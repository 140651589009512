import { ActionType, createAction } from 'typesafe-actions'

export const AppEvents = {
  MINT_SUCCESS: 'mint_success'
} as const

type AppEventsList = keyof typeof AppEvents
export type AppEventsType = typeof AppEvents[AppEventsList]

export type AppEventData<Name extends AppEventsType, PayloadType = undefined> = {
  event: Name
  payload?: PayloadType
}

export type AppEventPayloadMap = {
  [AppEvents.MINT_SUCCESS]: AppEventData<'mint_success'> & { projectId: number }
}

// Actions
export const eventEmiterActions = {
  emit: createAction('@@page/App/AppEventEmitter/EMIT')<Partial<AppEventPayloadMap>>()
}

export type EventEmitterActions = ActionType<typeof eventEmiterActions>
