import React, { ElementType } from 'react'
import clsx from 'clsx'
import TypographyMaterial, {
  TypographyProps as TypographyMaterialProps
} from '@mui/material/Typography'

import { useBreakpoint } from 'utils/Hooks'

import styles from './Typography.module.scss'

type TypographyWeightProp = 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extraBold'
export type TypographyColorProp = 'primary' | 'secondary' | 'error' | 'light'

export type TypographyProps = Omit<TypographyMaterialProps, 'fontWeight'> & {
  color?: TypographyColorProp
  component?: ElementType<any>
  fontWeight?: TypographyWeightProp | 300 | 400 | 500 | 600 | 700 | 800
  variantXs?: TypographyMaterialProps['variant']
  variantSm?: TypographyMaterialProps['variant']
  variantMd?: TypographyMaterialProps['variant']
  variantLg?: TypographyMaterialProps['variant']
  variantXl?: TypographyMaterialProps['variant']
}

const setColor = (color?: TypographyColorProp) => {
  if (color === 'secondary') return styles.ColorSecondary
  else if (color === 'light') return styles.ColorLight
  else if (color === 'error') return styles.ColorError

  return ''
}

const setVariant = (props: {
  breakpoint: string
  variant?: TypographyMaterialProps['variant']
  variantMd?: TypographyProps['variantMd']
  variantLg?: TypographyProps['variantLg']
  variantSm?: TypographyProps['variantSm']
  variantXl?: TypographyProps['variantXl']
  variantXs?: TypographyProps['variantXs']
}) => {
  const { breakpoint, variant, variantMd, variantLg, variantSm, variantXl, variantXs } = props

  if (breakpoint === 'xs' && variantXs) return variantXs
  else if (breakpoint === 'sm' && variantSm) return variantSm
  else if (breakpoint === 'md' && variantMd) return variantMd
  else if (breakpoint === 'lg' && variantLg) return variantLg
  else if (breakpoint === 'xl' && variantXl) return variantXl

  return variant
}

const TypographyResponsive: React.FC<TypographyProps> = props => {
  const {
    align,
    classes,
    className,
    children,
    color,
    fontWeight,
    gutterBottom,
    noWrap,
    paragraph,
    variant,
    variantMd,
    variantLg,
    variantSm,
    variantXl,
    variantXs,
    ...restProps
  } = props

  const { breakpoint } = useBreakpoint()
  const classNameColor = setColor(color)
  const adjustedVariant = setVariant({
    breakpoint,
    variant,
    variantMd,
    variantLg,
    variantSm,
    variantXl,
    variantXs
  })

  return (
    <TypographyMaterial
      {...restProps}
      align={align}
      classes={classes}
      className={clsx(classNameColor, className)}
      fontWeight={fontWeight}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      paragraph={paragraph}
      variant={adjustedVariant}
    >
      {children}
    </TypographyMaterial>
  )
}

const Typography: React.FC<TypographyProps> = props => {
  const { variantMd, variantLg, variantSm, variantXl, variantXs, ...nonResponsiveProps } = props

  if (variantMd || variantLg || variantSm || variantXl || variantXs)
    return <TypographyResponsive {...props} />

  const {
    align,
    classes,
    className,
    children,
    color,
    gutterBottom,
    noWrap,
    paragraph,
    variant,
    fontWeight,
    ...restProps
  } = nonResponsiveProps

  const classNameColor = setColor(color)

  return (
    <TypographyMaterial
      {...restProps}
      align={align}
      classes={classes}
      className={clsx(classNameColor, className)}
      fontWeight={fontWeight}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      paragraph={paragraph}
      variant={variant}
    >
      {children}
    </TypographyMaterial>
  )
}

export default Typography
