/*
 * Make loading only appear after certain of time.
 */

import { useState, useRef, useEffect } from 'react'

export const useDelayedLoading = (
  loading?: boolean | string,
  trueTimeout: number = 300,
  falseTimeout: number = 500
) => {
  const [delayedLoading, setDelayedLoading] = useState(loading)
  const timeoutId = useRef(0)

  useEffect(() => {
    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current)
    }
    const delay = loading ? trueTimeout : falseTimeout

    timeoutId.current = window.setTimeout(() => {
      setDelayedLoading(loading)
    }, delay)

    return () => {
      if (timeoutId.current) window.clearTimeout(timeoutId.current)
    }
  }, [falseTimeout, loading, trueTimeout])

  return delayedLoading
}
