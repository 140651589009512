import _forEach from 'lodash/pickBy'
import _toInteger from 'lodash/toInteger'
import { ErrorBundleType } from 'duck/ApiDuck'
import { AxiosResponse } from 'axios'
import { stripHtml } from './TextUtils'
import SentryUtils from './SentryUtils'
import { Severity } from '@sentry/react'

export const roundTransitionTime = (value: number) => _toInteger(Math.round(value * 10)) / 10

/*
 * Convert Ordering format between -{orderingKey}  to
 * {sortDirection: '', sortValue: ''} back and forth
 */

export type SortObject = {
  sortDirection: string
  sortValue: string
}

export const sortUtils = {
  toObject: (value: string = '') => {
    let sortDirection
    let sortValue
    if (value[0] === '-') {
      sortDirection = '-'
      sortValue = value.substring(1, value.length)
    } else {
      sortDirection = ''
      sortValue = value
    }
    return {
      sortDirection,
      sortValue
    }
  },
  toString: (value: SortObject) => `${value.sortDirection}${value.sortValue}`
}

const SERVER_INFO_STRING_LIST = ['nginx', 'ubuntu']

export const errorUtils = {
  getCode: (errorBundle?: ErrorBundleType | { error: AxiosResponse }) => {
    return errorBundle?.error?.status
  },
  flattenErrorMessageObject: (errorMessage: { [key: string]: Array<string> | string }) => {
    const errorKeys = Object.keys(errorMessage)
    let result = ''
    _forEach(errorKeys, (key, index: number) => {
      const errorText = errorMessage[key]
      const separator = !result || index === errorKeys.length - 1 ? '' : ', '
      if (Array.isArray(errorText)) {
        _forEach(errorText, (val, index: number) => {
          const separator = !result || index === errorText.length - 1 ? '' : ', '
          result = `${result}${separator}${val}`
        })
      } else {
        result = `${result}${separator}${errorText}`
      }
    })
    return stripHtml(result)
  },
  /*
   *  Collect value key error massage into flat string. Each error separated by comma.
   */
  flattenMessage: (errorBundle?: ErrorBundleType | { error: AxiosResponse } | null) => {
    const errorMessage = errorBundle?.error?.data

    if (!errorMessage) {
      return errorUtils.sanitizeServerVersion(stripHtml(errorBundle?.error?.statusText ?? ''))
    }
    if (typeof errorMessage === 'string') {
      return errorUtils.sanitizeServerVersion(stripHtml(errorMessage))
    }
    return errorUtils.flattenErrorMessageObject(errorMessage)
  },
  flattenBlobMessage: async (file: Blob) => {
    try {
      const readerResult = await fileReader(file)
      const errorMessage = JSON.parse(readerResult as string)

      return errorUtils.flattenErrorMessageObject(errorMessage)
    } catch (e) {
      console.log(e)
      return ''
    }
  },
  //If contain information about server, then skip it
  sanitizeServerVersion: (input: string) => {
    let hasString = false
    SERVER_INFO_STRING_LIST.forEach(value => {
      if (input.toLowerCase().includes(value)) {
        hasString = true
      }
    })

    return !hasString ? input : ''
  }
}

export const fileReader = (file: Blob) => {
  const fileReader = new FileReader()

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new Error('Problem parsing file'))
    }

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.readAsText(file)
  })
}

/* Check whether user adding a collection that have been already added in anoter inputset */

/* Convert base64 string to File string */

export const b64toFile = (input: string, fileName?: string) => {
  const sliceSize = 512
  const splited = input.split(';')
  const contentType = splited?.[0]?.split(':')[1] ?? ''
  const b64Data = splited?.[1]?.split(',')?.[1] ?? ''
  const extension = contentType.split('/')[1]

  if (!contentType || !b64Data) {
    return null
  }

  let byteCharacters = atob(b64Data)

  let byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)

    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  return new File(byteArrays, `${fileName || 'sampleName'}.${extension}`, { type: contentType })
}

export const toDataURL = (
  url?: string,
  callback?: (result: string | ArrayBuffer | null) => void,
  onError?: () => void
) => {
  if (!url) return
  let img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = function () {
    let canvas = document.createElement('CANVAS') as HTMLCanvasElement
    let ctx = canvas.getContext('2d')
    let dataURL
    canvas.height = img.height
    canvas.width = img.width
    ctx?.drawImage(img, 0, 0)
    dataURL = canvas.toDataURL()
    callback?.(dataURL)
  }
  img.onerror = (e: Event | string) => {
    SentryUtils.captureMessage(
      `Failed when convert image into DataURL.`,
      { image: url, e },
      Severity.Error
    )
    onError?.()
  }
  img.src = url
}
