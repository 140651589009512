import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import { withLatestFrom, map, filter, delay, mergeMap, startWith, take } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { apiActions, apiSelectors, mineProject } from 'duck/ApiDuck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { constCreatorMaker } from 'utils/TextUtils'

// Constants
const NAMESPACE = '@@page/CollectionsPage'
const creator = constCreatorMaker(NAMESPACE)

export const actions = {
  reloadCollected: createAction(creator('RELOAD_COLLECTED'))(),
  setLoaded: createAction(creator('SET_LOADED'))()
}

const selectCollectionsPage = (state: RootState) => state.container.collectionsPage

const selectListParam = createSelector(
  selectCollectionsPage,
  apiSelectors.currentUserAddress,
  (collectionsPage, userAddress) => ({ ...collectionsPage.listParams, user_address: userAddress })
)
const selectLoaded = createSelector(
  selectCollectionsPage,
  collectionsPage => collectionsPage.loaded
)

export const selectors = {
  listParam: selectListParam,
  loaded: selectLoaded
}

export type CollectionsPageState = {
  loaded: boolean
  listParams: ActionType<typeof mineProject.listMineProjectUserCollectedOutputs>['payload']['param']
}

const INITIAL: CollectionsPageState = {
  loaded: false,
  listParams: {
    limit: 30
  }
}

const reducer = produce((state: CollectionsPageState, { type }) => {
  switch (type) {
    case getType(actions.setLoaded): {
      state.loaded = true
      return
    }
    default:
  }
}, INITIAL)

// Epics

const reloadCollectedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.reloadCollected)),
    withLatestFrom(state$),
    delay(300),
    map(([_, state]) => {
      const listParam = selectors.listParam(state)
      return { listParam }
    }),
    mergeMap(({ listParam }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mineProject.listMineProjectUserCollectedOutputsResponse)),
        take(1),
        map(() => actions.setLoaded()),
        startWith(
          apiActions.mineProject.listMineProjectUserCollectedOutputs({
            param: listParam,
            next: false
          })
        )
      )
    )
  )

export const epics = combineEpics(reloadCollectedEpic)
export default reducer
