import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import { RootState, RootActionType } from 'duck'
import { dialogSelectors, DialogNames, dialogActions } from 'duck/DialogDuck'
import Typography from 'components/Typography'
import { appActions, appSelectors } from 'duck/AppDuck'
import PriceText from 'components/PriceText'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './DialogMintMini.module.scss'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { ErrorPanel } from './Components'

const CLASSES_DIALOG = {
  paper: styles.DialogPaper
}

const mapStateToProps = (state: RootState) => ({
  dialogDatum: dialogSelectors.dialogDatum[DialogNames.MINT_DIALOG_ARTIST_CURATED](state),
  activeDialog: dialogSelectors.activeDialog(state),
  prepareMintImageLoading: apiSelectors.loading['mineProject.prepareMintImage'](state),
  mintLoading: appSelectors.mintLoading(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      prepareMintImage: apiActions.mineProject.prepareMintImage,
      closeDialog: dialogActions.closeDialog,
      mintArtistCurated: appActions.metamask.mintArtistCurated
    },
    dispatch
  )

export type DialogMintMiniProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const DialogMintMini: React.FC<DialogMintMiniProps> = props => {
  const {
    closeDialog,
    mintArtistCurated,
    prepareMintImage,
    prepareMintImageLoading,
    mintLoading,
    dialogDatum,
    activeDialog
  } = props

  const open = activeDialog === DialogNames.MINT_DIALOG_ARTIST_CURATED
  const projectData = dialogDatum.projectData
  const projectId = dialogDatum?.projectData?.id
  const prepareMintData = dialogDatum.prepareMintData
  const isUserCanceled = dialogDatum.isUserCanceled
  const errorMessageRaw = dialogDatum.errorMessage
  const [errorMessage, setErrorMessage] = useState(errorMessageRaw)

  useEffect(() => {
    if (projectId && open) {
      prepareMintImage({ projectId })
    }
  }, [open, prepareMintImage, projectId])

  useEffect(() => {
    setErrorMessage(errorMessageRaw)
  }, [errorMessageRaw])

  const handleCloseDialog = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleCloseDialog}
      onBackdropClick={handleCloseDialog}
      classes={CLASSES_DIALOG}
      fullWidth
    >
      <div
        className={clsx(
          stylesGlobal.MarginTop4,
          stylesGlobal.MarginXAuto,
          stylesGlobal.PaddingBottom50,
          stylesGlobal.TextCenter,
          styles.DialogContentWrapperWide,
          styles.DialogContentWrapper
        )}
      >
        <Typography variant="body1" fontWeight="bold" component="div">
          {projectData?.title}
        </Typography>

        <Typography
          className={clsx(
            stylesGlobal.DisplayFlex,
            stylesGlobal.AlignItemsCenter,
            stylesGlobal.JustifyContentCenter,
            stylesGlobal.MarginTop40,
            stylesGlobal.MarginBottom40
          )}
          variant="h5"
          fontWeight="extraBold"
          align="center"
          component="div"
        >
          {prepareMintData ? (
            <PriceText
              price={`${prepareMintData?.mine_price}`}
              network={projectData?.network || 'ETHEREUM'}
            />
          ) : (
            <Skeleton variant="text" width={100} />
          )}
        </Typography>

        <div
          className={clsx(
            stylesGlobal.DisplayFlexColumn,
            stylesGlobal.MarginXAuto,
            stylesGlobal.MarginTop24,
            styles.ContainerWithMaxWidthWide
          )}
        >
          <div>
            <Button
              className={stylesGlobal.PaddingX32}
              variant="contained"
              color="secondary"
              disabled={Boolean(mintLoading || prepareMintImageLoading)}
              onClick={() => {
                setErrorMessage('')
                mintArtistCurated({ projectId: projectData.id })
              }}
            >
              {mintLoading ? 'Mint In Progress...' : 'Mint to Wallet'}
            </Button>
          </div>

          <ErrorPanel errorMessage={errorMessage} isUserCanceled={isUserCanceled} />

          <Typography
            variant="body2"
            align="center"
            className={stylesGlobal.MarginTop28}
            component="div"
          >
            You’ll receive 1 of artist curated results. each artwork is unique and can only be
            minted once.
          </Typography>
        </div>
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogMintMini)
