import React, { useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Dialog from 'components/Dialog'
import { useBreakpoint } from 'utils/Hooks'
import { RootState, RootActionType } from 'duck'
import { dialogActions, dialogSelectors, DialogNames } from 'duck/DialogDuck'
import Typography from 'components/Typography'
import { apiSelectors } from 'duck/ApiDuck'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './Dialogs.module.scss'
import _startCase from 'lodash/startCase'
import _lowerCase from 'lodash/lowerCase'

const CLASSES_DIALOG = {
  paper: styles.DialogPaper
}

const mapStateToProps = (state: RootState) => ({
  dialogDatum: dialogSelectors.dialogDatum[DialogNames.CONNECT_TO_WRONG_NETWORK](state),
  currentUserAddress: apiSelectors.currentUserAddress(state),
  activeDialog: dialogSelectors.activeDialog(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog
    },
    dispatch
  )

type DialogMetamaskConnectionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const DialogMetamaskConnection: React.FC<DialogMetamaskConnectionProps> = props => {
  const { closeDialog, activeDialog, dialogDatum, currentUserAddress } = props

  const open = activeDialog === DialogNames.CONNECT_TO_WRONG_NETWORK
  const projectNetwork = _startCase(_lowerCase(dialogDatum?.projectNetwork))

  const { isSmallscreenAbove } = useBreakpoint()

  const handleCloseDialog = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  useEffect(() => {
    if (currentUserAddress) {
      closeDialog()
    }
  }, [closeDialog, currentUserAddress])

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleCloseDialog}
      onBackdropClick={handleCloseDialog}
      classes={isSmallscreenAbove ? CLASSES_DIALOG : undefined}
      fullScreen={!isSmallscreenAbove}
      fullWidth
    >
      <div className={clsx(stylesGlobal.MarginTop4, styles.DialogContentWrapper)}>
        <div className={clsx(stylesGlobal.TextCenter)}>
          <Typography
            variant="h6"
            component="h2"
            fontWeight="bold"
            className={stylesGlobal.MarginBottom16}
          >
            {`You need connected to ${projectNetwork} network`}
          </Typography>

          <Typography variant="body1">
            {`You need connected to the ${projectNetwork} network to mint the image in this project. Right now, you're connected to different network.`}
          </Typography>
        </div>
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogMetamaskConnection)
