import React from 'react'
import Helmet, { HelmetProps } from 'react-helmet'
import ImgLogo from 'assets/images/logo-artmine-playform@3x.png'

type SEOHelmetProps = {
  children?: HelmetProps['children']
  description?: string
  image?: string
  link?: HelmetProps['link']
  title?: string
  url?: string
}

const INITIAL_CONTENT = {
  description:
    'Art Mine presents a collaborative way to create and experience art. Artists create generative AI models for you to mine and mint as NFTs. Each NFT is one-of-a-kind and can only be minted once.',
  title: 'Art Mine by Playform'
}

const SEOHelmet: React.FC<SEOHelmetProps> = props => {
  const {
    children,
    description = INITIAL_CONTENT.description,
    image = ImgLogo,
    link,
    title = INITIAL_CONTENT.title,
    url = window.location.href
  } = props

  return (
    <Helmet link={link}>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} /> : null
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      {children}
    </Helmet>
  )
}

export default SEOHelmet
