import React from 'react'
import clsx from 'clsx'
import DialogMaterial, { DialogProps as DialogMaterialProps } from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'

import Button from 'components/Button'

import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './Dialog.module.scss'

export type DialogProps = DialogMaterialProps & {}

const Dialog: React.FC<DialogProps> = props => {
  const {
    children,
    classes,
    className,
    fullScreen,
    fullWidth,
    keepMounted,
    maxWidth,
    title,
    onBackdropClick,
    onClose,
    scroll,
    ...restProps
  } = props

  return (
    <DialogMaterial
      {...restProps}
      classes={classes}
      className={className}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      keepMounted={keepMounted}
      maxWidth={maxWidth}
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      scroll={scroll}
    >
      <div className={styles.DialogTitle}>
        <div
          className={clsx(
            stylesGlobal.PaddingY8,
            stylesGlobal.PaddingRight8,
            stylesGlobal.MarginLeftAuto
          )}
        >
          <Button
            onClick={event => {
              onClose?.(event, 'escapeKeyDown')
            }}
            icon
          >
            <CloseIcon />
          </Button>
        </div>
      </div>

      {children}
    </DialogMaterial>
  )
}

export default Dialog
