import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from 'duck'
import { appActions, appSelectors } from 'duck/AppDuck'
import { usePageViewTracker } from './Hooks'

const LocationBasedAddon: React.FC<{}> = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const pushTo = useSelector((state: RootState) => appSelectors.pushTo(state))
  usePageViewTracker({ location })

  useEffect(() => {
    if (pushTo) dispatch(appActions.setPushTo(undefined))
  }, [dispatch, pushTo])

  if (pushTo) return <Navigate to={pushTo} />

  return null
}

export default LocationBasedAddon
