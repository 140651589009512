import React, { MouseEventHandler, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RootState, RootActionType } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import { appSelectors, appActions } from 'duck/AppDuck'
import { dialogActions, DialogNames } from 'duck/DialogDuck'
import { useBreakpoint } from 'utils/Hooks'
import { TextUtils } from 'utils/TextUtils'
import loadable from 'utils/Loadable'
import stylesGlobal from 'stylesGlobal.module.scss'

const NavbarDesktop = loadable(() => import('./NavbarDesktop'))
const NavbarMobile = loadable(() => import('./NavbarMobile'))

const mapStateToProps = (state: RootState) => ({
  // retrieveConfigLoading: apiSelectors.loading['mineProject.retrieveConfig'](state),
  currentUserAddress: apiSelectors.currentUserAddress(state)
  // connectLoading: appSelectors.connectLoading(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      connectToMetaMask: appActions.metamask.connect,
      disconnect: appActions.metamask.disconnect,
      addDialog: dialogActions.addDialog
    },
    dispatch
  )

export type NavbarProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export type NavbarChildProps = {
  loading?: boolean
  userAddressShort: string
  onClickConnectMetamask: MouseEventHandler
}
const Navbar: React.FC<NavbarProps> = props => {
  const { currentUserAddress, connectToMetaMask, addDialog, disconnect } = props
  const { isMediumscreenAbove } = useBreakpoint()
  const userAddressShort = TextUtils.trimAddress(currentUserAddress ?? '')
  const onClickConnectMetamask = useCallback(() => {
    if (!currentUserAddress) {
      connectToMetaMask({ connectLocation: 'Navbar' })
    } else {
      addDialog({
        [DialogNames.CONNECTION_STATUS]: { dialogName: DialogNames.CONNECTION_STATUS }
      })
    }
  }, [addDialog, connectToMetaMask, currentUserAddress])

  return (
    <div className={stylesGlobal.WidthFull}>
      {isMediumscreenAbove ? (
        <NavbarDesktop
          addDialog={addDialog}
          userAddressShort={userAddressShort}
          onClickConnectMetamask={onClickConnectMetamask}
        />
      ) : (
        <NavbarMobile
          disconnect={disconnect}
          userAddressShort={userAddressShort}
          onClickConnectMetamask={onClickConnectMetamask}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
