import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const LogoIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon
      className={clsx(className)}
      width="56"
      titleAccess={alt}
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.3556 14.7006C19.3701 15.718 19.3701 17.3674 18.3556 18.3848C17.3411 19.4021 15.6962 19.4021 14.6817 18.3848C13.6672 17.3674 13.6672 15.718 14.6817 14.7006C15.6962 13.6833 17.3411 13.6833 18.3556 14.7006Z" />
      <path d="M34.6652 31.0582C35.6797 32.0755 35.6797 33.725 34.6652 34.7423C33.6506 35.7596 32.0058 35.7596 30.9913 34.7423C29.9768 33.725 29.9768 32.0755 30.9913 31.0582C32.0058 30.0408 33.6506 30.0408 34.6652 31.0582Z" />
      <path d="M26.81 14.3728C27.9689 15.535 27.9689 17.4192 26.81 18.5814C25.6511 19.7436 23.7721 19.7436 22.6131 18.5814C21.4542 17.4192 21.4542 15.535 22.6131 14.3728C23.7721 13.2107 25.6511 13.2107 26.81 14.3728Z" />
      <path d="M34.9454 14.4005C36.0892 15.5474 36.0892 17.407 34.9454 18.554C33.8016 19.701 31.9472 19.701 30.8035 18.554C29.6597 17.407 29.6597 15.5474 30.8035 14.4005C31.9472 13.2535 33.8016 13.2535 34.9454 14.4005Z" />
      <path d="M34.5143 22.9502C35.4381 23.8766 35.4381 25.3786 34.5143 26.305C33.5905 27.2314 32.0927 27.2314 31.1689 26.305C30.2451 25.3786 30.2451 23.8766 31.1689 22.9502C32.0927 22.0238 33.5905 22.0238 34.5143 22.9502Z" />
      <path d="M41.8561 15.5889C42.3408 16.0751 42.3408 16.8632 41.8561 17.3494C41.3713 17.8355 40.5853 17.8355 40.1005 17.3494C39.6157 16.8632 39.6157 16.0751 40.1005 15.5889C40.5853 15.1028 41.3713 15.1028 41.8561 15.5889Z" />
      <path d="M25.3636 7.67309C25.704 8.01441 25.704 8.56779 25.3636 8.90912C25.0232 9.25044 24.4714 9.25043 24.131 8.90912C23.7906 8.5678 23.7906 8.01441 24.131 7.67309C24.4714 7.33177 25.0232 7.33177 25.3636 7.67309Z" />
      <path d="M25.1238 24.2486C25.3779 24.5035 25.3779 24.9167 25.1238 25.1715C24.8696 25.4264 24.4576 25.4264 24.2034 25.1715C23.9493 24.9167 23.9493 24.5035 24.2034 24.2486C24.4576 23.9938 24.8696 23.9938 25.1238 24.2486Z" />
      <path d="M33.1772 7.99014C33.3581 8.17158 33.3582 8.46575 33.1772 8.64718C32.9963 8.82862 32.7029 8.82862 32.522 8.64718C32.3411 8.46575 32.3411 8.17158 32.522 7.99014C32.7029 7.8087 32.9963 7.8087 33.1772 7.99014Z" />
      <path d="M41.1412 41.0305C41.2119 41.1015 41.2119 41.2165 41.1412 41.2874C41.0704 41.3583 40.9557 41.3583 40.885 41.2874C40.8142 41.2165 40.8142 41.1015 40.885 41.0305C40.9557 40.9596 41.0704 40.9596 41.1412 41.0305Z" />
      <path d="M49.0625 24.8553C48.9868 24.7794 48.9868 24.6563 49.0625 24.5804C49.1382 24.5045 49.2609 24.5045 49.3366 24.5804C49.4123 24.6563 49.4123 24.7794 49.3366 24.8553C49.2609 24.9312 49.1382 24.9312 49.0625 24.8553Z" />
      <path d="M42.7125 22.9526C43.6363 23.879 43.6363 25.381 42.7125 26.3074C41.7887 27.2338 40.2909 27.2338 39.3671 26.3074C38.4433 25.381 38.4433 23.879 39.3671 22.9526C40.2909 22.0262 41.7887 22.0262 42.7125 22.9526Z" />
      <path d="M42.6294 31.308C43.4931 32.1742 43.4931 33.5785 42.6294 34.4446C41.7656 35.3108 40.3652 35.3108 39.5015 34.4446C38.6378 33.5785 38.6378 32.1742 39.5015 31.308C40.3652 30.4419 41.7656 30.4419 42.6294 31.308Z" />
      <path d="M18.5737 22.6327C19.7326 23.7949 19.7326 25.6791 18.5737 26.8413C17.4147 28.0035 15.5357 28.0035 14.3768 26.8413C13.2179 25.6792 13.2179 23.7949 14.3768 22.6327C15.5357 21.4706 17.4147 21.4706 18.5737 22.6327Z" />
      <path d="M18.546 30.8436C19.6897 31.9906 19.6897 33.8502 18.546 34.9971C17.4022 36.1441 15.5478 36.1441 14.404 34.9971C13.2603 33.8501 13.2603 31.9906 14.404 30.8436C15.5478 29.6966 17.4022 29.6966 18.546 30.8436Z" />
      <path d="M26.276 31.2099C27.1998 32.1363 27.1998 33.6383 26.276 34.5647C25.3522 35.4911 23.8544 35.4911 22.9306 34.5647C22.0068 33.6383 22.0068 32.1363 22.9306 31.2099C23.8544 30.2835 25.3522 30.2835 26.276 31.2099Z" />
      <path d="M17.3453 40.168C17.8301 40.6541 17.8301 41.4423 17.3453 41.9284C16.8605 42.4146 16.0745 42.4146 15.5898 41.9284C15.105 41.4423 15.105 40.6541 15.5898 40.168C16.0745 39.6818 16.8605 39.6818 17.3453 40.168Z" />
      <path d="M8.92804 24.1542C9.2684 24.4955 9.2684 25.0489 8.92803 25.3902C8.58767 25.7315 8.03582 25.7315 7.69546 25.3902C7.35509 25.0489 7.35509 24.4955 7.69546 24.1542C8.03582 23.8129 8.58767 23.8129 8.92804 24.1542Z" />
      <path d="M8.66549 32.5669C8.84643 32.7483 8.84643 33.0425 8.6655 33.2239C8.48457 33.4053 8.19122 33.4053 8.01029 33.2239C7.82936 33.0425 7.82936 32.7483 8.01029 32.5669C8.19121 32.3854 8.48456 32.3854 8.66549 32.5669Z" />
      <path d="M24.5547 49.4316C24.479 49.3557 24.479 49.2326 24.5547 49.1567C24.6304 49.0808 24.7531 49.0808 24.8288 49.1567C24.9045 49.2326 24.9045 49.3557 24.8288 49.4316C24.7531 49.5075 24.6304 49.5075 24.5547 49.4316Z" />
      <path d="M26.278 39.4331C27.2018 40.3595 27.2018 41.8615 26.278 42.7879C25.3542 43.7143 23.8564 43.7143 22.9325 42.7879C22.0087 41.8615 22.0087 40.3595 22.9325 39.4331C23.8564 38.5067 25.3542 38.5067 26.278 39.4331Z" />
      <path d="M34.3921 39.5674C35.2558 40.4336 35.2558 41.8379 34.3921 42.704C33.5283 43.5702 32.1279 43.5702 31.2642 42.704C30.4005 41.8379 30.4005 40.4336 31.2642 39.5674C32.1279 38.7012 33.5283 38.7012 34.3921 39.5674Z" />
    </SvgIcon>
  )
}

export default LogoIcon
