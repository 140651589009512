import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'
import App from 'containers/AppPage'
import { configureStore } from './store'
import reportWebVitals from './reportWebVitals'
import MixPanelUtils from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import { values } from 'appConstants'

import 'swiper/swiper.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import 'swiper/modules/free-mode/free-mode.scss'
import 'swiper/modules/scrollbar/scrollbar.scss'
import './index.scss'

if (process.env.NODE_ENV === 'production') {
  const disableReactDevTools = (): void => {
    const noop = (): void => undefined
    const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__

    if (typeof DEV_TOOLS === 'object') {
      for (const [key, value] of Object.entries(DEV_TOOLS)) {
        if (key === 'renderers') DEV_TOOLS[key] = []
        else DEV_TOOLS[key] = typeof value === 'function' ? noop : null
      }
    }
  }

  disableReactDevTools()
}

export const store = configureStore().store

SentryUtils.init()
MixPanelUtils.init()

if (values.GOOGLE_TAG_MANAGER_ID) {
  TagManager.initialize({
    gtmId: values.GOOGLE_TAG_MANAGER_ID
  })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
