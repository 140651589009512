import { createTheme } from '@mui/material/styles'

const globalMuiClassNames = {
  active: '.Mui-active',
  checked: '.Mui-checked',
  completed: '.Mui-completed',
  disabled: '.Mui-disabled',
  error: '.Mui-error',
  expanded: '.Mui-expanded',
  focusVisible: '.Mui-focusVisible',
  focused: '.Mui-focused',
  required: '.Mui-required',
  selected: '.Mui-selected'
}
const fontSizeBase = 16
export const convertPixelToRem = (value: number) => {
  return value / fontSizeBase + 'rem'
}
const convertPixelToLineHeight = (value: number, fontSize: number) => {
  return value / fontSize
}

export const color: any = {
  primary: {
    main: '#000',
    light: '#383838',
    dark: '#000000'
  },
  secondary: {
    main: '#F9BD19',
    light: '#383838',
    dark: '#c18d00',
    hover: '#c18d00',
    focus: 'rgba(249, 189, 25, 0.12)'
  },
  error: {
    main: '#EB5757',
    light: '#EB5757',
    dark: '#EB5757'
  },
  text: {
    primary: '#000000',
    secondary: '#F9BD19',
    light: '#696969'
  },
  border: {
    primary: '#DEDEDE'
  }
}

export const border = {
  primary: `1px solid ${color.border.primary}`,
  secondary: `1px solid ${color.secondary.main}`
}

export const typography = {
  fontFamily: `'Nunito Sans', sans-serif`,
  fontWeightLight: 300,
  fontWeightMedium: 500,
  fontWeightSemibold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontWeightRegular: 400,
  h1: {
    // fontSize: '96px',
    lineHeight: convertPixelToLineHeight(112, 96),
    letterSpacing: convertPixelToRem(-1.5),
    fontWeight: 300
  },
  h2: {
    // fontSize: '60px',
    lineHeight: convertPixelToLineHeight(72, 60),
    letterSpacing: convertPixelToRem(-0.5),
    fontWeight: 300
  },
  h3: {
    fontSize: '44px'
    // lineHeight: '56px'
  },
  h4: {
    // fontSize: '34px',
    lineHeight: convertPixelToLineHeight(34, 36)
  },
  h5: {
    // fontSize: '24px',
    lineHeight: convertPixelToLineHeight(33, 24),
    letterSpacing: convertPixelToRem(0.18)
  },
  h6: {
    // fontSize: '20px',
    lineHeight: convertPixelToLineHeight(24, 20),
    letterSpacing: convertPixelToRem(0.15),
    fontWeight: 500
  },
  subtitle1: {
    // fontSize: '16px',
    // lineHeight: '24px',
    lineHeight: convertPixelToLineHeight(24, 16),
    letterSpacing: convertPixelToRem(0.15)
  },
  subtitle2: {
    // fontSize: '14px',
    lineHeight: convertPixelToLineHeight(24, 14),
    letterSpacing: convertPixelToRem(0.13),
    fontWeight: 500
  },
  body1: {
    // fontSize: '16px',
    // lineHeight: '24px',
    lineHeight: convertPixelToLineHeight(24, 16),
    letterSpacing: 0
  },
  body2: {
    // fontSize: '14px',
    // lineHeight: '20px'
    lineHeight: convertPixelToLineHeight(20, 14),
    letterSpacing: 0
  },
  button: {
    // fontSize: '14px',
    // lineHeight: '16px',
    lineHeight: convertPixelToLineHeight(16, 14),
    letterSpacing: convertPixelToRem(1.25),
    fontWeight: 500
  },
  caption: {
    // fontSize: '12px',
    // lineHeight: '16px',
    lineHeight: convertPixelToLineHeight(16, 12),
    letterSpacing: convertPixelToRem(0.4)
  },
  overline: {
    fontSize: convertPixelToRem(10),
    lineHeight: convertPixelToLineHeight(16, 10),
    letterSpacing: convertPixelToRem(1.5),
    fontWeight: 500
  }
}

/* Because ThemeOptions type object doesn't allow add keyframe variable 
   So, it's how to work around id. 
*/
// const createThemeExtended = (options?: any) => {
//   return createTheme(options as ThemeOptions)
// }

const artmineTheme = createTheme({
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920
    }
  },
  palette: {
    primary: color.primary,
    secondary: color.secondary,
    error: color.error,
    text: {
      primary: color.text.primary,
      secondary: color.text.secondary
      // disabled: 'rgba(255, 255, 255, 0.6)'
      // hint: 'rgba(0, 0, 0, 0.38)'
    }
    // divider: 'rgba(255, 255, 255, 0.12)'
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableTouchRipple: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: convertPixelToLineHeight(22, 16),
          letterSpacing: 0,
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          },
          '&:active': {
            boxShadow: 'none'
          }
        },
        sizeMedium: {
          padding: '5px 16px'
        },
        //
        outlinedSizeMedium: {
          padding: '4px 15px'
        },
        outlinedPrimary: {
          border: `1px solid ${color.primary.main}`
        },
        // textSecondary: {
        //   color: '#BE8C04'
        // },
        outlinedSecondary: {
          // color: '#BE8C04',
          border: `1px solid ${color.secondary.main}`
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 0
        },
        paperWidthXs: {
          maxWidth: 370
        },
        paperFullWidth: {
          width: 'calc(100% - 24px)',
          margin: '32px 12px'
        },
        paperFullScreen: {
          maxWidth: 'none',
          width: '100%',
          margin: 0
        }
      }
    },
    MuiDrawer: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: '3px'
        },
        sizeMedium: {
          padding: '4px'
        },
        sizeLarge: {
          padding: '7px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: color.text.light
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: color.secondary.focus
          },
          '&.Mui-selected': {
            backgroundColor: color.secondary.focus
          },
          '&.Mui-selected:hover': {
            backgroundColor: color.secondary.focus
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: 7,
          paddingBottom: 8
        },
        inputSizeSmall: {
          paddingTop: 4,
          paddingBottom: 5
        },
        notchedOutline: {
          borderColor: color.border.primary
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          margin: `0 ${convertPixelToRem(6)}`,
          userSelect: 'none'
        },
        ellipsis: ({ ownerState, theme }) => {
          return {
            minWidth: ownerState.shape === 'rounded' ? convertPixelToRem(42) : undefined,
            marginTop: convertPixelToRem(-6),
            backgroundColor: 'unset',
            color: color.text.light,
            pointerEvents: 'none',
            userSelect: 'none'
          }
        },
        outlined: ({ ownerState, theme }) => {
          if (ownerState.shape === 'rounded')
            return {
              minWidth: convertPixelToRem(42)
            }
        },
        outlinedSecondary: ({ ownerState, theme }) => {
          if (ownerState.shape === 'rounded')
            return {
              backgroundColor: color.secondary.main,
              '&:hover': {
                backgroundColor: color.secondary.hover
              },
              [`&${globalMuiClassNames.selected}`]: {
                backgroundColor: 'unset',
                color: color.secondary.main,
                border: `1px solid ${color.secondary.main}`
              },
              [`&${globalMuiClassNames.selected}:hover`]: {
                backgroundColor: 'rgba(249, 189, 25, 0.04)'
              }
            }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingRight: convertPixelToRem(14),
          paddingLeft: convertPixelToRem(14)
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          [`&${globalMuiClassNames.disabled}`]: {
            color: 'rgba(0, 0, 0, 0.16)'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: convertPixelToRem(12),
          backgroundColor: '#FFF',
          border: border.primary,
          borderRadius: 0,
          color: color.text.light,
          fontSize: convertPixelToRem(14)
        }
      }
    }
  }
})

export default artmineTheme
