/**
 * Asynchronously loads the component for Show case
 */

import loadable from 'utils/Loadable'
import { LoadingPage } from 'components/Loading'

export default loadable(() => import('./index'), {
  fallback: <LoadingPage />
})
