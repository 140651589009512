import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'
import styles from './Icon.module.scss'

const ArrowDownwardIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon
      className={clsx(styles.IconStroke, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.99 19.49L11.99 4.49999"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.977 13.486L12 19.502L6.02299 13.486"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default ArrowDownwardIcon
