import { ActionType, createReducer, createAction } from 'typesafe-actions'
import { RootState } from 'duck'
import { createSelector } from 'reselect'
import { ActionTypeParam } from '.'
import { ButtonProps } from 'components/Button'

// Actions
export type UpsellLocation = 'todo'

export const bannerActions = {
  show: createAction('@@page/App/banner/SHOW')<BannerState['generalBanner']>(),
  close: createAction('@@page/App/banner/CLOSE')(),
  update: createAction('@@page/App/banner/UPDATE')<BannerState['generalBanner']>()
}

export type BannerActions = ActionType<typeof bannerActions>

// Selectors
const selectBanner = (state: RootState) => state.banner
const selectGeneralBanner = createSelector(selectBanner, banner => banner.generalBanner)

export const selectors = {
  banner: selectGeneralBanner
}

export type BannerAction = {
  label: string
  onClick: ActionTypeParam | (() => void)
  color?: ButtonProps['color']
  className?: string
}

export type BannerState = {
  generalBanner: {
    show?: boolean
    message?: string
    autoCloseDuration?: number
    actions?: BannerAction[]
  }
}

const initialState: BannerState = {
  generalBanner: {
    show: false,
    message: '',
    actions: undefined,
    autoCloseDuration: 0
  }
  // generalBanner: {
  //   show: true,
  //   message: 'dadsdad',
  //   actions: [
  //     {
  //       label: 'DISMISS',
  //       onClick: () => {},
  //       variant: 'outlined'
  //     }
  //   ],
  //   autoCloseDuration: 0
  // },
}

const reducer = createReducer<BannerState, BannerActions>(initialState)
  .handleAction(bannerActions.show, (state, { payload }) => ({
    ...state,
    generalBanner: {
      ...state.generalBanner,
      message: payload.message,
      actions: payload.actions,
      autoCloseDuration: payload.autoCloseDuration || 0,
      show: true
    }
  }))
  .handleAction(bannerActions.update, (state, { payload }) => ({
    ...state,
    generalBanner: {
      ...state.generalBanner,
      message: payload.message,
      actions: payload.actions
    }
  }))
  .handleAction(bannerActions.close, state => ({
    ...state,
    generalBanner: { ...initialState.generalBanner }
  }))

export default reducer
