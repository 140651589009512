import _reduce from 'lodash/reduce'
import * as SessionStorage from './SessionStorage'
import * as LocalStorage from './LocalStorage'
import * as ApiUtils from './ApiUtils'

export const NumUtils = {
  clamp: (val: number, min: number, max: number) => {
    return val > max ? max : val < min ? min : val
  },
  sumValues: (input: { [key: string]: number }) =>
    _reduce(
      input,
      (result, value) => {
        result += value
        return result
      },
      0
    ),
  randomBetween: (min: number, max: number, dec?: number) => {
    let decFactor = 1
    if (dec) {
      for (let i = 0; i < dec; i += 1) {
        decFactor *= 10
      }
    }
    return (
      Math.round(Math.random() * (max * decFactor - min * decFactor) + min * decFactor) / decFactor
    )
  }
}

export { LocalStorage, SessionStorage, ApiUtils }
