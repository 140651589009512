import { ActionType } from 'typesafe-actions'
import apiReducers, { apiActions, sharedActions, epics as apiEpics } from 'duck/ApiDuck'
import appReducers, { appActions, epics as appEpics } from 'duck/AppDuck'
import dialogReducers, { dialogActions } from 'duck/DialogDuck'
import { eventEmiterActions } from 'duck/EventEmitterDuck'
import bannerReducers, { bannerActions } from 'duck/BannerDuck'
import homePageReducers, {
  actions as homePageActions,
  epics as homePageEpics,
  HomePageState
} from 'containers/HomePage/duck'

import projectsPageReducers, {
  actions as projectsPageActions,
  epics as projectsPageEpics,
  ProjectsPageState
} from 'containers/ProjectsPage/duck'

import projectPageReducers, {
  actions as projectPageActions,
  epics as projectPageEpics,
  ProjectPageState
} from 'containers/ProjectPage/duck'
import collectionsPageReducer, {
  actions as collectionsPageActions,
  epics as collectionsPageEpics,
  CollectionsPageState
} from 'containers/CollectionsPage/duck'

import collectionPageReducer, {
  actions as collectionPageActions,
  epics as collectionPageEpics,
  CollectionPageState
} from 'containers/CollectionPage/duck'

import contactPageReducer, {
  actions as contactPageActions,
  epics as contactPageEpics,
  ContactPageState
} from 'containers/ContactPage/duck'

import { combineEpics, StateObservable } from 'redux-observable'

//Reducer
import { StateType } from 'typesafe-actions'
import { combineReducers } from 'redux'
import { catchError } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Severity } from '@sentry/react'
import SentryUtils from 'utils/SentryUtils'

export const RootActions = {
  sharedActions,
  appActions,
  apiActions,
  dialogActions,
  bannerActions,
  homePageActions,
  projectsPageActions,
  projectPageActions,
  collectionsPageActions,
  collectionPageActions,
  contactPageActions,
  eventEmiterActions
}

export type RootActionType = ActionType<typeof RootActions>

export type ActionTypeParam = {
  type: string
  payload?: any
  meta?: any
}

const epicsArray = [
  apiEpics,
  appEpics,
  homePageEpics,
  projectsPageEpics,
  projectPageEpics,
  collectionsPageEpics,
  collectionPageEpics,
  contactPageEpics
]

export const epics = (
  action$: Observable<RootActionType>,
  store$: StateObservable<RootState>,
  dependencies: any
) =>
  combineEpics(...epicsArray)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      const message = error?.message
      console.log(message)
      SentryUtils.captureMessage(`Epic Error ${message}`, error, Severity.Error)
      return source
    })
  )

export type ContainerStateType = {
  homePage: HomePageState
  projectsPage: ProjectsPageState
  projectPage: ProjectPageState
  collectionsPage: CollectionsPageState
  collectionPage: CollectionPageState
  contactPage: ContactPageState
}

const containerReducer = combineReducers<ContainerStateType>({
  homePage: homePageReducers,
  projectsPage: projectsPageReducers,
  projectPage: projectPageReducers,
  collectionsPage: collectionsPageReducer,
  collectionPage: collectionPageReducer,
  contactPage: contactPageReducer
})

export type RootState = {
  api: StateType<typeof apiReducers>
  app: StateType<typeof appReducers>
  dialog: StateType<typeof dialogReducers>
  banner: StateType<typeof bannerReducers>
  container: StateType<typeof containerReducer>
}

export const createRootReducer = () =>
  combineReducers<RootState>({
    api: apiReducers,
    app: appReducers,
    dialog: dialogReducers,
    banner: bannerReducers,
    container: containerReducer
  })
