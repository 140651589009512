import { DialogProps } from 'components/Dialog'
import { ActionTypeParam } from 'duck'
import { MineImage, MineProject, PrepareMintData } from 'models/ApiModels'

export type DialogContentCommand = 'use-in-new-project'

export type DialogActionType = {
  label: string
  actions?: (ActionTypeParam | Function)[]
}

export type DialogPayloadBasic<Name extends DialogNameType> = {
  dialogName: Name
  title?: undefined
  content?: undefined
  onClose?: Function
}
export type DialogPayloadComplete<Name extends DialogNameType, Content = undefined> = {
  dialogName: Name
  title?: string
  content?: Content
  onClose?: Function
}
export type DialogPayloadConfirmation<
  Name extends DialogNameType,
  Content = undefined
> = DialogPayloadComplete<Name, Content> & {
  cancelActionText?: string
  classes?: DialogProps['classes']
  swapActionPosition?: boolean
  yesAction: DialogActionType
}

export const DialogNames = {
  ERROR: 'error',
  PROJECT_NOT_FOUND: 'projectNotFound',
  PROJECT_CONFIRMATION: 'projectConfirmation',
  INFORMATION: 'information',
  CONNECTION_STATUS: 'connection_status',
  CONNECT_TO_METAMASK: 'connect_to_metamask',
  CONNECT_TO_WRONG_NETWORK: 'connect_to_wrong_network',
  MINT_DIALOG_COLLECTOR_CURATED: 'mint_dialog_collector_curated',
  MINT_DIALOG_ARTIST_CURATED: 'mint_dialog_artist_curated',
  MINT_DIALOG_SUCCESS: 'mint_dialog_success',
  MINT_DIALOG_PROGRESS: 'mint_dialog_progress',
  PROJECT_COPYURL: 'projectCopyUrl'
} as const

export type DialogTypeMap = {
  //Error Dialog
  [DialogNames.ERROR]: DialogPayloadComplete<'error', string | (string | undefined)[]>
  [DialogNames.PROJECT_CONFIRMATION]: DialogPayloadBasic<'projectConfirmation'> & {
    confirmType: 'set_featured_project' | 'hide_project'
  }
  [DialogNames.PROJECT_NOT_FOUND]: DialogPayloadBasic<'projectNotFound'>
  [DialogNames.MINT_DIALOG_COLLECTOR_CURATED]: DialogPayloadBasic<'mint_dialog_collector_curated'> & {
    mineImageId: number
    mineImage?: MineImage
    errorMessage?: string
    isUserCanceled?: boolean
  }
  [DialogNames.MINT_DIALOG_ARTIST_CURATED]: DialogPayloadBasic<'mint_dialog_artist_curated'> & {
    projectId: number
    projectData?: MineProject
    prepareMintData?: PrepareMintData
    errorMessage?: string
    isUserCanceled?: boolean
  }
  [DialogNames.INFORMATION]: DialogPayloadComplete<'information', string>
  [DialogNames.CONNECTION_STATUS]: DialogPayloadBasic<'connection_status'>
  [DialogNames.CONNECT_TO_METAMASK]: DialogPayloadBasic<'connect_to_metamask'> & {
    actionText?: string
  }
  [DialogNames.MINT_DIALOG_SUCCESS]: DialogPayloadBasic<'mint_dialog_success'> & { imageId: number }
  [DialogNames.MINT_DIALOG_PROGRESS]: DialogPayloadBasic<'mint_dialog_progress'>
  [DialogNames.CONNECT_TO_WRONG_NETWORK]: DialogPayloadBasic<'connect_to_wrong_network'> & {
    projectNetwork: MineProject['network']
  }
  [DialogNames.PROJECT_COPYURL]: DialogPayloadBasic<'projectCopyUrl'>
}

export type DialogType = keyof DialogTypeMap
export type DialogNameList = keyof typeof DialogNames
export type DialogNameType = typeof DialogNames[DialogNameList]
