import React, { CSSProperties, ReactNode } from 'react'
import clsx from 'clsx'
import Skeleton from '@mui/material/Skeleton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import _isNil from 'lodash/isNil'
import Img, { ImgProps } from 'components/Img'
import Typography from 'components/Typography'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import { CircularLoading } from 'components/Loading'
import { TypographyProps } from 'components/Typography'
import { MineImage, MineProject } from 'models/ApiModels'
import { Format } from 'utils/TextUtils'
import PriceText from 'components/PriceText'
import { useBreakpoint } from 'utils/Hooks'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './Card.module.scss'

type CardCollectionProps = {
  classes?: {
    loadingText?: string
    previewWrapper?: string
    previewWrapperOuter?: string
    title?: string
  }
  network: MineProject['network']
  hideTitle?: boolean
  imageOnly?: boolean
  loading?: boolean
  loadingIconSize?: number
  showPrice?: boolean
  showProjectName?: boolean
  mineImage?: MineImage
  titleVariant?: TypographyProps['variant']
  title?: ReactNode | string
  onLoad?: ImgProps['onLoad']
  priceTooltip?: string
  style?: CSSProperties
}

export const CardCollectionSkeleton: React.FC<{
  style?: CSSProperties
}> = props => {
  const { style } = props

  return (
    <div style={style}>
      <Skeleton
        variant="rectangular"
        className={(stylesGlobal.MarginBottom4, stylesGlobal.PaddingBottomFull)}
      />

      <div>
        <Skeleton variant="text" width="45%" className={stylesGlobal.MarginXAuto} />
      </div>
    </div>
  )
}

const CardCollection: React.FC<CardCollectionProps> = props => {
  const {
    network,
    classes,
    imageOnly,
    loading,
    loadingIconSize = 24,
    mineImage,
    showPrice,
    priceTooltip,
    style,
    title,
    hideTitle,
    showProjectName,
    titleVariant = 'body1',
    onLoad
  } = props

  const { isSmallscreenAbove } = useBreakpoint()
  const isGenerating = !mineImage?.image && mineImage?.status === 'UNKNOWN'
  const isFailed = !mineImage?.image && mineImage?.status === 'FAILED'
  const hasOverlayLink = mineImage?.opensea_url || mineImage?.rarible_url

  return (
    <div
      className={hasOverlayLink ? styles.CardCollectionWithLinkOverlay : undefined}
      style={style}
    >
      <div className={classes?.previewWrapperOuter}>
        <div
          className={clsx(
            stylesGlobal.PositionRelative,
            stylesGlobal.DisplayBlock,
            stylesGlobal.WidthFull,
            stylesGlobal.MarginBottom4,
            stylesGlobal.PaddingBottomFull,
            classes?.previewWrapper
          )}
        >
          {isGenerating || isFailed ? (
            <div
              className={clsx(
                stylesGlobal.PositionAbsolute,
                stylesGlobal.Top0,
                stylesGlobal.Left0,
                stylesGlobal.DisplayFlex,
                stylesGlobal.AlignItemsCenter,
                stylesGlobal.JustifyContentCenter,
                stylesGlobal.WidthFull,
                stylesGlobal.HeightFull,
                stylesGlobal.BorderPrimary,
                stylesGlobal.UserSelectNone
              )}
            >
              <Typography variant="caption" component="div" color="light">
                {isGenerating ? (
                  <div className={stylesGlobal.TextCenter}>
                    <CircularLoading size={loadingIconSize} loading disableShrink />
                    <div className={clsx(stylesGlobal.MarginTop4, classes?.loadingText)}>
                      Generating Image...
                    </div>
                  </div>
                ) : (
                  'Failed To Generate Image'
                )}
              </Typography>
            </div>
          ) : (
            <div
              className={clsx(
                stylesGlobal.PositionAbsolute,
                stylesGlobal.Top0,
                stylesGlobal.Left0,
                stylesGlobal.DisplayFlex,
                stylesGlobal.AlignItemsCenter,
                stylesGlobal.JustifyContentCenter,
                stylesGlobal.WidthFull,
                stylesGlobal.HeightFull,
                stylesGlobal.BorderPrimary,
                stylesGlobal.UserSelectNone
              )}
            >
              <Img
                image={mineImage?.image}
                className={clsx(
                  stylesGlobal.DisplayBlock,
                  stylesGlobal.PointerEventsNone,
                  stylesGlobal.UserSelectNone,
                  stylesGlobal.ObjectCover
                )}
                onLoad={onLoad}
                showBgColor={Boolean(mineImage?.image)}
                borderless
                fullWidth
                fullHeight
              />

              {loading ? (
                <div
                  className={clsx(
                    stylesGlobal.PositionAbsolute,
                    stylesGlobal.Left0,
                    stylesGlobal.ZIndex1,
                    stylesGlobal.DisplayFlex,
                    stylesGlobal.AlignItemsCenter,
                    stylesGlobal.JustifyContentCenter,
                    stylesGlobal.WidthFull,
                    stylesGlobal.UserSelectNone
                  )}
                >
                  <CircularLoading loading disableShrink />
                </div>
              ) : null}

              {hasOverlayLink ? (
                <div
                  className={clsx(
                    stylesGlobal.PositionAbsolute,
                    stylesGlobal.Top0,
                    stylesGlobal.Left0,
                    stylesGlobal.ZIndex1,
                    stylesGlobal.WidthFull,
                    stylesGlobal.HeightFull,
                    stylesGlobal.DisplayFlexColumn,
                    stylesGlobal.AlignItemsCenter,
                    stylesGlobal.JustifyContentCenter,
                    stylesGlobal.PointerEventsAuto,
                    styles.CardCollectionLinkOverlayWrapper
                  )}
                >
                  {mineImage?.opensea_url ? (
                    <Button
                      className={stylesGlobal.MarginY4}
                      color="secondary"
                      variant="contained"
                      href={mineImage?.opensea_url}
                    >
                      View on OpenSea
                    </Button>
                  ) : null}

                  {mineImage?.rarible_url ? (
                    <Button
                      className={stylesGlobal.MarginY4}
                      color="secondary"
                      variant="contained"
                      href={mineImage?.rarible_url}
                    >
                      View on Rarible
                    </Button>
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>

      <div>
        {!imageOnly && showProjectName ? (
          <Typography
            variant={titleVariant}
            align="center"
            component="div"
            color="light"
            className={clsx(styles.MarginTop4, classes?.title)}
          >
            {mineImage?.project_name}
          </Typography>
        ) : null}

        {!imageOnly && !hideTitle ? (
          <Typography
            variant={titleVariant}
            align="center"
            component="div"
            color="light"
            className={clsx(styles.MarginTop4, classes?.title)}
          >
            {title ? title : mineImage ? Format.getSeedText(mineImage?.seed) : null}
          </Typography>
        ) : null}

        {!imageOnly && showPrice ? (
          <div
            className={clsx(
              stylesGlobal.PositionRelative,
              stylesGlobal.DisplayFlex,
              stylesGlobal.AlignItemsCenter,
              stylesGlobal.JustifyContentCenter,
              stylesGlobal.MarginTop16
            )}
          >
            <Typography variant="body1" align="center" component="div" fontWeight="bold">
              {mineImage && !_isNil(mineImage?.mine_price) ? (
                <PriceText price={mineImage?.mine_price} network={network} />
              ) : (
                <Skeleton variant="rectangular" width={80} />
              )}
            </Typography>

            {mineImage?.mine_price && priceTooltip ? (
              <div
                className={clsx(
                  stylesGlobal.DisplayFlex,
                  stylesGlobal.AlignItemsCenter,
                  stylesGlobal.JustifyContentCenter,
                  stylesGlobal.PaddingTop4
                )}
              >
                <Tooltip
                  className={clsx(
                    stylesGlobal.HeightFull,
                    stylesGlobal.MarginLeft8,
                    stylesGlobal.PointerEventsAuto
                  )}
                  title={priceTooltip}
                  placement={isSmallscreenAbove ? 'right-end' : 'bottom'}
                >
                  <HelpOutlineIcon
                    className={clsx(
                      stylesGlobal.TextColorLight,
                      styles.CardCollectionPriceTooltipIcon
                    )}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CardCollection
