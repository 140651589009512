import { MutableRefObject, useEffect, useRef } from 'react'
import _throttle from 'lodash/throttle'
import _debounce from 'lodash/debounce'

/* Scroll event hooks using requestAnimationFrame */
const DEFAULT_OFFSET_BOTTOM = 150
const isBrowser = typeof window !== `undefined`

type useScrollType = {
  ref?: MutableRefObject<any>
  useWindow?: boolean
  // element?: HTMLDivElement | Window | null
  fpsLimit?: number
  onBottom?: Function
  onBottomThrottled?: Function
  onScroll?: Function
  onScrollX?: Function
  offsetBottom?: number
}

export const useScroll = (props: useScrollType) => {
  const {
    fpsLimit = 60,
    onBottom,
    onBottomThrottled,
    onScroll,
    onScrollX,
    offsetBottom,
    ref,
    useWindow
  } = props
  const currentScrollPosition = useRef({
    x: 0,
    y: 0
  })
  const timerHelper = useRef({
    now: 0,
    startTime: 0,
    then: 0,
    elapsed: 0
  })

  useEffect(() => {
    if (!isBrowser) return undefined

    const scrollNode = useWindow ? window : ref?.current || undefined

    // console.log('useEffectScroll', scrollNode, onBottom)

    if (scrollNode && (onScroll || onScrollX || onBottom || onBottomThrottled)) {
      // console.log('useEffectScroll if')
      const fpsInterval = 1000 / fpsLimit

      const debouncedOnBottom = onBottom
        ? _debounce(onBottom as (...args: any) => any, 250)
        : undefined
      const throttledOnBottom = onBottomThrottled
        ? _throttle(onBottomThrottled as (...args: any) => any, 250)
        : undefined

      const animate = (newtime: number) => {
        // stop
        if (!timerHelper?.current || !scrollNode) {
          return
        }

        // If still scrolling, request another frame
        if (
          currentScrollPosition.current.y !== (useWindow ? window.scrollY : scrollNode.scrollTop) ||
          currentScrollPosition.current.x !== (useWindow ? window.scrollX : scrollNode.scrollLeft)
        ) {
          requestAnimationFrame(animate)
        }

        // calc elapsed time since last loop
        timerHelper.current.now = newtime
        timerHelper.current.elapsed = timerHelper.current.now - timerHelper.current.then

        // if enough time has elapsed, draw the next frame
        if (timerHelper.current.elapsed > fpsInterval) {
          // Get ready for next frame by setting then=now, but...
          // Also, adjust for fpsInterval not being multiple of 16.67
          timerHelper.current.then =
            timerHelper.current.now - (timerHelper.current.elapsed % fpsInterval)

          //
          const offsetBottomAdjusted =
            offsetBottom || offsetBottom === 0
              ? offsetBottom
              : !offsetBottom
              ? DEFAULT_OFFSET_BOTTOM
              : 0

          // Events
          if (
            (onScroll || throttledOnBottom) &&
            currentScrollPosition.current.y !== (useWindow ? window.scrollY : scrollNode.scrollTop)
          ) {
            onScroll?.(
              useWindow ? window.scrollY : scrollNode.scrollTop,
              scrollNode,
              currentScrollPosition.current.y < (useWindow ? window.scrollY : scrollNode.scrollTop)
                ? 'down'
                : 'up'
            )

            if (
              throttledOnBottom &&
              (useWindow
                ? window.scrollY > window.innerHeight - offsetBottomAdjusted
                : scrollNode.scrollTop + scrollNode.clientHeight >
                  scrollNode.scrollHeight - offsetBottomAdjusted)
            )
              throttledOnBottom()
          }

          if (
            onScrollX &&
            currentScrollPosition.current.x !== (useWindow ? window.scrollX : scrollNode.scrollLeft)
          )
            onScrollX(
              useWindow ? window.scrollX : scrollNode.scrollLeft,
              scrollNode,
              currentScrollPosition.current.x < (useWindow ? window.scrollX : scrollNode.scrollLeft)
                ? 'right'
                : 'left'
            )

          if (
            debouncedOnBottom &&
            currentScrollPosition.current.y !==
              (useWindow ? window.scrollY : scrollNode.scrollTop) &&
            (useWindow
              ? window.scrollY > window.innerHeight - offsetBottomAdjusted
              : scrollNode.scrollTop + scrollNode.clientHeight >
                scrollNode.scrollHeight - offsetBottomAdjusted)
          ) {
            debouncedOnBottom()
          }

          //
          currentScrollPosition.current = {
            x: useWindow ? window.scrollX : scrollNode.scrollLeft,
            y: useWindow ? window.scrollY : scrollNode.scrollTop
          }
        }
      }

      const handleOnScroll = () => {
        requestAnimationFrame(animate)
      }

      // console.log('addEventListener scroll')

      useWindow
        ? window.addEventListener('scroll', handleOnScroll)
        : scrollNode?.addEventListener('scroll', handleOnScroll)

      return () => {
        // console.log('removeEventListener scroll')
        useWindow
          ? window.removeEventListener('scroll', handleOnScroll)
          : scrollNode?.removeEventListener('scroll', handleOnScroll)
      }
    }
  }, [onScroll, onScrollX, onBottom, onBottomThrottled, fpsLimit, offsetBottom, ref, useWindow])
}
