import _trim from 'lodash/trim'
import _words from 'lodash/words'
import _toLower from 'lodash/toLower'
import _truncate from 'lodash/truncate'
import _padStart from 'lodash/padStart'
import { format } from 'numerable'
import React from 'react'

/*
 *  Construct array of url and param to full URL
 *  We need this to handle empty param
 */
export const constructChainParam = (params: Array<any>, tailString?: string) => {
  let text = ''
  for (let i = 0; i < params.length; i++) {
    const param = params[i]
    if (!param) {
      break
    } else {
      text = i === 0 ? `${param}` : `${text}/${param}`
    }
  }
  return `${text}${tailString ?? ''}`
}

/*
 *  Create Constant for redux
 */

export const constCreatorMaker = (prefix: string) => {
  function creator(name: string) {
    return `${prefix}/${name}`
  }
  return creator
}

export function stripHtml(input: string = '') {
  return input.replace(/<[^>]*>?/gm, '')
}

interface Name {
  first_name?: string
  last_name?: string
}

export const NameUtils = {
  split: (name: string): Name => {
    const [firstName, ...lastNames] = _words(name)

    return {
      first_name: firstName,
      last_name: _trim(
        lastNames.reduce((result, lastName) => {
          result = `${result} ${lastName}`
          return result
        }, '')
      )
    }
  },
  getSliced: (name?: Name, limit: number = 10) => {
    const first_name = name?.first_name ?? ''
    const last_name = name?.last_name ?? ''
    const nameLength = first_name.length + last_name.length

    if (nameLength > limit) {
      const [firstName, ...restFirstNames] = _words(first_name)

      const firstNameTruncated = _truncate(firstName, { length: 20, omission: '' })
      const restFirstNamesCombined = _trim(
        restFirstNames.reduce((result, lastName) => {
          result = `${result} ${lastName}`
          return result
        }, '')
      )

      const lastNameInitial = _words(`${restFirstNamesCombined} ${last_name}`)
        .reduce((result, lastName) => {
          result = `${result}${lastName?.[0] ?? ''}`
          return result
        }, '')
        .toUpperCase()

      return `${firstNameTruncated} ${lastNameInitial}`
    } else {
      return `${first_name} ${last_name ?? ''}`
    }
  }
}

export const Format = {
  getSeedText: (seedNumber: number) => `#${_padStart(`${seedNumber}`, 8, '0')}`,
  number: (number: number, formatString: string = '0,0') => {
    return format(number, formatString)
  },
  numberWithDecimalTrail: (number: number, decimal: number) => {
    let decimalText = ''

    for (let i = 0; i < decimal; i++) {
      if (i === 0) {
        decimalText = '.'
      }
      decimalText = `${decimalText}0`
    }

    return format(number, `0,0${decimalText}`)
  },
  commarizeNumber: (number: number) => {
    return number > 1000 ? format(number, '0.0a') : number
  },
  currency: (number: number = 0, currencyCode: string = '', zeroCount: number = 0) => {
    let currencySymbol = ''
    let zeroCountText = ''

    switch (currencyCode.toUpperCase()) {
      case 'USD':
        currencySymbol = '$'
        break
      default:
        break
    }

    for (let i = 0; i < zeroCount; i++) {
      if (i === 0) {
        zeroCountText = '.'
      }
      zeroCountText = `${zeroCountText}0`
    }

    return format(number, `${currencySymbol}0,0${zeroCountText}`)
  }
}

/*
 * Text Transformation
 */
export type TextTransformProps = {
  str?: string | React.ReactNode | null | undefined
  format?: 'capitalize' | 'lowercase' | 'titlecase' | 'uppercase'
}

export const TextUtils = {
  textTransform: (str?: string | null, format?: TextTransformProps['format']) => {
    if (!str) return undefined

    if (format === 'titlecase') {
      const lower = _toLower(str)
      return lower.replace(/\w\S*/g, txt => `${txt.charAt(0).toUpperCase()}${txt.substr(1)}`) ?? ''
    } else if (format === 'uppercase') {
      return str.toUpperCase()
    }

    return str
  },
  trimAddress: (address?: string) =>
    address
      ? `${address.substring(0, 7)}...${address.substring(address.length - 4, address.length)}`
      : ''
}
