import { RootActionType, RootState } from 'duck'
import { combineEpics, Epic } from 'redux-observable'
import {
  filter,
  withLatestFrom,
  map,
  delay,
  exhaustMap,
  merge,
  of,
  mergeMap,
  catchError,
  switchMap,
  concatMap,
  startWith,
  take
} from 'rxjs'
import _toNumber from 'lodash/toNumber'
import _compact from 'lodash/compact'
import { isActionOf } from 'typesafe-actions'
import { apiActions, apiSelectors, sharedActions } from '.'
import { ApiUtils } from 'utils'
import { ListMineProjectReq, ListOutputsRequest, PaginationRequestParams } from 'models/ApiModels'
import { values } from 'appConstants'

const listMineProjectSampleOutputsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listMineProjectSampleOutputs)),
    map(action => {
      const { param } = action.payload

      const listParam: ListOutputsRequest = {
        ...param,
        limit: 60,
        is_sample: true
      }
      return { listParam, type: action.type, param }
    }),
    exhaustMap(({ listParam, type, param }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: type })),
        ApiUtils.mineProject.listOutputs({}, listParam).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listMineProjectSampleOutputsResponse({
              data: response.data,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { param } }))
          )
        )
      )
    )
  )

const listFeaturedMineProjectsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listFeaturedMineProjects)),
    map(action => {
      const listParam: ListMineProjectReq = {
        limit: values.HOME_PAGE_PROJECT_COUNT,
        is_featured: true,
        is_published: true
      }
      return { listParam, type: action.type }
    }),
    exhaustMap(({ listParam, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: type })),
        ApiUtils.mineProject.listProjects({}, listParam).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listFeaturedMineProjectsResponse({
              data: response.data
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const listMineProjectsHomeEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listMineProjectsHome)),
    map(action => {
      const listParam: ListMineProjectReq = {
        limit: values.HOME_PAGE_PROJECT_LIST_COUNT,
        sort: action.payload.req.sort,
        is_published: true
      }
      return { listParam, type: action.type }
    }),
    exhaustMap(({ listParam, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: type })),
        ApiUtils.mineProject.listProjects({}, listParam).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listMineProjectsHomeResponse({
              data: response.data
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const listStudioMineProjectsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listStudioMineProjects)),
    map(action => {
      const listParam: ListMineProjectReq = {
        limit: values.HOME_PAGE_PROJECT_COUNT,
        studio: true,
        is_published: true
      }
      return { listParam, type: action.type }
    }),
    exhaustMap(({ listParam, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: type })),
        ApiUtils.mineProject.listProjects({}, listParam).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listStudioMineProjectsResponse({
              data: response.data
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )
const listTopMineProjectsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listTopMineProjects)),
    map(action => {
      const listParam: ListMineProjectReq = {
        limit: values.HOME_PAGE_PROJECT_COUNT,
        sort: 'top',
        is_published: true
      }
      return { listParam, type: action.type }
    }),
    exhaustMap(({ listParam, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: type })),
        ApiUtils.mineProject.listProjects({}, listParam).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listTopMineProjectsResponse({
              data: response.data
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const listMineProjectCollectedOutputsEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listMineProjectCollectedOutputs)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, silentUpdate } = action.payload
      const projectId = param.project ?? 0
      const nextData = state.api.mineProjects.mineImage.collectedList[projectId]?.lastReq?.next

      const params = next ? { next: nextData } : {}
      const extraParams = next ? undefined : param
      return { next, params, extraParams, type: action.type, silentUpdate, param }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    delay(300),
    exhaustMap(({ next, params, extraParams, type, silentUpdate, param }) =>
      merge(
        of({ silentUpdate }).pipe(
          filter(({ silentUpdate }) => !Boolean(silentUpdate)),
          map(() => sharedActions.setLoading({ loading: true, type: type }))
        ),
        ApiUtils.mineProject.listOutputs(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listMineProjectCollectedOutputsResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listMineProjectUserCollectedOutputsEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listMineProjectUserCollectedOutputs)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, silentUpdate } = action.payload
      const nextData = state.api.mineProjects.mineImage.userList.lastReq?.next

      const params = next ? { next: nextData } : {}
      const extraParams = next ? undefined : param
      const mineProjects = apiSelectors.mineProjectsData(state)
      return { next, mineProjects, params, extraParams, type: action.type, silentUpdate, param }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    delay(300),
    exhaustMap(({ next, mineProjects, params, extraParams, type, silentUpdate, param }) =>
      merge(
        of({ silentUpdate }).pipe(
          filter(({ silentUpdate }) => !Boolean(silentUpdate)),
          map(() => sharedActions.setLoading({ loading: true, type: type }))
        ),
        ApiUtils.mineProject.listOutputs(params, extraParams).pipe(
          map(response => ({
            response,
            shouldRetrievedMineProjects: _compact(
              response.data.results?.map(data =>
                !mineProjects[data.project] ? data.project : undefined
              )
            )
          })),
          mergeMap(({ response, shouldRetrievedMineProjects }) => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listMineProjectUserCollectedOutputsResponse({
              data: response.data,
              next,
              req: param
            }),
            ...shouldRetrievedMineProjects.map(id => apiActions.mineProject.retrieveProject(id))
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listMineProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listMineProjects)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { silentUpdate, req } = action.payload
      const { page = 0, limit = values.HOME_PAGE_PROJECT_COUNT } = req

      const params: PaginationRequestParams = {
        limit,
        offset: limit * page
      }
      const extraParams: ListMineProjectReq = { ...req, is_published: true }

      return { params, extraParams, type: action.type, silentUpdate, req }
    }),
    delay(300),
    exhaustMap(({ params, extraParams, type, silentUpdate, req }) =>
      merge(
        of({ silentUpdate }).pipe(
          filter(({ silentUpdate }) => !Boolean(silentUpdate)),
          map(() => sharedActions.setLoading({ loading: true, type: type }))
        ),
        ApiUtils.mineProject.listProjects(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listMineProjectsResponse({
              data: response.data,
              req
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const createMineImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.createMineImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mineProject.createMineImage(payload).pipe(
          mergeMap(response => [
            apiActions.mineProject.createMineImageResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createFeedbackEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.createFeedback)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mineProject.createFeedback(payload).pipe(
          mergeMap(response => [
            apiActions.mineProject.createFeedbackResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createNotifyEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.createNotify)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mineProject.createNotify(payload).pipe(
          mergeMap(response => [
            apiActions.mineProject.createNotifyResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createMailChimpEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.createMailChimp)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mineProject.createMailchimp(payload).pipe(
          mergeMap(response => [
            apiActions.mineProject.createMailChimpResponse(payload),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveInputsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.retrieveInput)),
    mergeMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.mineProject.retrieveInput(action.payload).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            ...Object.keys(response.data.collections).map(id =>
              apiActions.mineProject.listImage({
                param: {
                  collection: _toNumber(id),
                  inputs: action.payload,
                  limit: 60,
                  offset: 0
                }
              })
            ),
            apiActions.mineProject.retrieveInputResponse(response.data)
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const setOpenedProjectIdEpic: Epic<RootActionType, RootActionType, RootState> = (action$, $store) =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.setOpenedProjectId)),
    withLatestFrom($store),
    map(([action, state]) => ({
      id: action.payload
    })),
    filter(({ id }) => Boolean(id)),
    mergeMap(({ id }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mineProject.retrieveProjectResponse)),
        take(1),
        map(() => apiActions.mineProject.listMineProjectSampleOutputs({ param: { project: id } })),
        startWith(apiActions.mineProject.retrieveProject(id ?? 0))
      )
    )
  )

const retrieveProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.retrieveProject)),
    mergeMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: `${action.payload}`, type: action.type })),
        ApiUtils.mineProject.retrieveProject(action.payload).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            apiActions.mineProject.retrieveProjectResponse(response.data)
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const prepareMintImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.prepareMintImage)),
    mergeMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.mineProject.prepareMintImage(action.payload.projectId).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            apiActions.mineProject.prepareMintImageResponse({
              projectId: action.payload.projectId,
              data: response.data
            })
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const retrieveConfigEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.retrieveConfig)),
    mergeMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.mineProject.retrieveConfig().pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            apiActions.mineProject.retrieveConfigResponse(response.data)
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type
              })
            )
          )
        )
      )
    )
  )

const retrieveMineImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.retrieveMineImage)),
    filter(({ payload }) => Boolean(payload)),
    mergeMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.mineProject.retrieveMineImage(action.payload).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            apiActions.mineProject.retrieveMineImageResponse(response.data)
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const startMineImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.startMineImage)),
    mergeMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.mineProject.startMineImage(action.payload).pipe(
          mergeMap(() => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            apiActions.mineProject.startMineImageResponse(action.payload)
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const listImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listImage)),
    withLatestFrom(store),
    /* To determine whether extract first data, or continue pagination
     * We need this because the nature of image data structure,
     * This code is to choose which imageList need to be updated.
     */
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const collectionId = param.collection
      const nextData = state.api.mineProjects.collectionImages[collectionId]?.next

      const params = next ? { next: nextData } : {}
      const extraParams = next ? undefined : param
      return { next, params, extraParams, type: action.type, param }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    mergeMap(({ next, params, extraParams, type, param }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mineProject.listImage(params, extraParams).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listImageResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                req: param,
                type
              })
            )
          )
        )
      )
    )
  )

const listBannerImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(apiActions.mineProject.listBannerImages)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mineProject.listBannerImages().pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mineProject.listBannerImagesResponse({
              data: response.data
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const apiEpics = [
  listMineProjectsHomeEpic,
  createMailChimpEpic,
  createNotifyEpic,
  prepareMintImageEpic,
  createFeedbackEpic,
  retrieveConfigEpic,
  startMineImageEpic,
  listStudioMineProjectsEpic,
  listTopMineProjectsEpic,
  listFeaturedMineProjectsEpic,
  listMineProjectUserCollectedOutputsEpic,
  listMineProjectSampleOutputsEpic,
  setOpenedProjectIdEpic,
  listImagesEpic,
  retrieveProjectEpic,
  listMineProjectCollectedOutputsEpic,
  retrieveInputsEpic,
  listMineProjectsEpic,
  createMineImageEpic,
  retrieveProjectEpic,
  retrieveMineImageEpic,
  listBannerImagesEpic
]

export const epics = combineEpics(...apiEpics)
