import React, { Fragment } from 'react'
import Loading from 'components/Loading'
import { useDelayedRender } from 'utils/Hooks'
import { defaultPageTitle } from 'appConstants'
import styles from './Loading.module.scss'
import SEOHelmet from 'containers/AppPage/components/SEOHelmet'

const DEFAULT_DELAY = 400

type LoadingPageProps = {
  delay?: number
  disablePageTitle?: boolean
}

export const LoadingPage: React.FC<LoadingPageProps> = props => {
  const { delay = DEFAULT_DELAY, disablePageTitle } = props

  const shouldRender = useDelayedRender(delay)

  return (
    <>
      {!disablePageTitle ? <SEOHelmet title={`Loading....  | ${defaultPageTitle}`} /> : null}

      {shouldRender ? (
        <div className={styles.LoadingPage}>
          <Loading />
        </div>
      ) : null}
    </>
  )
}
