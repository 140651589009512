import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import {
  withLatestFrom,
  map,
  filter,
  delay,
  mergeMap,
  startWith,
  take,
  concatMap,
  tap
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { apiActions, apiSelectors, ErrorBundleType, mineProject, sharedActions } from 'duck/ApiDuck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { constCreatorMaker } from 'utils/TextUtils'
import { NumUtils } from 'utils'
import { merge, of } from 'rxjs'
import { MineImage } from 'models/ApiModels'
import MixPanelUtils from 'utils/MixPanelUtils'
import { AppEvents, eventEmiterActions } from 'duck/EventEmitterDuck'

// Constants
const NAMESPACE = '@@page/ProjectPage'
const creator = constCreatorMaker(NAMESPACE)
const MAX_SEED = 99999999

const Utils = {
  getInitialCollectedList: (projectId: number): CollectedListData => ({
    loaded: false,
    listParams: {
      project: projectId,
      limit: 30,
      mine_status: 'succeed'
    }
  })
}

// Actions
export const actions = {
  reloadCollected: createAction(creator('RELOAD_COLLECTED'))(),
  setCollectedLoaded: createAction(creator('SET_COLLECTED_LOADED'))<{ projectId: number }>(),
  generateMineImage: createAction(creator('GENERATE_MINE_IMAGE'))<{ seed: number }>(),
  pickSeedToGenerate: createAction(creator('PICK_SEED_TO_GENERATE'))(),
  updateSeed: createAction(creator('UPDATE_SEED'))<{ seed?: number; projectId: number }>(),
  setError: createAction(creator('SET_ERROR'))<{ errorString?: string }>(),
  setMineImageId: createAction(creator('SET_MINE_IMAGE_ID'))<{
    mineImageId?: number
    projectId: number
  }>(),
  reset: createAction(creator('RESET'))<{ projectId: number }>()
}

// Selectors
const selectProjectPage = (state: RootState) => state.container.projectPage

const selectCollectedListParamsData = createSelector(
  selectProjectPage,
  apiSelectors.openedMineProject,
  (projectPage, openedMineProject) =>
    projectPage.collectedLists[openedMineProject?.id ?? 0] ??
    Utils.getInitialCollectedList(openedMineProject?.id ?? 0)
)

const selectCurrentSeed = createSelector(
  selectProjectPage,
  apiSelectors.openedMineProject,
  (projectPage, openedMineProject) =>
    projectPage.collectedData[openedMineProject?.id ?? 0]?.seed ?? 0
)

const selectCurrentMineImage = createSelector(
  selectProjectPage,
  apiSelectors.mineImageData,
  apiSelectors.openedMineProject,
  (projectPage, imageData, openedMineProject) => {
    const mineImageId = projectPage.collectedData[openedMineProject?.id ?? 0]?.mineImageId ?? 0
    return mineImageId ? imageData[mineImageId] : undefined
  }
)

export const selectors = {
  currentSeed: selectCurrentSeed,
  generateError: createSelector(selectProjectPage, param => param.generateError),
  currentMineImage: selectCurrentMineImage,
  collectedListloaded: createSelector(
    selectCollectedListParamsData,
    listParamData => listParamData.loaded
  ),
  collectedListParams: createSelector(
    selectCollectedListParamsData,
    listParamData => listParamData.listParams
  )
}

export type CollectedListData = {
  loaded: boolean
  listParams: ActionType<typeof mineProject.listMineProjectCollectedOutputs>['payload']['param']
}
export type CollectedDataType = {
  seed?: number
  mineImageId?: number
}
export type ProjectPageState = {
  generateError?: string
  collectedLists: Record<number, CollectedListData>
  collectedData: Record<number, CollectedDataType>
}

const INITIAL_COLLECTED_DATA: CollectedDataType = {
  seed: undefined,
  mineImageId: undefined
}

const INITIAL: ProjectPageState = {
  generateError: undefined,
  collectedLists: {},
  collectedData: {}
}

const reducer = produce((state: ProjectPageState, { type, payload }) => {
  switch (type) {
    case getType(actions.setCollectedLoaded): {
      const { projectId } = payload as ActionType<typeof actions.setCollectedLoaded>['payload']
      const currentData =
        state.collectedLists[projectId] ?? Utils.getInitialCollectedList(projectId)
      state.collectedLists[projectId] = {
        ...currentData,
        loaded: true
      }

      return
    }
    case getType(actions.updateSeed): {
      const { projectId, seed } = payload as ActionType<typeof actions.updateSeed>['payload']
      state.collectedData[projectId] = {
        ...(state.collectedData[projectId] ?? { ...INITIAL_COLLECTED_DATA }),
        seed
      }
      return
    }
    case getType(actions.reset): {
      const { projectId } = payload as ActionType<typeof actions.reset>['payload']
      state.collectedData[projectId] = INITIAL_COLLECTED_DATA
      return
    }
    case getType(actions.setError): {
      const { errorString } = payload as ActionType<typeof actions.setError>['payload']
      state.generateError = errorString
      return
    }
    case getType(actions.setMineImageId): {
      const { projectId, mineImageId } = payload as ActionType<
        typeof actions.setMineImageId
      >['payload']
      state.collectedData[projectId] = {
        ...(state.collectedData[projectId] ?? { ...INITIAL_COLLECTED_DATA }),
        mineImageId
      }
      return
    }
    default:
  }
}, INITIAL)

// Epics

const listenOnMintSuccess: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    filter(({ payload }) => Boolean(payload[AppEvents.MINT_SUCCESS]?.projectId)),
    map(({ payload }) =>
      actions.reset({ projectId: payload[AppEvents.MINT_SUCCESS]?.projectId ?? 0 })
    )
  )

const reloadCollectedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.reloadCollected)),
    withLatestFrom(state$),
    delay(300),
    map(([_, state]) => {
      const listParam = selectors.collectedListParams(state)
      const projectId = listParam.project
      return { listParam, projectId }
    }),
    mergeMap(({ listParam, projectId = 0 }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mineProject.listMineProjectCollectedOutputsResponse)),
        take(1),
        map(() => actions.setCollectedLoaded({ projectId })),
        startWith(
          apiActions.mineProject.listMineProjectCollectedOutputs({
            param: listParam,
            next: false
          })
        )
      )
    )
  )

const generateMineImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.generateMineImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      seed: action.payload.seed ?? NumUtils.randomBetween(1, MAX_SEED),
      project: apiSelectors.openedMineProject(state)?.id ?? 0
    })),
    mergeMap(({ seed, project }) =>
      action$.pipe(
        filter(
          isActionOf([apiActions.mineProject.createMineImageResponse, sharedActions.setError])
        ),
        take(1),
        mergeMap(param =>
          merge(
            of(param).pipe(
              filter(
                ({ type }) => type === getType(apiActions.mineProject.createMineImageResponse)
              ),
              tap(() => {
                MixPanelUtils.track<'GENERATE_IMAGE'>('Generate Image', {
                  generate_button_type: 'Generate',
                  seed
                })
              }),
              concatMap(({ payload }) => [
                actions.setMineImageId({
                  mineImageId: (payload as MineImage).id,
                  projectId: project
                }),
                actions.updateSeed({ seed, projectId: project })
              ])
            ),
            of(param).pipe(
              filter(({ type }) => type === getType(sharedActions.setError)),
              filter(
                ({ payload }) =>
                  (payload as ErrorBundleType).type ===
                  getType(apiActions.mineProject.createMineImage)
              ),
              filter(({ payload }) => (payload as ErrorBundleType).error?.status === 400),
              map(() =>
                actions.setError({
                  errorString: 'This number has already been mined, try again'
                })
              )
            )
          )
        ),
        startWith(
          apiActions.mineProject.createMineImage({
            seed,
            project
          })
        )
      )
    )
  )

const pickSeedToGenerateEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.pickSeedToGenerate)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      seed: NumUtils.randomBetween(1, MAX_SEED),
      project: apiSelectors.openedMineProject(state)?.id ?? 0
    })),
    mergeMap(({ seed, project }) =>
      action$.pipe(
        filter(
          isActionOf([apiActions.mineProject.createMineImageResponse, sharedActions.setError])
        ),
        take(1),
        mergeMap(param =>
          merge(
            of(param).pipe(
              filter(
                ({ type }) => type === getType(apiActions.mineProject.createMineImageResponse)
              ),
              tap(() => {
                MixPanelUtils.track<'GENERATE_IMAGE'>('Generate Image', {
                  generate_button_type: 'Pick For Me',
                  seed
                })
              }),
              concatMap(({ payload }) => [
                actions.setMineImageId({
                  mineImageId: (payload as MineImage).id,
                  projectId: project
                }),
                actions.updateSeed({ seed, projectId: project })
              ])
            ),
            of(param).pipe(
              filter(({ type }) => type === getType(sharedActions.setError)),
              filter(
                ({ payload }) =>
                  (payload as ErrorBundleType).type ===
                  getType(apiActions.mineProject.createMineImage)
              ),
              filter(({ payload }) => (payload as ErrorBundleType).error?.status === 400),
              map(() => actions.pickSeedToGenerate())
            )
          )
        ),
        startWith(
          apiActions.mineProject.createMineImage({
            seed,
            project
          })
        )
      )
    )
  )

export const epics = combineEpics(
  listenOnMintSuccess,
  reloadCollectedEpic,
  generateMineImageEpic,
  pickSeedToGenerateEpic
)
export default reducer
