import React, { useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import TooltipMaterial, { TooltipProps as TooltipMaterialProps } from '@mui/material/Tooltip'
import ButtonBase from '@mui/material/ButtonBase'

import Platform from 'utils/Platform'

import styles from './Tooltip.module.scss'

const TOOLTIPCLICK_POPPER_PROPS = {
  disablePortal: true
}

type TooltipProps = TooltipMaterialProps

const TooltipWithClick: React.FC<TooltipProps> = props => {
  const { children, title, ...restProps } = props
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const toggleTooltipOpen = () => {
    setOpen(prev => !prev)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={styles.TooltipWithClickContainer}>
        <TooltipMaterial
          {...restProps}
          PopperProps={TOOLTIPCLICK_POPPER_PROPS}
          onClose={handleTooltipClose}
          open={open}
          title={title}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <ButtonBase onClick={toggleTooltipOpen}>{children}</ButtonBase>
        </TooltipMaterial>
      </div>
    </ClickAwayListener>
  )
}

const Tooltip: React.FC<
  TooltipProps & {
    trigger?: 'click'
  }
> = props => {
  const { placement, disableInteractive = true, trigger, ...restProps } = props
  const isControlled = typeof props.open !== 'undefined'

  if (!isControlled && (trigger === 'click' || (!trigger && Platform.isTouchDevice())))
    return (
      <TooltipWithClick
        {...restProps}
        disableInteractive={disableInteractive}
        placement={placement}
      />
    )

  return (
    <TooltipMaterial
      {...restProps}
      disableInteractive={disableInteractive}
      placement={placement || 'right-end'}
    />
  )
}

export default Tooltip
