import {
  apiActions,
  ErrorBundleType,
  MineProjectActionType,
  RequestType,
  sharedActions,
  SharedActionsType
} from './actions'
import {
  PaginationResponse,
  MineProject,
  MineImage,
  InputImageSet,
  ImageType,
  ArtMineConfig,
  BannerImage,
  PrepareMintData
} from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'
import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _concat from 'lodash/concat'
import _uniq from 'lodash/uniq'
import { combineReducers } from 'redux'

export type MineProjectState = {
  accounts?: string[]
  mineProject: {
    openedId?: number
    lists: {
      page: number[][]
      home: number[]
      featured: number[]
      studio: number[]
      topSell: number[]
    }
    data: Record<number, MineProject>
    lastListReq?: PaginationResponse<MineProject>
  }
  inputs: Record<number, InputImageSet>
  prepareMineData: Record<number, PrepareMintData>
  collectionImages: {
    [collectionId: number]: PaginationResponse<ImageType>
  }
  mineConfig?: ArtMineConfig
  mineImage: {
    data: Record<number, MineImage>
    userList: {
      list: number[]
      lastReq?: PaginationResponse<MineImage>
    }
    sampleLists: Record<number, number[]>
    collectedList: Record<
      number,
      {
        list: number[]
        lastReq: PaginationResponse<MineImage>
      }
    >
  }
  bannerImages?: BannerImage[]
}

export const INITIAL_MINE_PROJECT_STATE: MineProjectState = {
  mineProject: {
    openedId: undefined,
    data: {},
    lists: {
      page: [],
      home: [],
      featured: [],
      studio: [],
      topSell: []
    },
    lastListReq: undefined
  },
  prepareMineData: {},
  mineConfig: undefined,
  inputs: {},
  collectionImages: {},
  mineImage: {
    data: {},
    userList: {
      list: [],
      lastReq: undefined
    },
    sampleLists: {},
    collectedList: {}
  }
}

export const mineProjectsReducer = createReducer<MineProjectState, MineProjectActionType>(
  INITIAL_MINE_PROJECT_STATE
)
  .handleAction(
    apiActions.mineProject.retrieveProjectResponse,
    (state, action): MineProjectState => {
      const id = action.payload.id
      const currentData = state.mineProject.data

      return {
        ...state,
        mineProject: {
          ...state.mineProject,
          data: {
            ...currentData,
            [id]: action.payload
          }
        }
      }
    }
  )
  .handleAction(apiActions.mineProject.setAccount, (state, action) => ({
    ...state,
    accounts: action.payload
  }))
  .handleAction(
    apiActions.mineProject.listMineProjectsResponse,
    (state, action): MineProjectState => {
      const { data, req } = action.payload

      const results = data?.results ?? []
      const page = req.page ?? 0
      const combinedResults = results.map(result => ({
        ...(state.mineProject.data[result.id] ?? {}),
        ...result
      }))

      const newPage = [...(state.mineProject.lists.page ?? [])]
      newPage[page] = results.map(value => value.id)

      return {
        ...state,
        mineProject: {
          ...state.mineProject,
          lists: {
            ...state.mineProject.lists,
            page: newPage
          },
          data: {
            ...state.mineProject.data,
            ..._keyBy(combinedResults, result => result.id)
          },
          lastListReq: data
        }
      }
    }
  )

  .handleAction(
    apiActions.mineProject.listMineProjectsHomeResponse,
    (state, action): MineProjectState => {
      const { data } = action.payload

      const results = data?.results ?? []
      const combinedResults = results.map(result => ({
        ...(state.mineProject.data[result.id] ?? {}),
        ...result
      }))

      return {
        ...state,
        mineProject: {
          ...state.mineProject,
          lists: {
            ...state.mineProject.lists,
            home: results.map(value => value.id)
          },
          data: {
            ...state.mineProject.data,
            ..._keyBy(combinedResults, result => result.id)
          }
        }
      }
    }
  )
  .handleAction(
    apiActions.mineProject.listStudioMineProjectsResponse,
    (state, action): MineProjectState => {
      const { data } = action.payload

      const results = data?.results ?? []
      const combinedResults = results.map(result => ({
        ...(state.mineProject.data[result.id] ?? {}),
        ...result
      }))

      return {
        ...state,
        mineProject: {
          ...state.mineProject,
          lists: {
            ...state.mineProject.lists,
            studio: results.map(value => value.id)
          },
          data: {
            ...state.mineProject.data,
            ..._keyBy(combinedResults, result => result.id)
          }
        }
      }
    }
  )
  .handleAction(
    apiActions.mineProject.listFeaturedMineProjectsResponse,
    (state, action): MineProjectState => {
      const { data } = action.payload

      const results = data?.results ?? []
      const combinedResults = results.map(result => ({
        ...(state.mineProject.data[result.id] ?? {}),
        ...result
      }))

      return {
        ...state,
        mineProject: {
          ...state.mineProject,
          lists: {
            ...state.mineProject.lists,
            featured: results.map(value => value.id)
          },
          data: {
            ...state.mineProject.data,
            ..._keyBy(combinedResults, result => result.id)
          }
        }
      }
    }
  )
  .handleAction(
    apiActions.mineProject.listTopMineProjectsResponse,
    (state, action): MineProjectState => {
      const { data } = action.payload

      const results = data?.results ?? []
      const combinedResults = results.map(result => ({
        ...(state.mineProject.data[result.id] ?? {}),
        ...result
      }))

      return {
        ...state,
        mineProject: {
          ...state.mineProject,
          lists: {
            ...state.mineProject.lists,
            topSell: results.map(value => value.id)
          },
          data: {
            ...state.mineProject.data,
            ..._keyBy(combinedResults, result => result.id)
          }
        }
      }
    }
  )
  .handleAction(apiActions.mineProject.setOpenedProjectId, (state, action) => {
    const id = action.payload

    return {
      ...state,
      mineProject: {
        ...state.mineProject,
        openedId: id
      }
    }
  })
  .handleAction(apiActions.mineProject.prepareMintImageResponse, (state, action) => {
    const id = action.payload.projectId

    return {
      ...state,
      prepareMineData: {
        ...state.prepareMineData,
        [id]: action.payload.data
      }
    }
  })
  .handleAction(
    [
      apiActions.mineProject.createMineImageResponse,
      apiActions.mineProject.retrieveMineImageResponse
    ],
    (state, action) => {
      const id = action.payload.id

      return {
        ...state,
        mineImage: {
          ...state.mineImage,
          data: {
            ...state.mineImage.data,
            [id]: action.payload
          }
        }
      }
    }
  )
  .handleAction(apiActions.mineProject.listMineProjectCollectedOutputsResponse, (state, action) => {
    const { data, req, next } = action.payload
    const projectId = req.project ?? 0
    const results = data?.results ?? []
    const prevData = next ? state.mineImage.collectedList[projectId].list ?? [] : []

    const combinedResults =
      results?.map(result => ({
        ...(state.mineImage.data[result.id] ?? {}),
        ...result
      })) ?? {}

    return {
      ...state,
      mineImage: {
        ...state.mineImage,
        data: {
          ...state.mineImage.data,
          ..._keyBy(combinedResults, result => result.id)
        },
        collectedList: {
          ...state.mineImage.collectedList,
          [projectId]: {
            list: _uniq([...prevData, ..._map(results, result => result.id)]),
            lastReq: data
          }
        }
      }
    }
  })
  .handleAction(
    apiActions.mineProject.listMineProjectUserCollectedOutputsResponse,
    (state, action) => {
      const { data, next } = action.payload
      const results = data?.results ?? []
      const prevData = next ? state.mineImage.userList?.list ?? [] : []

      const combinedResults =
        results?.map(result => ({
          ...(state.mineImage.data[result.id] ?? {}),
          ...result
        })) ?? {}

      return {
        ...state,
        mineImage: {
          ...state.mineImage,
          data: {
            ...state.mineImage.data,
            ..._keyBy(combinedResults, result => result.id)
          },
          userList: {
            list: _uniq([...prevData, ..._map(results, result => result.id)]),
            lastReq: data
          }
        }
      }
    }
  )
  .handleAction(apiActions.mineProject.listMineProjectSampleOutputsResponse, (state, action) => {
    const { data, req } = action.payload
    const projectId = req.project ?? 0
    const results = data?.results ?? []

    const combinedResults =
      results?.map(result => ({
        ...(state.mineImage.data[result.id] ?? {}),
        ...result
      })) ?? {}

    return {
      ...state,
      mineImage: {
        ...state.mineImage,
        data: {
          ...state.mineImage.data,
          ..._keyBy(combinedResults, result => result.id)
        },
        sampleLists: {
          ...state.mineImage.sampleLists,
          [projectId]: _map(results, result => result.id)
        }
      }
    }
  })
  .handleAction(apiActions.mineProject.listImageResponse, (state, action) => {
    const { data, req, next } = action.payload
    const currentImages = state.collectionImages[req.collection] || {}

    /* Without next, images will be replaced, not added */
    const currentResults = next && currentImages.results ? currentImages.results : []

    return {
      ...state,
      collectionImages: {
        ...state.collectionImages,
        [req.collection]: {
          ...currentImages,
          ...data,
          results: _uniq(_concat(currentResults, data.results || []))
        }
      }
    }
  })
  .handleAction(apiActions.mineProject.startMineImageResponse, (state, action) => {
    return {
      ...state,
      mineImage: {
        ...state.mineImage,
        userList: {
          ...state.mineImage.userList,
          list: _uniq([action.payload, ...state.mineImage.userList.list])
        }
      }
    }
  })
  .handleAction(apiActions.mineProject.retrieveInputResponse, (state, action) => {
    const id = action.payload.id

    return {
      ...state,
      inputs: {
        ...state.inputs,
        [id]: action.payload
      }
    }
  })
  .handleAction(apiActions.mineProject.retrieveConfigResponse, (state, action) => {
    return {
      ...state,
      mineConfig: action.payload
    }
  })
  .handleAction(apiActions.mineProject.listBannerImagesResponse, (state, action) => {
    const data = action.payload?.data?.results ?? []
    return {
      ...state,
      bannerImages: data
    }
  })

export type ErrorsType = {
  [key in RequestType]?: ErrorBundleType
}

export type LoadingsType = {
  [key in RequestType]?: boolean | string
}

export type SharedState = {
  errors: ErrorsType
  loadings: LoadingsType
  imageListFetchState: { [key: string]: boolean }
}
export const initialSharedState: SharedState = {
  errors: {},
  loadings: {},
  imageListFetchState: {}
}

export const sharedReducer = createReducer<SharedState, SharedActionsType>(initialSharedState)
  .handleAction(sharedActions.setError, (state, action) => {
    const { type, ...data } = action.payload
    return {
      ...state,
      errors: {
        ...state.errors,
        [type]: data
      },
      loadings: {
        ...state.loadings,
        [type]: false
      }
    }
  })
  .handleAction(sharedActions.setLoading, (state, action) => ({
    ...state,
    loadings: {
      ...state.loadings,
      [action.payload.type]: action.payload.loading
    }
  }))
  .handleAction(sharedActions.setImageListFetchState, (state, action) => ({
    ...state,
    imageListFetchState: {
      ...state.imageListFetchState,
      [action.payload.key]: action.payload.value
    }
  }))
  .handleAction(sharedActions.resetError, (state, action) => {
    const newData: ErrorsType = {}
    if (Array.isArray(action.payload)) {
      action.payload.forEach(actionType => {
        newData[actionType] = undefined
      })
    } else {
      newData[action.payload] = undefined
    }

    return {
      ...state,
      errors: {
        ...state.errors,
        ...newData
      }
    }
  })
  .handleAction(sharedActions.resetAllErrors, state => ({
    ...state,
    errors: {}
  }))

export const reducers = combineReducers({
  mineProjects: mineProjectsReducer,
  shared: sharedReducer
})
