import {
  ArtMineConfig,
  Feedback,
  FeedbackCreateReq,
  ImageListReq,
  ImageType,
  InputImageSet,
  ListOutputsRequest,
  MailChimpCreateReq,
  MineImage,
  MineImageCreateReq,
  MineProject,
  ListMineProjectReq,
  Notify,
  NotifyCreateReq,
  PaginationRequestParams,
  PaginationResponse,
  BannerImage,
  MineProjectUpdateReq,
  PrepareMintData
} from 'models/ApiModels'

import Cookies from 'js-cookie'
import axios from 'axios-observable'
import urljoin from 'url-join'
import { SessionStorage } from 'utils'
import { LSKey } from 'appConstants'
import { AxiosRequestConfig } from 'axios'

export const AUTH_TOKEN_NAME = 'aican_auth'
export const API_BASE_URL = process.env.REACT_APP_API_URL
export const DEFAULT_API_TIMEOUT = 300 * 1000

export function removeAuthCookie() {
  Cookies.remove(AUTH_TOKEN_NAME)
}

axios.defaults.baseURL = API_BASE_URL
axios.defaults.xsrfCookieName = axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

function includeAuthToken(reqConfig: AxiosRequestConfig): AxiosRequestConfig {
  if (reqConfig.headers) {
    reqConfig.headers['Content-Type'] = 'application/json'
    reqConfig.headers['Accept'] = 'application/json, text/plain, */*'
  }

  const userDebug = SessionStorage.get(LSKey.USER_DEBUG_EMAIL)

  if (userDebug && reqConfig.headers) {
    reqConfig.headers['X-DEBUG-USER'] = userDebug
  }

  return reqConfig
}

axios.interceptors.request.use(includeAuthToken)

const artMineOutputsUrl = '/api/artmine/outputs/'
const artMineConfigUrl = '/api/artmine/configs/'
const artMineProjectUrl = '/api/artmine/projects/'
const collectionImagesUrl = '/api/collection/images/'
const inputsUrl = '/api/v2/inputs/'
const feedbackUrl = `/api/artmine/feedbacks/`
const notifyUrl = `/api/artmine/notify/`
const mailchimpUrl = `/api/mailchimp/`
const bannerUrl = `/api/social/banners/`

const mineActions = 'mine/'
const prepareMineActions = 'prepare-mint/'

export type TOP_API_URLS =
  | typeof artMineOutputsUrl
  | typeof artMineProjectUrl
  | typeof collectionImagesUrl
  | typeof inputsUrl
  | typeof artMineConfigUrl
  | typeof feedbackUrl
  | typeof notifyUrl
  | typeof mailchimpUrl
  | typeof bannerUrl

export type URL_ACTIONS = typeof mineActions | typeof prepareMineActions

const listCreator =
  <Res, P = {}>(url: TOP_API_URLS) =>
  (params?: PaginationRequestParams, extraParams?: P) =>
    params && params.next
      ? axios.get<Res>(params.next, { params: extraParams })
      : axios.get<Res>(url, { params: Object.assign({}, params, extraParams) })

const retrieveCreator =
  <T>(url: TOP_API_URLS) =>
  (id: string | number) =>
    axios.get<T>(urljoin(url, id.toString(), '/'))

const updateCreator =
  <Res, Req>(url: TOP_API_URLS, method: 'patch' | 'put' = 'patch') =>
  (id: string | number, data: Req) => {
    const axiosFunction = method === 'patch' ? axios.patch : axios.put
    return axiosFunction<Res>(urljoin(url, id.toString(), '/'), data)
  }

const createCreator =
  <Res, Req>(url: TOP_API_URLS, additionalParam?: object) =>
  (data: Req) =>
    axios.post<Res>(url, data, { ...additionalParam }) // <--Using spread syntax because of canceling problem https://github.com/zhaosiyang/axios-observable/issues/11

// const deleteCreator =
//   <T = {}>(url: TOP_API_URLS) =>
//   (id: string | number) =>
//     axios.delete<T>(urljoin(url, id.toString(), '/'))

const urlActionCreator =
  <Res, Req = {}>(url: TOP_API_URLS, action: URL_ACTIONS) =>
  (id: string | number, data?: Req) =>
    axios.post<Res>(urljoin(url, id.toString(), action), data)

export const mineProject = {
  listOutputs: listCreator<PaginationResponse<MineImage>, ListOutputsRequest>(artMineOutputsUrl),
  retrieveMineImage: retrieveCreator<MineImage>(artMineOutputsUrl),
  createMineImage: createCreator<MineImage, MineImageCreateReq>(artMineOutputsUrl),
  createFeedback: createCreator<Feedback, FeedbackCreateReq>(feedbackUrl),
  createNotify: createCreator<Notify, NotifyCreateReq>(notifyUrl),
  createMailchimp: createCreator<null, MailChimpCreateReq>(mailchimpUrl),
  startMineImage: urlActionCreator(artMineOutputsUrl, mineActions),
  prepareMintImage: urlActionCreator<PrepareMintData>(artMineProjectUrl, prepareMineActions),
  listProjects: listCreator<PaginationResponse<MineProject>, ListMineProjectReq>(artMineProjectUrl),
  retrieveProject: retrieveCreator<MineProject>(artMineProjectUrl),
  updateProject: updateCreator<MineProjectUpdateReq, MineProject>(artMineProjectUrl),
  retrieveInput: retrieveCreator<InputImageSet>(inputsUrl),
  listBannerImages: listCreator<PaginationResponse<BannerImage>, null>(bannerUrl),
  listImage: listCreator<PaginationResponse<ImageType>, ImageListReq>(collectionImagesUrl),
  retrieveConfig: () => axios.get<ArtMineConfig>(artMineConfigUrl)
}
