import { XOR } from 'ts-xor'

export type UserUiExtrasType = {
  is_saw_collection_remove_confirm?: boolean
  is_seen_public_project_welcome?: boolean
  is_saw_welcome_slider?: boolean
  is_did_initial_project_creation?: boolean
  is_did_subscribe_plus?: boolean
  is_did_subscribe_pro?: boolean
  is_did_subscribe_studio?: boolean
  has_redeem_free_credit?: boolean
}

export type User = Readonly<{
  id: string
  username: string
  first_name: string
  last_name: string
  email: string
  portfolio?: string
  is_agree_toc: boolean | null
  ui_extras?: UserUiExtrasType
  is_staff: boolean
  phone: string
  can_debug_user: boolean

  is_receive_news: boolean | null
  is_receive_social_update: boolean | null
  is_receive_project_update: boolean | null
  is_receive_management: boolean | null
}>

export interface ImageType {
  id: number
  file: string
  width: number
  height: number
  thumbnail?: string | null
  created?: string
}
export type BookmarkType = 'user-image' | 'post' | 'collection' | 'bookmark-create'

export interface Bookmarkable {
  bookmark: number | null
}
export interface Shareable {
  object_type: 'user_image'
}

export type ImageListReq = {
  collection: number
  inputs?: number
} & PaginationRequestParams

export interface Shareable {
  object_type: 'user_image'
}

export interface Adjustable {
  adjust: number | null
}

export interface Downloadable {
  download: number | null
}

export type UserImage = ImageType & Bookmarkable & Shareable & Adjustable & Downloadable

export type ProjectUiExtrasType = {
  is_finish_checked?: boolean
  aesthetic_background_strip?: string
  inspiration_background_strip?: string
  is_generated_opened?: boolean
  is_has_clip?: boolean
}

export type ProjectProgress = {
  started_at: string
  percent: number
  minutes: number
}

export type ProjectObjectType =
  | 'pretrain_mix_project'
  | 'training_project'
  | 'sketch_project'
  | 'training_mix_project'
  | 'transfer_project'
  | ''

export type ProjectStatus = 'drafted' | 'waiting' | 'processing' | 'ready' | 'stopped' | 'failed'

export type Project = Readonly<{
  // Server Data
  id: number
  is_private: boolean
  category: string
  thumbnail: UserImage
  inspiration: number | null
  aesthetic: number | null
  ui_extras?: ProjectUiExtrasType
  created: string
  modified: string
  progress: ProjectProgress
  status: ProjectStatus
  mix: number | null
  has_noises: boolean
  object_type: ProjectObjectType
  total_snapshot: number
  current_snapshot: number
  outputs_count: number
  bookmark_scope: string
}>

export type MineStatusType = 'created' | 'started' | 'succeed' | 'failed'
export const MINING_STATUS: MineStatusType[] = ['created', 'started']
export type MineImage = {
  id: number
  code: number //	range [0, 100,000,000)
  seed: number //	range [0, 100,000,000)
  image: UserImage
  project: number //	id of MineProject
  user_address: string //	user account address in blockchain
  mine_status: MineStatusType
  status: 'UNKNOWN' | 'FINISHED' | 'FAILED'
  created: string
  modified: string
  ipfs_uri: string
  signature: string
  mine_price: string
  opensea_url: string
  etherscan_url: string
  rarible_url: string
  expire_at: number

  // derived variable.
  project_name?: string
  project_by?: string
}

export const CURATION_LIST = ['artist', 'collector'] as const
export type CurationType = typeof CURATION_LIST[number]

export type PrepareMintData = {
  id: number
  code: number
  ipfs_uri: string
  mine_price: number
  expire_at: number
  signature: string
}

export type MineProject = {
  id: number
  network: 'POLYGON' | 'ETHEREUM'
  thumbnail?: UserImage
  created: number
  modified: number
  description: string
  mine_limit: number
  mine_count: number
  current_price: string // price for next image to mine
  user_full_name: string
  title: string
  license: string
  email: string
  instagram_url: string
  twitter_url: string
  type: CurationType
  end_price: string
  start_price: string
  is_featured: boolean
  is_published: boolean
  artist_url: string

  // TODO
  additional_benefit: string
  artist_biography: string
  studio: boolean
}

export type MineProjectUpdateReq = Pick<MineProject, 'id'> &
  Partial<Pick<MineProject, 'is_featured'>>

export type BannerImage = {
  image: ImageType
  mobile_image: ImageType
  link: string
}

export type PageParams = {
  page?: number
  limit?: number
  offset?: number
}

type NextPageParams = {
  next?: string
}
export type PaginationResponse<T> = Readonly<{
  count: number
  next: string
  previous: string
  results?: Array<T>
}>

export const ListMineProjectSortList = [
  'date',
  'trending',
  'top',
  'price',
  '-date',
  '-trending',
  '-top',
  '-price'
] as const

export type ListMineProjectReq = PageParams & {
  sort?: typeof ListMineProjectSortList[number]
  is_published?: boolean
  user?: number
} & Partial<Pick<MineProject, 'studio' | 'type' | 'is_featured' | 'is_published'>>

export type ListMineProjectReqType = keyof ListMineProjectReq

export type Collection = Readonly<{
  // Server Data
  id: number
  name: string
  is_private: boolean
  thumbnail: UserImage | null
  created: string
  modified: string
  count: number
  tags: string[]
  category: number
  username: string
  user: Pick<User, 'email' | 'first_name' | 'id' | 'last_name'>
  is_draft: boolean
}>

export type InputImageSet = {
  id: number
  images: { [collectionId: number]: number[] }
  collections: { [collectionId: number]: Collection }
  collections_order: string[]
  tags: { [collectionId: number]: { removed: boolean } }

  //Derived data
  imageCount?: number
  imagesSet?: { [collectionId: number]: Set<number> }
  imagesData?: { [collectionId: number]: PaginationResponse<ImageType> | null }
  imagesThumbnail?: { [collectionId: number]: ImageType[] | null }
}

export type PaginationRequestParams = Partial<XOR<PageParams, NextPageParams>>

export type ListOutputsRequest = {
  project?: number
  user_address?: string
  is_sample?: boolean
  mine_status?: MineStatusType
} & PaginationRequestParams

export type MineImageCreateReq = {
  seed: number
  project: number
}

export type ArtMineConfig = {
  contract_abi: Record<number, object>
  contract_address: string
  chain_id: number
  audience_id: string

  polygon_chain_id: number
  polygon_contract_address: string
  polygon_contract_abi: Record<number, object>
}

export type Feedback = {
  id: number
  created: string
  first_name: string
  last_name: string
  email: string
  content: string
}

export type FeedbackCreateReq = {
  first_name: string
  last_name: string
  email: string
  content: string
}

export type NotifyCreateReq = {
  email: string
  project: number
}

export type Notify = {
  email: string
  project: number
  created: string
}

export type MailChimpCreateReq = {
  email: string
  audience_id: string
}
