import { useState, useLayoutEffect } from 'react'

type WindowDimensionObject = {
  height: number
  width: number
}

const WINDOW_DIMENSION_DEFAULT: WindowDimensionObject = {
  height: 0,
  width: 0
}

function getWindowDimensionObject(): WindowDimensionObject {
  var w = 0
  var h = 0

  //IE
  if (!window.innerWidth) {
    if (!(document.documentElement.clientWidth === 0)) {
      //strict mode
      w = document.documentElement.clientWidth
      h = document.documentElement.clientHeight
    } else {
      //quirks mode
      w = document.body.clientWidth
      h = document.body.clientHeight
    }
  } else {
    //w3c
    w = window.innerWidth
    h = window.innerHeight
  }

  return { width: w, height: h }
}

export const useWindowDimensionDetector = () => {
  const [dimension, setDimension] = useState(WINDOW_DIMENSION_DEFAULT)

  useLayoutEffect(() => {
    if (window) {
      const measure = () =>
        window.requestAnimationFrame(() => setDimension(getWindowDimensionObject()))
      measure()

      window.addEventListener('resize', measure)
      // window.addEventListener('scroll', measure)

      return () => {
        window.removeEventListener('resize', measure)
        // window.removeEventListener('scroll', measure)
      }
    }
  }, [])

  return dimension
}
