export const defaultPageTitle = 'Art Mine by Playform'
export const defaultDescription =
  'Art Mine is a unique art gallery that invites the collector to participate in the curation of AI-generated art. Find your one-of-a-kind AI Art NFT.'

/*  */
export const BREAKPOINTS_SMALLSCREEN_UP = ['lg', 'xl', 'md', 'sm']
export const BREAKPOINTS_MEDIUMSCREEN_UP = ['lg', 'xl', 'md']
export const BREAKPOINTS_LARGESCREEN_UP = ['lg', 'xl']
export const BREAKPOINTS_LARGESCREEN_DOWN = ['xs', 'sm', 'md']
export const BREAKPOINTS_MEDIUMSCREEN_DOWN = ['xs', 'sm']
export const BREAKPOINTS_EXTRASMALL = ['xs']

/*  */
export const LSKey = {
  IS_CONNECTED_TO_METAMASK: 'a00fd7b2674aa32294f49213e3abbec9',
  USER_DEBUG_EMAIL: 'b8cbde65f7aab82bc5c4f102160e4bed'
}

export const values = {
  WEI_TO_ETH: 1 / 1e18,
  MAX_UPSCALE_SIZE: 12 * 1024,
  SOURCE_FROM_PRETRAINED: 'pretrained_model',
  MIN_UPLOADED_IMAGE: 30,
  MAX_PROJECT_NAME: 50,
  HOME_PAGE_PROJECT_COUNT: 20,
  HOME_PAGE_PROJECT_LIST_COUNT: 8,
  UPLOAD_ACCEPT: 'image/jpeg, image/png',
  MAX_IMAGE_SIZE: 10485760,
  MAX_IMAGE_SIZE_MEGABYTE: '10 MB',
  MIN_PUBLIC_COLLECTION_IMAGE: 5,
  ADD_CREDIT_PRODUCT_CODE: 'playform_credit',
  PLAYFORM_CONTACT_EMAIL: 'playform@playform.io',
  PLAYFORM_SUPPORT_EMAIL: 'support@playform.io',
  PLAYFORM_URL: 'https://www.playform.io',
  PLAYFORM_CREATE_URL: 'https://create.playform.io/login',
  PLAYFORM_ARTMINE_LEARN_MORE: 'https://www.playform.io/artmine',
  SUBMIT_FEEDBACK_DELAY: 60000,
  DATE_FORMAT: 'MM/DD/YYYY',
  TIMESTAMP_FORMAT: 'MM/DD/YYYY, h:mm A',
  TIMESTAMP_FORMAT_NO_YEAR: 'MMM DD, h:mm A',
  TIMESTAMP_FORMAT_TIME_ONLY: 'h:mma',
  TIMESTAMP_FORMAT_2: 'MMMM DD YYYY, h:mm A',
  TIMESTAMP_FORMAT_2_NO_TIME: 'MMMM DD, YYYY',
  DIALOG_TRANSITION_DURATION: 500,
  SUBMIT_PROJECT_LINK: process.env.REACT_APP_SUBMIT_PROJECT_LINK,
  INSTAGRAM: process.env.REACT_APP_INSTAGRAM,
  DISCORD: process.env.REACT_APP_DISCORD,
  TWITTER: process.env.REACT_APP_TWITTER,
  OG_IMAGE_URL: 'https://s3.us-east-2.amazonaws.com/media.playform/social_artmine.png',
  AUDIENCE_ID: process.env.REACT_APP_AUDIENCE_ID,
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  BASE_PROJECT_LIST_PARAM: {
    limit: 30,
    // limit: 8,
    page: 0
  },
  ADMIN_WALLET: ''
}

export type ProjectsTabType = 'all' | 'studio' | 'artist_curated' | 'collector_curated' | 'featured'

export const PROJECTS_TAB_DATA_DESCRIPTION: Record<ProjectsTabType, string> = {
  all: 'Art Mine presents a collaborative way to create and experience art. Artists create generative AI models for you to mine and mint as NFTs. Each NFT is one-of-a-kind and can only be minted once.',
  studio: 'Playform Studio is an elite group of AI artists who are at the core of our community.',
  artist_curated:
    'Artist selects the best results generated from their model to make available for sale.',
  collector_curated: 'Collector selects the best results generated from artist-trained AI model.',
  featured: ''
}

export type License = 'nft_license_2.0' | 'cc_by-nc_4.0' | 'unrestricted'

export const LICENSE_TEXT: Record<License, string> = {
  'cc_by-nc_4.0': 'CC BY-NC 4.0',
  'nft_license_2.0': 'NFT License 2.0',
  unrestricted: 'Unrestricted'
}
