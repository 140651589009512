import { useEffect, useContext, useRef, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import _toNumber from 'lodash/toNumber'
import { BreakpointContext } from 'context'

import artmineTheme from 'artmineTheme'

export * from './useDimension'
export * from './useDelayedLoading'
export * from './useScroll'
export * from './useWindowDimensionDetector'

/*  */
const theme = artmineTheme
const breakpointKeys = [...theme.breakpoints.keys]
const breakpointKeysReversed = [...breakpointKeys].reverse()

export const useWidth = () => {
  if (!theme.breakpoints) return undefined

  return (
    breakpointKeysReversed.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))

      return !output && matches ? key : output
    }, '') || 'xs'
  )
}

/*  */
export const useDelayedRender = (timeout: number = 300, shouldStartCount: boolean = true) => {
  const [shouldRender, setIsShouldRender] = useState<boolean>(false)
  const timeoutId = useRef(0)

  useEffect(() => {
    if (timeoutId.current || !shouldStartCount) {
      window.clearTimeout(timeoutId.current)
    }
    timeoutId.current = window.setTimeout(() => {
      if (shouldStartCount) {
        setIsShouldRender(true)
      }
    }, timeout)

    return () => {
      if (timeoutId.current) window.clearTimeout(timeoutId.current)
    }
  }, [shouldRender, shouldStartCount, timeout])

  return shouldRender
}

/*  */
export const useBreakpoint = () => {
  return useContext(BreakpointContext)
}

/*  */
export function useRefEffect<ReturnType>(value: any) {
  const ref = useRef<ReturnType>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}

/*  */
export function usePrevious<ReturnType>(value: any) {
  const ref = useRef<ReturnType>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

// Hook
export function useDebounce<Value>(value: Value, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<Value>(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

/* toNumber with NaN fallback */
export const toNumber = (value?: any) => {
  if (typeof value === 'undefined') return 0

  const result = _toNumber(value)

  return !isNaN(result) ? result : 0
}
