import React, { useState } from 'react'
import Button from 'components/Button'
import Typography from 'components/Typography'
import stylesGlobal from 'stylesGlobal.module.scss'

type ErrorPanelProps = {
  isUserCanceled?: boolean
  errorMessage?: string
}

export const ErrorPanel: React.FC<ErrorPanelProps> = props => {
  const { errorMessage, isUserCanceled } = props

  const [showErrorMessageDetail, setShowErrorMessageDetail] = useState(false)

  return (
    <>
      {errorMessage ? (
        <>
          <Typography title={errorMessage} className={stylesGlobal.PaddingTop12} color="error">
            {`${isUserCanceled ? `Minting canceled ` : 'Minting failed'}, try again?`}
          </Typography>
          {!isUserCanceled ? (
            <>
              {showErrorMessageDetail ? (
                <Typography
                  variant="body2"
                  fontSize={12}
                  title={errorMessage}
                  className={stylesGlobal.PaddingTop12}
                  color="error"
                >
                  <i>{errorMessage}</i>
                </Typography>
              ) : null}
              <Button
                variant="text"
                size="small"
                onClick={() => setShowErrorMessageDetail(value => !value)}
                className={stylesGlobal.MarginTop12}
              >
                <Typography variant="body2">
                  {showErrorMessageDetail ? 'Hide Error Detail' : 'Show Error Detail'}
                </Typography>
              </Button>
            </>
          ) : null}
        </>
      ) : null}
    </>
  )
}
