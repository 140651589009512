import mixpanel from 'mixpanel-browser'
import _reduce from 'lodash/reduce'
import {
  FeedbackCreateReq,
  MailChimpCreateReq,
  MineProject,
  NotifyCreateReq
} from 'models/ApiModels'
import dayjs from 'dayjs'

/* 
  Event that need to be updated 
  PROJECT__ADD_COLLECTION_TO_PROJECT: {
*/

export const Properties = {
  PROJECT_TYPE: 'project_type',
  ADDRESS: 'address',
  PROJECT_NAME: 'project_name',
  PROJECT_ID: 'project_id',
  SEED: 'seed',
  CHAIN_ID: 'chain_id',
  NETWORK: 'network',
  PRICE: 'price',
  CONNECT_LOCATION: 'connect_location',
  GENERATE_BUTTON_TYPE: 'generate_button_type',
  ROUTE: 'route',
  DIALOG_NAME: 'dialog_name'
} as const

const P = Properties

export type ConnectLocationType = 'Navbar' | 'Dialog' | 'Collection Page' | 'On Page Open'
export type GenerateButtonType = 'Generate' | 'Pick For Me'

type DefaultPropertyType = string | string[] | object | number

export type Events = {
  CONNECT_TO_METAMASK: {
    name: 'Connect To Metamask'
    properties: {
      [P.ADDRESS]: string
      [P.CHAIN_ID]: number | undefined
      [P.NETWORK]: MineProject['network'] | undefined
      [P.CONNECT_LOCATION]: ConnectLocationType
    }
  }
  DISCONNECT_FROM_METAMASK: {
    name: 'Disconnect From Metamask'
    properties: undefined
  }
  GENERATE_IMAGE: {
    name: 'Generate Image'
    properties: {
      [P.GENERATE_BUTTON_TYPE]: GenerateButtonType
      [P.SEED]: DefaultPropertyType
    }
  }
  MINE_IMAGE: {
    name: 'Mine Image'
    properties: {
      [P.PROJECT_ID]: DefaultPropertyType
      [P.PROJECT_NAME]: DefaultPropertyType
      [P.SEED]?: DefaultPropertyType
      [P.PROJECT_TYPE]?: MineProject['type']
      [P.PRICE]: DefaultPropertyType
      [P.CHAIN_ID]: number | undefined
      [P.NETWORK]: MineProject['network'] | undefined
    }
  }
  USER__OPEN_DIALOG: {
    name: 'Open Dialog'
    properties: { [P.DIALOG_NAME]: DefaultPropertyType }
  }
  PAGE_VIEW: {
    name: 'Page View'
    properties: { [P.ROUTE]: DefaultPropertyType }
  }
  CLICK_SUBMIT_PROJECT: {
    name: 'Click Submit Project'
    properties: { [P.ROUTE]: DefaultPropertyType }
  }
  SEND_FEEDBACK: {
    name: 'Send Feedback'
    properties: FeedbackCreateReq
  }
  SEND_EMAIL: {
    name: 'Send Email'
    properties: NotifyCreateReq
  }
  SEND_EMAIL_MAILCHIMP: {
    name: 'Send Email Mailchimp'
    properties: MailChimpCreateReq
  }
}

type EventType = keyof Events

export type SetPeopleData = {
  address: string
  last_time_connect: string
}

export type UpdatePeopleData = Partial<SetPeopleData> & {
  last_login_date?: string
  credit_balance?: boolean
  subscription?: string
  referral_from_name?: string
  referral_from_email?: string
  referral_from_id?: string
}

/* To make sure the data is consistent */
export const DataUtils = {
  formatDate: (input?: string) => (input ? dayjs(input).toISOString() : undefined),
  getObjectKeys: (object: object) => {
    const objectKeys = _reduce(
      object,
      (result, value, key) => {
        if (value !== undefined && value !== null) {
          result.push(key)
        }
        return result
      },
      [] as string[]
    )
    return objectKeys
  }
}

const hasToken = Boolean(process.env.REACT_APP_MIX_PANEL_TOKEN)

const MixPanelUtils = {
  init: () => {
    const token = process.env.REACT_APP_MIX_PANEL_TOKEN
    if (token) {
      mixpanel.init(token)
    }
  },
  identify: (userId?: string) => {
    hasToken && mixpanel.identify(userId ? userId : undefined)
  },
  timeEvent: (event: EventType) => {
    hasToken && mixpanel.time_event(event)
  },
  track: <T extends EventType>(
    eventName: Events[T]['name'],
    properties?: Events[T]['properties']
  ) => {
    hasToken && mixpanel.track(eventName, properties)
  },
  onLogout: () => {
    hasToken && mixpanel.reset()
  },
  setPeople: (data: SetPeopleData) => {
    hasToken && mixpanel.people.set_once(data)
  }
}

export default MixPanelUtils
