import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { values } from 'appConstants'
import dayjs from 'dayjs'
import preval from 'preval.macro'

/* 
  Need to be manually logged
  - Error On upload images //ok
  - Error On download images ok
  - Error On credit / training transaction ok
  - Error on bookmark that not trigger the error actions
*/

export const Transactions = [
  'generate_video_preview',
  'generate_video',
  'generate_inference',
  'upload_mix_image'
] as const

export const IGNORED_REQUEST_ERROR = [400, 404]

export const UNHANDLED_ERROR_CODES = [400, 404, 413, 412]

export type TransactionTypeList = keyof typeof Transactions

export type TransactionsType = typeof Transactions[TransactionTypeList]

type UserData = {
  user_address: string
}

type Extra = {
  [key: string]: unknown
}

const hasDSN = Boolean(process.env.REACT_APP_SENTRY_DSN)

const SentryUtils = {
  init: () => {
    const buildDate = preval`module.exports = new Date().toLocaleString();`
    const buildDateFormatted = dayjs(buildDate).format(values.DATE_FORMAT)
    const initParam = {
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `playform2.0@${buildDateFormatted}`,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0
    }
    hasDSN && Sentry.init(initParam)
    hasDSN &&
      Sentry.setContext('version', {
        buildDate
      })
  },
  startTransaction: () => {},
  finishTransaction: () => {},
  captureMessage: (message: string, extra: Extra, level?: Severity) => {
    hasDSN && Sentry.captureMessage(message, { extra, level })
  },
  setUser: (data: UserData) => {
    hasDSN && Sentry.setUser(data)
  },
  onLogout: () => {
    hasDSN && Sentry.configureScope(scope => scope.setUser(null))
  }
}

export default SentryUtils
