import React, { useCallback } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import { useBreakpoint } from 'utils/Hooks'
import { RootState, RootActionType } from 'duck'
import { dialogActions, dialogSelectors, DialogNames } from 'duck/DialogDuck'
import Typography from 'components/Typography'
import { apiSelectors } from 'duck/ApiDuck'
import { TextUtils } from 'utils/TextUtils'
import DotIcon from 'components/Icon/DotIcon'
import { appActions } from 'duck/AppDuck'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './Dialogs.module.scss'

const CLASSES_DIALOG = {
  paper: styles.DialogPaper
}

const mapStateToProps = (state: RootState) => ({
  currentUserAddress: apiSelectors.currentUserAddress(state),
  activeDialog: dialogSelectors.activeDialog(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      disconnect: appActions.metamask.disconnect
    },
    dispatch
  )

type DialogMetamaskConnectionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const DialogMetamaskConnection: React.FC<DialogMetamaskConnectionProps> = props => {
  const { closeDialog, disconnect, activeDialog, currentUserAddress } = props
  const open = activeDialog === DialogNames.CONNECTION_STATUS

  const { isSmallscreenAbove } = useBreakpoint()

  const handleCloseDialog = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleCloseDialog}
      onBackdropClick={handleCloseDialog}
      classes={isSmallscreenAbove ? CLASSES_DIALOG : undefined}
      fullScreen={!isSmallscreenAbove}
      fullWidth
    >
      <div className={clsx(stylesGlobal.MarginTop4, styles.DialogContentWrapper)}>
        <div className={clsx(stylesGlobal.TextCenter)}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            className={stylesGlobal.MarginBottom16}
          >
            Connected To
          </Typography>

          <Typography variant="subtitle1">
            {currentUserAddress ? <DotIcon color="green" /> : '\u00A0'}
            {TextUtils.trimAddress(currentUserAddress)}
          </Typography>
        </div>

        <div className={clsx(stylesGlobal.MarginTop40, stylesGlobal.TextCenter)}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              closeDialog()
              disconnect()
            }}
          >
            Disconnect Wallet
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogMetamaskConnection)
