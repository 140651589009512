import React, { useCallback } from 'react'
import clsx from 'clsx'
import Dialog from 'components/Dialog'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './DialogMintMini.module.scss'
import { RootState, RootActionType } from 'duck'
import { dialogSelectors, DialogNames, dialogActions } from 'duck/DialogDuck'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Typography from 'components/Typography'

const CLASSES_DIALOG = {
  paper: styles.DialogPaper
}

const mapStateToProps = (state: RootState) => ({
  activeDialog: dialogSelectors.activeDialog(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog
    },
    dispatch
  )

type DialogMintSuccessProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const DialogMintSuccess: React.FC<DialogMintSuccessProps> = props => {
  const { closeDialog, activeDialog } = props
  const open = activeDialog === DialogNames.MINT_DIALOG_PROGRESS

  const handleCloseDialog = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleCloseDialog}
      onBackdropClick={handleCloseDialog}
      classes={CLASSES_DIALOG}
      fullWidth
    >
      <div
        className={clsx(
          stylesGlobal.MarginTop4,
          stylesGlobal.PaddingX60,
          stylesGlobal.MarginBottom36
        )}
      >
        <div className={stylesGlobal.TextCenter}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            className={stylesGlobal.MarginBottom16}
          >
            Confirming...
          </Typography>
          <Typography variant="body1" className={stylesGlobal.MarginBottom16}>
            Please wait while the token is being sent to the blockchain.
          </Typography>
          <Typography variant="body1" className={stylesGlobal.MarginBottom16}>
            This process may take a few minutes to complete.
          </Typography>
        </div>
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogMintSuccess)
