import React from 'react'
import TextFieldMaterial, {
  TextFieldProps as TextFieldMaterialProps
} from '@mui/material/TextField'
import Typography from 'components/Typography'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './TextField.module.scss'

export type TextFieldProps = TextFieldMaterialProps & { errorText?: string }

const TextField: React.FC<TextFieldProps> = props => {
  const { className, label, variant, errorText, error, ...restProps } = props
  return (
    <div>
      {label ? (
        <Typography
          component="div"
          variant="body2"
          fontWeight="bold"
          className={styles.TextFieldLabel}
        >
          {label}
        </Typography>
      ) : null}
      <div className={className}>
        <TextFieldMaterial {...restProps} error={Boolean(errorText || error)} />
        {errorText ? (
          <Typography
            component="div"
            variant="body2"
            color="error"
            fontWeight="bold"
            className={stylesGlobal.PaddingTop8}
          >
            {errorText}
          </Typography>
        ) : null}
      </div>
    </div>
  )
}

export default TextField
