import React, { ElementType, forwardRef } from 'react'
import clsx from 'clsx'
import ButtonMaterial, { ButtonProps as ButtonMaterialProps } from '@mui/material/Button'
import IconButtonMaterial, {
  IconButtonProps as IconButtonMaterialProps
} from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'
import stylesGlobal from 'stylesGlobal.module.scss'

type ButtonIconProps = IconButtonMaterialProps & {
  component?: ElementType<any>
  to?: string
}

type ButtonTextProps = ButtonMaterialProps &
  Pick<ButtonProps, 'align' | 'component' | 'target' | 'to'> & {}

export type ButtonProps = ButtonMaterialProps & {
  align?: 'left'
  icon?: boolean
  component?: ElementType<any>
  target?: '_blank'
  to?: string
  tooltip?: string
}

const ButtonIcon: React.FC<ButtonIconProps> = React.forwardRef((props, ref) => {
  const { children, className, color, disabled, onClick, ...restProps } = props
  return (
    <IconButtonMaterial
      ref={ref}
      {...restProps}
      children={children}
      className={className}
      color={color}
      disabled={disabled}
      onClick={onClick}
    />
  )
})

const ButtonLink: React.FC<
  ButtonTextProps & {
    setRef?: any
  }
> = props => {
  /*
    Utilize useNavigate instead of component={Link} due to conflict with onClick
    at the time of created this.

    TODO FARIZ
  */

  const { onClick, href, target, to, setRef, ...restProps } = props
  const navigate = useNavigate()

  if (href) {
    return (
      <ButtonMaterial
        ref={setRef}
        {...restProps}
        // href={href}
        // target={target}
        onClick={event => {
          onClick?.(event)
          window?.open(href, target, 'noopener')
        }}
      />
    )
  }

  return (
    <ButtonMaterial
      ref={setRef}
      {...restProps}
      onClick={event => {
        onClick?.(event)
        if (to) navigate(to)
      }}
    />
  )
}

const ButtonText: React.FC<ButtonTextProps> = React.forwardRef((props, ref) => {
  const {
    align,
    children,
    className,
    color,
    component,
    disabled,
    endIcon,
    href,
    onClick,
    startIcon,
    to,
    variant,
    fullWidth,
    target,
    ...restProps
  } = props

  if (to || href)
    return (
      <ButtonLink
        setRef={ref}
        children={children}
        className={clsx(align === 'left' && stylesGlobal.JustifyContentFlexStart, className)}
        color={color}
        disabled={disabled}
        endIcon={endIcon}
        fullWidth={fullWidth}
        onClick={onClick}
        startIcon={startIcon}
        href={href}
        to={to}
        target={target}
        variant={variant}
      />
    )

  const additionalProps: {
    component?: ButtonProps['component']
  } = {}
  if (component) additionalProps.component = component

  return (
    <ButtonMaterial
      ref={ref}
      {...restProps}
      children={children}
      className={clsx(align === 'left' && stylesGlobal.JustifyContentFlexStart, className)}
      color={color}
      disabled={disabled}
      endIcon={endIcon}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      startIcon={startIcon}
      variant={variant}
      {...additionalProps}
    />
  )
})

const Button = (props: ButtonProps, ref: any) => {
  const { icon, tooltip, ...restProps } = props
  const ButtonComponent = icon ? (
    <ButtonIcon {...(restProps as ButtonIconProps)} ref={ref} />
  ) : (
    <ButtonText {...(restProps as ButtonTextProps)} ref={ref} />
  )

  if (tooltip) return <Tooltip title={tooltip}>{ButtonComponent}</Tooltip>

  return ButtonComponent
}

export default forwardRef(Button)
