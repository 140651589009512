/**
 * Asynchronously loads the component for Contact page
 */

import React from 'react'
import loadable from 'utils/Loadable'
import { LoadingPage } from 'components/Loading'

export default loadable(() => import('./index'), {
  fallback: <LoadingPage />
})
