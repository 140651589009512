import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import Button from 'components/Button'
import TextField from 'components/TextField'
import { RootState, RootActionType } from 'duck'
import stylesGlobal from 'stylesGlobal.module.scss'
import Validator, { VALIDATION_MESSAGE } from 'utils/Validator'
import { useDebounce } from 'utils/Hooks'
import { appActions, appSelectors } from 'duck/AppDuck'
import { apiSelectors } from 'duck/ApiDuck'
import { Typography } from '@mui/material'
import styles from './Components.module.scss'

const emailInputProps = {
  className: stylesGlobal.TextCenter
}

const mapStateToProps = (state: RootState) => ({
  emailMailchimpSubmitState: appSelectors.emailMailchimpSubmitState(state),
  emailNotifySubmitState: appSelectors.emailNotifySubmitState(state),
  submitLoading: apiSelectors.loading['mineProject.createNotify'](state),
  createMailChimpLoading: apiSelectors.loading['mineProject.createMailChimp'](state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      submitNotify: appActions.emailNotification.submitNotify,
      submitMailChimp: appActions.emailNotification.submitMailChimp
    },
    dispatch
  )

export type SubmitEmailNotificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    className?: string
    mode: 'home' | 'mint-dialog'
    spacing?: number
  }

const SubmitEmailNotification: React.FC<SubmitEmailNotificationProps> = props => {
  const {
    submitNotify,
    submitMailChimp,
    emailMailchimpSubmitState,
    emailNotifySubmitState,
    submitLoading,
    createMailChimpLoading,
    className,
    mode,
    spacing
  } = props
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')
  const emailDebounced = useDebounce(email, error ? 100 : 1000)
  const validate = useCallback((email: string) => {
    const valid = Validator.email(email)
    setError(!valid ? VALIDATION_MESSAGE['email'] : '')

    return valid
  }, [])

  useEffect(() => {
    if (emailMailchimpSubmitState === 'submitted' || emailNotifySubmitState === 'submitted') {
      setEmail('')
    }
  }, [emailMailchimpSubmitState, emailNotifySubmitState])

  useEffect(() => {
    validate(emailDebounced)
  }, [emailDebounced, validate])

  const shouldShowSubmitHome = mode === 'home' && emailMailchimpSubmitState === 'submitted'
  const shouldShowSubmitMintDialog =
    mode === 'mint-dialog' && emailNotifySubmitState === 'submitted'

  return (
    <Grid
      spacing={spacing}
      direction={mode === 'home' ? 'row' : 'column'}
      className={clsx(stylesGlobal.MarginTop8, className)}
      container
    >
      {shouldShowSubmitHome || shouldShowSubmitMintDialog ? (
        <Grid xs={12} item>
          <Typography variant={'body1'} className={stylesGlobal.PaddingLeft8}>
            <b>Thanks for submitting! You’ll now recieve updates on future drops.</b>
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid xs item>
            <TextField
              size="small"
              placeholder="Enter Email"
              fullWidth
              disabled={Boolean(submitLoading || createMailChimpLoading)}
              error={Boolean(error)}
              value={email}
              onChange={e => setEmail(e.target.value)}
              inputProps={emailInputProps}
            />
          </Grid>

          <Grid xs="auto" item>
            <Button
              disabled={Boolean(error || submitLoading || createMailChimpLoading || !email)}
              fullWidth={mode === 'mint-dialog'}
              variant="contained"
              color="secondary"
              onClick={() => {
                const valid = validate(email)
                if (valid) {
                  if (mode === 'mint-dialog') {
                    submitNotify(email)
                  }
                  if (mode === 'home') {
                    submitMailChimp(email)
                  }
                }
              }}
            >
              Submit
            </Button>
          </Grid>

          {error ? (
            <Typography className={styles.ErrorText} color="error">
              {error}
            </Typography>
          ) : null}
        </>
      )}
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitEmailNotification)
