import { ActionType, createAction } from 'typesafe-actions'
import {
  PaginationResponse,
  ListOutputsRequest,
  MineImage,
  MineImageCreateReq,
  MineProject,
  InputImageSet,
  ImageListReq,
  ImageType,
  ArtMineConfig,
  Feedback,
  FeedbackCreateReq,
  NotifyCreateReq,
  Notify,
  MailChimpCreateReq,
  ListMineProjectReq,
  BannerImage,
  MineProjectUpdateReq,
  PrepareMintData
} from 'models/ApiModels'
import { AxiosResponse } from 'axios'
import { CryptoStateType } from 'utils/CryptoUtils/Models'

export const mineProject = {
  listMineProjectUserCollectedOutputs: createAction(
    '@@api/LIST_MINE_PROJECT_USER_COLLECTED_OUTPUTS'
  )<{
    param: Pick<ListOutputsRequest, 'user_address' | 'limit' | 'offset'>
    next?: boolean
    silentUpdate?: boolean
  }>(),
  listMineProjectUserCollectedOutputsResponse: createAction(
    '@@api/LIST_MINE_PROJECT_USER_COLLECTED_OUTPUTS_RESPONSE'
  )<{
    data: PaginationResponse<MineImage>
    req: Pick<ListOutputsRequest, 'user_address' | 'limit' | 'offset'>
    next?: boolean
    silentUpdate?: boolean
  }>(),
  listMineProjectCollectedOutputs: createAction('@@api/LIST_MINE_PROJECT_COLLECTED_OUTPUTS')<{
    param: Pick<ListOutputsRequest, 'project' | 'mine_status' | 'limit' | 'offset'>
    next?: boolean
    silentUpdate?: boolean
  }>(),

  listMineProjectCollectedOutputsResponse: createAction(
    '@@api/LIST_MINE_PROJECT_COLLECTED_OUTPUTS_RESPONSE'
  )<{
    data: PaginationResponse<MineImage>
    req: Pick<ListOutputsRequest, 'project' | 'mine_status' | 'limit' | 'offset'>
    next?: boolean
    silentUpdate?: boolean
  }>(),
  listMineProjectSampleOutputs: createAction('@@api/LIST_MINE_PROJECT_SAMPLE_OUTPUTS')<{
    param: Pick<ListOutputsRequest, 'project'>
  }>(),
  listMineProjectSampleOutputsResponse: createAction(
    '@@api/LIST_MINE_PROJECT_SAMPLE_OUTPUTS_RESPONSE'
  )<{
    data: PaginationResponse<MineImage>
    req: Pick<ListOutputsRequest, 'project'>
  }>(),
  retrieveMineImage: createAction('@@api/RETRIEVE_MINE_IMAGE')<number>(),
  retrieveMineImageResponse: createAction('@@api/RETRIEVE_MINE_IMAGE_RESPONSE')<MineImage>(),
  createMineImage: createAction('@@api/CREATE_MINE_IMAGE')<MineImageCreateReq>(),
  createMineImageResponse: createAction('@@api/CREATE_MINE_IMAGE_RESPONSE')<MineImage>(),

  startMineImage: createAction('@@api/START_MINE_IMAGE')<number>(),
  startMineImageResponse: createAction('@@api/START_MINE_IMAGE_RESPONSE')<number>(),

  listMineProjects: createAction('@@api/LIST_MINE_PROJECTS')<{
    req: Omit<ListMineProjectReq, 'offset'>
    silentUpdate?: boolean
  }>(),
  listMineProjectsResponse: createAction('@@api/LIST_MINE_PROJECTS_RESPONSE')<{
    data: PaginationResponse<MineProject>
    req: Omit<ListMineProjectReq, 'offset'>
    silentUpdate?: boolean
  }>(),
  listMineProjectsHome: createAction('@@api/LIST_MINE_PROJECTS_HOME')<{
    req: Pick<ListMineProjectReq, 'sort'>
  }>(),
  listMineProjectsHomeResponse: createAction('@@api/LIST_MINE_PROJECTS_HOME_RESPONSE')<{
    data: PaginationResponse<MineProject>
  }>(),
  listFeaturedMineProjects: createAction('@@api/LIST_FEATURED_MINE_PROJECTS')(),
  listFeaturedMineProjectsResponse: createAction('@@api/LIST_FEATURED_MINE_PROJECTS_RESPONSE')<{
    data: PaginationResponse<MineProject>
  }>(),
  listStudioMineProjects: createAction('@@api/LIST_STUDIO_MINE_PROJECTS')(),
  listStudioMineProjectsResponse: createAction('@@api/LIST_STUDIO_MINE_PROJECTS_RESPONSE')<{
    data: PaginationResponse<MineProject>
  }>(),
  listTopMineProjects: createAction('@@api/LIST_TOP_FEATURED_MINE_PROJECTS')(),
  listTopMineProjectsResponse: createAction('@@api/LIST_TOP_FEATURED_MINE_PROJECTS_RESPONSE')<{
    data: PaginationResponse<MineProject>
  }>(),
  retrieveProject: createAction('@@api/RETRIEVE_PROJECT')<number>(),
  retrieveProjectResponse: createAction('@@api/RETRIEVE_PROJECT_RESPONSE')<MineProject>(),
  updateProject: createAction('@@api/UPDATE_PROJECT')<MineProjectUpdateReq>(),
  updateProjectResponse: createAction('@@api/UPDATE_PROJECT_RESPONSE')<MineProject>(),
  retrieveInput: createAction('@@api/RETRIEVE_INPUT')<number>(),
  retrieveInputResponse: createAction('@@api/RETRIEVE_INPUT_RESPONSE')<InputImageSet>(),

  listImage: createAction('@@api/collection/LIST_IMAGE')<{ param: ImageListReq; next?: boolean }>(),
  listImageResponse: createAction('@@api/collection/LIST_IMAGE_RESPONSE')<{
    data: PaginationResponse<ImageType>
    req: ImageListReq
    next?: boolean
  }>(),
  setAccount: createAction('@@page/App/SET_ACCOUNT')<CryptoStateType['accounts']>(),
  setOpenedProjectId: createAction('@@api/SET_OPENED_ID')<number | undefined>(),
  retrieveCollectionImages: createAction('@@api/RETRIEVE_COLLECTION_IMAGES')<
    Pick<ImageListReq, 'inputs' | 'collection'>
  >(),
  retrieveConfig: createAction('@@api/RETRIEVE_CONFIG')(),
  retrieveConfigResponse: createAction('@@api/RETRIEVE_CONFIG_RESPONSE')<ArtMineConfig>(),

  createFeedback: createAction('@@api/CREATE_FEEDBACK')<FeedbackCreateReq>(),
  createFeedbackResponse: createAction('@@api/CREATE_FEEDBACK_RESPONSE')<Feedback>(),
  createNotify: createAction('@@api/CREATE_NOTIFY')<NotifyCreateReq>(),
  createNotifyResponse: createAction('@@api/CREATE_NOTIFY_RESPONSE')<Notify>(),

  createMailChimp: createAction('@@api/CREATE_MAILCHIMP')<MailChimpCreateReq>(),
  createMailChimpResponse: createAction('@@api/CREATE_MAILCHIMP_RESPONSE')<MailChimpCreateReq>(),

  prepareMintImage: createAction('@@api/PREPARE_MINT_IMAGE')<{ projectId: number }>(),
  prepareMintImageResponse: createAction('@@api/PREPARE_MINT_IMAGE_RESPONSE')<{
    projectId: number
    data: PrepareMintData
  }>(),

  listBannerImages: createAction('@@api/LIST_BANNER_IMAGES')(),
  listBannerImagesResponse: createAction('@@api/LIST_BANNER_IMAGES_RESPONSE')<{
    data: PaginationResponse<BannerImage>
  }>()
}

export type MineProjectActionType = ActionType<typeof mineProject>

export type RequestType = MineProjectActionType['type']

export type ErrorBundleType = {
  error?: AxiosResponse<any>
  req?: any
  type: RequestType
}

export const sharedActions = {
  reset: createAction('@@shared/RESET')(),
  setError: createAction('@@shared/SET_ERROR')<ErrorBundleType>(),
  cancelRequest: createAction('@@shared/CANCEL_REQUEST')<RequestType>(),
  resetError: createAction('@@shared/RESET_ERROR')<RequestType | RequestType[]>(),
  resetAllErrors: createAction('@@shared/RESET_ALL_ERRORS')(),
  setLoading: createAction('@@shared/SET_LOADING')<{
    loading: boolean | string
    type: RequestType
  }>(),
  setImageListFetchState: createAction('@@shared/SET_IMAGE_LIST_FETCH_STATE')<{
    key: string
    value: boolean
  }>()
}

export type SharedActionsType = ActionType<typeof sharedActions>

export const apiActions = {
  mineProject
}
export type ApiActionsType = ActionType<typeof apiActions> | SharedActionsType
