import React from 'react'
import clsx from 'clsx'
import _toNumber from 'lodash/toNumber'
import format from 'numerable/format'
import ArrowUpward from 'components/Icon/ArrowUpward'
import ArrowDownward from 'components/Icon/ArrowDownward'
import PolygonIcon from 'components/Icon/PolygonIcon'
import { MineProject } from 'models/ApiModels'
import { values } from 'appConstants'
import Tooltip from 'components/Tooltip'
import stylesGlobal from 'stylesGlobal.module.scss'

type PriceTextProps = {
  className?: string
  disableWordBreak?: boolean
  network: MineProject['network']
  price: string
  priceDirection?: 'up' | 'down'
  unitMode?: 'text' | 'symbol'
}

const PriceText: React.FC<PriceTextProps> = props => {
  const { className, disableWordBreak, network, price, priceDirection, unitMode = 'text' } = props

  const priceNumber = _toNumber(price)

  if (network === 'ETHEREUM') {
    return (
      <span className={clsx(stylesGlobal.DisplayInlineFlex, className)}>
        <span className={stylesGlobal.MarginRight4}>{unitMode === 'text' ? 'ETH' : 'Ξ'}</span>

        <span className={!disableWordBreak ? stylesGlobal.BreakWords : undefined}>
          {format(priceNumber * values.WEI_TO_ETH, '0,0.0##################')}
        </span>

        {priceDirection ? (
          <span className={clsx(stylesGlobal.MarginTop4, stylesGlobal.MarginLeft4)}>
            {priceDirection === 'up' ? <ArrowUpward /> : null}
            {priceDirection === 'down' ? <ArrowDownward /> : null}
          </span>
        ) : null}
      </span>
    )
  }

  if (network === 'POLYGON') {
    const priceFormatted = format(priceNumber * values.WEI_TO_ETH, '0,0.##################')
    const logo =
      unitMode === 'text' ? (
        'MATIC'
      ) : (
        <Tooltip title={'MATIC'}>
          <PolygonIcon fill="#8247E5" className={stylesGlobal.FontSize20} />
        </Tooltip>
      )
    return (
      <span className={clsx(stylesGlobal.DisplayInlineFlex, className)}>
        <span className={stylesGlobal.MarginRight4}>{logo}</span>{' '}
        <span
          className={!disableWordBreak ? stylesGlobal.BreakWords : undefined}
        >{`${priceFormatted}`}</span>
        {priceDirection ? (
          <span className={clsx(stylesGlobal.MarginTop4, stylesGlobal.MarginLeft4)}>
            {priceDirection === 'up' ? <ArrowUpward /> : null}
            {priceDirection === 'down' ? <ArrowDownward /> : null}
          </span>
        ) : null}
      </span>
    )
  }

  return null
}

export default PriceText
