import React, { forwardRef, Ref } from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const PolygonIcon = (props: IconProps & { fill?: string }, ref: Ref<any>) => {
  const { className, fill = '#696969', ...restProps } = props
  return (
    <SvgIcon
      className={className}
      ref={ref}
      {...restProps}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M16.2864 9.33927C15.9817 9.15134 15.5901 9.15134 15.242 9.33927L12.8051 10.8897L11.1515 11.8764L8.75807 13.4268C8.45346 13.6147 8.06181 13.6147 7.71368 13.4268L5.84249 12.2052C5.53788 12.0173 5.3203 11.6414 5.3203 11.2186V8.86944C5.3203 8.49357 5.49436 8.1177 5.84249 7.88279L7.71368 6.70821C8.0183 6.52028 8.40994 6.52028 8.75807 6.70821L10.6293 7.92977C10.9339 8.1177 11.1515 8.49357 11.1515 8.91642V10.4669L12.8051 9.43323V7.83581C12.8051 7.45994 12.631 7.08408 12.2829 6.84916L8.80159 4.64095C8.49697 4.45302 8.10533 4.45302 7.7572 4.64095L4.18888 6.89614C3.84075 7.08407 3.66669 7.45994 3.66669 7.83581V12.2522C3.66669 12.6281 3.84075 13.004 4.18888 13.2389L7.71368 15.4471C8.0183 15.635 8.40994 15.635 8.75807 15.4471L11.1515 13.9436L12.8051 12.91L15.1985 11.4065C15.5031 11.2186 15.8947 11.2186 16.2428 11.4065L18.114 12.5811C18.4186 12.769 18.6362 13.1449 18.6362 13.5678V15.9169C18.6362 16.2928 18.4622 16.6686 18.114 16.9036L16.2864 18.0781C15.9817 18.2661 15.5901 18.2661 15.242 18.0781L13.3708 16.9036C13.0662 16.7156 12.8486 16.3398 12.8486 15.9169V14.4135L11.195 15.4471V16.9975C11.195 17.3734 11.369 17.7493 11.7172 17.9842L15.242 20.1924C15.5466 20.3803 15.9382 20.3803 16.2864 20.1924L19.8112 17.9842C20.1158 17.7962 20.3334 17.4204 20.3334 16.9975V12.5341C20.3334 12.1583 20.1593 11.7824 19.8112 11.5475L16.2864 9.33927Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

export default forwardRef(PolygonIcon)
