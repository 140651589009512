import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import { withLatestFrom, map, filter, mergeMap, startWith, take, delay, tap } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { apiActions, sharedActions } from 'duck/ApiDuck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { constCreatorMaker } from 'utils/TextUtils'
import { FeedbackCreateReq } from 'models/ApiModels'
import Validator, { isHasErrors, VALIDATION_MESSAGE } from 'utils/Validator'
import { dialogActions, DialogNames } from 'duck/DialogDuck'
import { errorUtils } from 'utils/DataProcessingUtils'
import { of, merge } from 'rxjs'
import { values } from 'appConstants'
import MixPanelUtils from 'utils/MixPanelUtils'

// Constants
const NAMESPACE = '@@page/ContactPage'
const creator = constCreatorMaker(NAMESPACE)

export const actions = {
  setFormData: createAction(creator('SET_FORM_DATA'))<ContactPageState['formData']>(),
  setFormErrors: createAction(creator('SET_FORM_ERRORS'))<ContactPageState['formErrors']>(),
  submit: createAction(creator('SUBMIT'))(),
  setSubmitState: createAction(creator('SET_SUBMIT_STATE'))<ContactPageState['submitState']>()
}

const selectContactPage = (state: RootState) => state.container.contactPage

const selectFormData = createSelector(selectContactPage, contactPage => contactPage.formData)
const selectFormErrors = createSelector(selectContactPage, contactPage => contactPage.formErrors)

export const selectors = {
  contactPage: selectContactPage,
  formData: selectFormData,
  submitState: createSelector(selectContactPage, contactPage => contactPage.submitState),
  hasFormErrors: createSelector(selectContactPage, contactPage =>
    isHasErrors(contactPage.formErrors)
  ),
  formErrors: selectFormErrors
}

const INITIAL_FORM_DATA: FeedbackCreateReq = {
  content: '',
  email: '',
  first_name: '',
  last_name: ''
}

export type ContactPageState = {
  submitState: 'submitted' | undefined
  formData: Partial<FeedbackCreateReq>
  formErrors: Record<keyof FeedbackCreateReq, string | undefined>
}

const INITIAL: ContactPageState = {
  submitState: undefined,
  formData: INITIAL_FORM_DATA,
  formErrors: INITIAL_FORM_DATA
}

const reducer = produce((state: ContactPageState, { type, payload }) => {
  switch (type) {
    case getType(actions.setFormData): {
      const data = payload as ActionType<typeof actions.setFormData>['payload']

      state.formData = {
        ...state.formData,
        ...data
      }

      if (data.content) {
        state.formErrors.content = ''
      }
      if (data.first_name) {
        state.formErrors.first_name = ''
      }

      if (data.email) {
        const valid = Validator.email(data.email)

        state.formErrors.email = valid ? '' : VALIDATION_MESSAGE['email']
      }

      return
    }
    case getType(actions.setFormErrors): {
      const data = payload as ActionType<typeof actions.setFormErrors>['payload']

      state.formErrors = {
        ...state.formErrors,
        ...data
      }

      return
    }
    case getType(actions.setSubmitState): {
      const data = payload as ActionType<typeof actions.setSubmitState>['payload']

      state.submitState = data
      return
    }
    default:
  }
}, INITIAL)

// Epics

const submitEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.submit)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const formData = selectors.formData(state)
      return { formData }
    }),
    map(({ formData }) => {
      const errors: ContactPageState['formErrors'] = {
        first_name: !formData.first_name ? VALIDATION_MESSAGE['required'] : '',
        email: !formData.email
          ? VALIDATION_MESSAGE['required']
          : !Validator.email(formData.email)
          ? VALIDATION_MESSAGE['email']
          : '',
        last_name: '',
        content: !formData.content ? VALIDATION_MESSAGE['required'] : ''
      }
      const isHasError = isHasErrors(errors)

      return { errors, isHasError, formData }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ isHasError }) => isHasError),
          map(({ errors }) => actions.setFormErrors(errors))
        ),
        of(param).pipe(
          filter(({ isHasError }) => !isHasError),
          mergeMap(({ formData }) =>
            action$.pipe(
              filter(isActionOf(apiActions.mineProject.createFeedbackResponse)),
              take(1),
              tap(() => {
                MixPanelUtils.track<'SEND_FEEDBACK'>('Send Feedback', {
                  content: formData.content ?? '',
                  email: formData.email ?? '',
                  first_name: formData.first_name ?? '',
                  last_name: formData.last_name ?? ''
                })
              }),
              mergeMap(() => [
                actions.setSubmitState('submitted'),
                actions.setFormData(INITIAL_FORM_DATA)
              ]),
              startWith(
                apiActions.mineProject.createFeedback({
                  content: formData.content ?? '',
                  email: formData.email ?? '',
                  first_name: formData.first_name ?? '',
                  last_name: formData.last_name ?? ''
                })
              )
            )
          )
        )
      )
    )
  )

const listenOnCreateFeedbackErrorEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    map(action => ({
      error: action.payload,
      errorCode: errorUtils.getCode(action.payload) ?? 0
    })),
    mergeMap(payload =>
      merge(
        of(payload).pipe(
          filter(({ error }) => error.type === getType(apiActions.mineProject.createFeedback)),
          map(({ errorCode, error }) => {
            const message =
              errorCode === 429
                ? 'Please wait for a minute to send the feedback again.'
                : errorUtils.flattenMessage(error)

            return { message }
          }),
          mergeMap(({ message }) => [
            dialogActions.addDialog({
              [DialogNames.ERROR]: {
                content: message,
                dialogName: DialogNames.ERROR,
                title: 'Unable To Send The Feedback'
              }
            })
          ])
        )
      )
    )
  )

const setSubmitStateEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.setSubmitState)),
    filter(({ payload }) => payload === 'submitted'),
    delay(values.SUBMIT_FEEDBACK_DELAY),
    map(() => actions.setSubmitState(undefined))
  )

export const epics = combineEpics(submitEpic, setSubmitStateEpic, listenOnCreateFeedbackErrorEpic)
export default reducer
