import { ActionType, createReducer, isActionOf, createAction, getType } from 'typesafe-actions'
import { Epic, combineEpics } from 'redux-observable'
import {
  catchError,
  filter,
  mergeMap,
  startWith,
  take,
  tap,
  withLatestFrom,
  map,
  delay
} from 'rxjs/operators'
import _toNumber from 'lodash/toNumber'
import { from, merge, of } from 'rxjs'
import { createSelector } from 'reselect'
import { RootActionType, RootState } from 'duck'
import { apiActions, apiSelectors, sharedActions } from './ApiDuck'
import CryptoUtils, { cryptoState } from 'utils/CryptoUtils'
import { LSKey, values } from 'appConstants'
import { dialogActions, DialogNames } from './DialogDuck'
import { SessionStorage } from 'utils'
import { MineImage } from 'models/ApiModels'
import MixPanelUtils, { ConnectLocationType } from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import { AppEvents, eventEmiterActions } from './EventEmitterDuck'
import { errorUtils } from 'utils/DataProcessingUtils'
import dayjs from 'dayjs'
import { Severity } from '@sentry/react'

// Actions
export const appActions = {
  setPushTo: createAction('@@page/App/SET_PUSH_TO')<string | undefined>(),
  metamask: {
    init: createAction('@@page/App/METAMASK/INIT')(),
    connect: createAction('@@page/App/METAMASK/CONNECT')<{
      connectLocation: ConnectLocationType
    }>(),
    mintCollectorCurated: createAction('@@page/App/METAMASK/MINT_COLLECTOR_CURATED')<
      Pick<MineImage, 'id'>
    >(),
    mintArtistCurated: createAction('@@page/App/METAMASK/MINT_ARTIST_CURATED')<{
      projectId: number
    }>(),
    setMintLoading: createAction('@@page/App/METAMASK/SET_MINT_LOADING')<boolean | string>(),
    disconnect: createAction('@@page/App/METAMASK/DISCONNECT')(),
    setConnectLoading: createAction('@@page/App/METAMASK/SET_CONNECT_LOADING')<boolean | string>()
  },
  emailNotification: {
    submitNotify: createAction('@@page/App/EMAIL_NOTIFICATION/SUBMIT_NOTIFY')<string>(),
    submitMailChimp: createAction('@@page/App/EMAIL_NOTIFICATION/SUBMIT_MAILCHIMP')<string>(),
    setEmailNotifySubmitState: createAction(
      '@@page/App/EMAIL_NOTIFICATION/SET_EMAIL_NOTIFY_SUBMIT_STATE'
    )<AppState['emailNotifySubmitState']>(),
    setEmailMailchimpSubmitState: createAction(
      '@@page/App/EMAIL_NOTIFICATION/SET_EMAIL_MAILCHIMP_SUBMIT_STATE'
    )<AppState['emailMailchimpSubmitState']>()
  }
}

export type AppActionType = ActionType<typeof appActions>

// Selectors
const selectApp = (state: RootState) => state.app

export const appSelectors = {
  app: selectApp,
  pushTo: createSelector(selectApp, param => param.pushTo),
  connectLoading: createSelector(selectApp, param => param.connectLoading),
  mintLoading: createSelector(selectApp, param => param.mintLoading),
  emailNotifySubmitState: createSelector(selectApp, param => param.emailNotifySubmitState),
  emailMailchimpSubmitState: createSelector(selectApp, param => param.emailMailchimpSubmitState)
}

// Reducer
export type AppState = {
  pushTo?: string
  connectLoading?: boolean | string
  mintLoading?: boolean | string
  emailNotifySubmitState?: 'submitted'
  emailMailchimpSubmitState?: 'submitted'
}

const INITIAL_APP_STATE: AppState = {
  pushTo: undefined,
  connectLoading: undefined,
  mintLoading: undefined,
  emailNotifySubmitState: undefined,
  emailMailchimpSubmitState: undefined
}

const reducer = createReducer<AppState, AppActionType>(INITIAL_APP_STATE)
  .handleAction(appActions.setPushTo, (state, action) => ({
    ...state,
    pushTo: action.payload
  }))
  .handleAction(appActions.metamask.setConnectLoading, (state, action) => ({
    ...state,
    connectLoading: action.payload
  }))
  .handleAction(appActions.metamask.setMintLoading, (state, action) => ({
    ...state,
    mintLoading: action.payload
  }))
  .handleAction(appActions.emailNotification.setEmailNotifySubmitState, (state, action) => ({
    ...state,
    emailNotifySubmitState: action.payload
  }))
  .handleAction(appActions.emailNotification.setEmailMailchimpSubmitState, (state, action) => ({
    ...state,
    emailMailchimpSubmitState: action.payload
  }))

const connectToMetaMaskEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.metamask.connect)),
    map(param => ({
      hasMetamask: CryptoUtils.hasMetamask(),
      connectLocation: param.payload.connectLocation
    })),
    mergeMap(({ hasMetamask, connectLocation }) =>
      merge(
        of(hasMetamask).pipe(
          filter(hasMetamask => !hasMetamask),
          map(() =>
            dialogActions.addDialog({
              [DialogNames.ERROR]: {
                dialogName: DialogNames.ERROR,
                content: CryptoUtils.errorMessage(),
                title: 'No wallet detected'
              }
            })
          )
        ),
        of(hasMetamask).pipe(
          filter(hasMetamask => hasMetamask),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.mineProject.retrieveConfigResponse)),
              take(1),
              withLatestFrom(state$),
              map(([_, state]) => apiSelectors.mineConfig(state)),
              mergeMap(mineConfig =>
                merge(
                  of(appActions.metamask.setConnectLoading(true)),
                  from(
                    CryptoUtils.init({
                      contract_abi: mineConfig?.contract_abi ?? {},
                      contract_address: mineConfig?.contract_address ?? '',
                      chain_id: mineConfig?.chain_id ?? 0,
                      polygon_chain_id: mineConfig?.polygon_chain_id ?? 0,
                      polygon_contract_abi: mineConfig?.polygon_contract_abi ?? {},
                      polygon_contract_address: mineConfig?.polygon_contract_address ?? ''
                    })
                  ).pipe(
                    tap(() =>
                      SessionStorage.save(
                        LSKey.IS_CONNECTED_TO_METAMASK,
                        LSKey.IS_CONNECTED_TO_METAMASK
                      )
                    ),
                    map(param => ({
                      address: param.accounts?.[0] ?? '',
                      chain_id: param.chainId,
                      network: param.network,
                      accounts: param.accounts
                    })),
                    tap(({ address, chain_id, network }) => {
                      if (address) {
                        SentryUtils.setUser({
                          user_address: address
                        })
                        MixPanelUtils.identify(address)
                      }
                      MixPanelUtils.track<'CONNECT_TO_METAMASK'>('Connect To Metamask', {
                        address,
                        chain_id,
                        network,
                        connect_location: connectLocation
                      })
                      MixPanelUtils.setPeople({
                        address,
                        last_time_connect: dayjs().toISOString()
                      })
                    }),
                    mergeMap(({ accounts }) => [
                      apiActions.mineProject.setAccount(accounts),
                      appActions.metamask.setConnectLoading(false)
                    ])
                  )
                )
              ),
              catchError(err =>
                of(err).pipe(
                  tap(err => {
                    SentryUtils.captureMessage(
                      `Error connecting to metamask`,
                      { errorCode: err?.code, message: err?.message },
                      Severity.Error
                    )
                  }),
                  mergeMap((err: Error) => [
                    appActions.metamask.setConnectLoading(false),
                    dialogActions.addDialog({
                      [DialogNames.ERROR]: {
                        dialogName: DialogNames.ERROR,
                        content: err.message,
                        title: 'Failed To Connect'
                      }
                    })
                  ])
                )
              ),
              startWith(apiActions.mineProject.retrieveConfig())
            )
          )
        )
      )
    )
  )

const disconnectFromMetamaskEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.metamask.disconnect)),
    tap(() => SessionStorage.remove(LSKey.IS_CONNECTED_TO_METAMASK)),
    tap(() => CryptoUtils.disconnect()),
    tap(() => {
      MixPanelUtils.track<'DISCONNECT_FROM_METAMASK'>('Disconnect From Metamask')
    }),
    map(() => apiActions.mineProject.setAccount(undefined))
  )

const initEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.metamask.init)),
    map(() => SessionStorage.get(LSKey.IS_CONNECTED_TO_METAMASK)),
    filter(connectValue => connectValue === LSKey.IS_CONNECTED_TO_METAMASK),
    map(() => appActions.metamask.connect({ connectLocation: 'On Page Open' }))
  )

const mintLoadingEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([appActions.metamask.mintCollectorCurated, appActions.metamask.mintArtistCurated])
    ),
    withLatestFrom(state$),
    map(() => appActions.metamask.setMintLoading(true))
  )

const mintCollectorCuratedEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.metamask.mintCollectorCurated)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      mineImageId: action.payload.id,
      payload: apiSelectors.mineImageData(state)[action.payload.id],
      mineProjectsData: apiSelectors.mineProjectsData(state)
    })),
    mergeMap(({ mineImageId, mineProjectsData }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mineProject.retrieveMineImageResponse)),
        take(1),
        map(({ payload }) => ({
          param: {
            code: payload.code,
            price: BigInt(`${payload.mine_price}`),
            expire_at: payload.expire_at,
            ipfs_uri: payload.ipfs_uri,
            signature: payload.signature
          },
          seed: payload.seed,
          mine_price: payload.mine_price,
          mine_image_id: payload.id,
          projectId: payload.project,
          projectName: mineProjectsData[payload.project]?.title ?? ''
        })),
        mergeMap(param =>
          from(CryptoUtils.getUserBalance()).pipe(
            mergeMap(balance =>
              merge(
                of(balance).pipe(
                  filter(balance => balance <= _toNumber(param.mine_price)),
                  mergeMap(() => [
                    dialogActions.openDialog({
                      [DialogNames.ERROR]: {
                        dialogName: DialogNames.ERROR,
                        title: 'Unable To Mint The Image',
                        content: `You don't have enough balance to mint this image.`
                      }
                    }),
                    appActions.metamask.setMintLoading(false)
                  ])
                ),
                of(balance).pipe(
                  filter(balance => balance > _toNumber(param.mine_price)),
                  mergeMap(() =>
                    merge(
                      of(param).pipe(
                        delay(1000),
                        map(() =>
                          dialogActions.addDialog({
                            [DialogNames.MINT_DIALOG_PROGRESS]: {
                              dialogName: DialogNames.MINT_DIALOG_PROGRESS
                            }
                          })
                        )
                      ),
                      of(param).pipe(
                        mergeMap(
                          ({ param, mine_price, seed, projectId, projectName, mine_image_id }) =>
                            from(CryptoUtils.mintTo(param)).pipe(
                              mergeMap(() =>
                                action$.pipe(
                                  filter(isActionOf(apiActions.mineProject.startMineImageResponse)),
                                  take(1),
                                  tap(() => {
                                    MixPanelUtils.track<'MINE_IMAGE'>('Mine Image', {
                                      project_id: projectId,
                                      project_name: projectName,
                                      seed,
                                      project_type: 'collector',
                                      network: cryptoState.network,
                                      chain_id: cryptoState.chainId,
                                      price: _toNumber(mine_price) * values.WEI_TO_ETH
                                    })
                                  }),
                                  mergeMap(() => [
                                    eventEmiterActions.emit({
                                      [AppEvents.MINT_SUCCESS]: {
                                        event: AppEvents.MINT_SUCCESS,
                                        projectId
                                      }
                                    }),
                                    appActions.metamask.setMintLoading(false),
                                    apiActions.mineProject.retrieveMineImage(mine_image_id),
                                    dialogActions.openDialog({
                                      [DialogNames.MINT_DIALOG_SUCCESS]: {
                                        dialogName: 'mint_dialog_success',
                                        imageId: mine_image_id
                                      }
                                    })
                                  ]),
                                  startWith(apiActions.mineProject.startMineImage(mine_image_id))
                                )
                              ),
                              catchError(err =>
                                of(err).pipe(
                                  tap(err => {
                                    SentryUtils.captureMessage(
                                      `Error On Mint Image`,
                                      { errorCode: err.code, message: err.message },
                                      Severity.Error
                                    )
                                  }),
                                  mergeMap((err: { message: string; code: number }) => [
                                    appActions.metamask.setMintLoading(false),
                                    dialogActions.openDialog({
                                      [DialogNames.MINT_DIALOG_COLLECTOR_CURATED]: {
                                        dialogName: DialogNames.MINT_DIALOG_COLLECTOR_CURATED,
                                        mineImageId: mine_image_id,
                                        errorMessage: err.message,
                                        isUserCanceled: err.code === 4001
                                      }
                                    })
                                  ])
                                )
                              )
                            )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        ),
        startWith(apiActions.mineProject.retrieveMineImage(mineImageId))
      )
    )
  )

const mintArtistCuratedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.metamask.mintArtistCurated)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectId: action.payload.projectId,
      mineProjectsData: apiSelectors.mineProjectsData(state)
    })),
    mergeMap(({ mineProjectsData, projectId }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mineProject.prepareMintImageResponse)),
        take(1),
        map(({ payload }) => ({
          data: payload.data
        })),
        map(({ data }) => ({
          param: {
            code: data.code,
            ipfs_uri: data.ipfs_uri,
            price: BigInt(data.mine_price),
            expire_at: data.expire_at,
            signature: data.signature
          },
          imageId: data.id,
          mine_price: data.mine_price,
          projectId: projectId,
          projectName: mineProjectsData[projectId]?.title ?? ''
        })),
        mergeMap(param =>
          from(CryptoUtils.getUserBalance()).pipe(
            mergeMap(balance =>
              merge(
                of(balance).pipe(
                  filter(balance => balance <= _toNumber(param.mine_price)),
                  mergeMap(() => [
                    dialogActions.openDialog({
                      [DialogNames.ERROR]: {
                        dialogName: DialogNames.ERROR,
                        title: 'Unable To Mint The Image',
                        content: `You don't have enough balance to mint this image.`
                      }
                    }),
                    appActions.metamask.setMintLoading(false)
                  ])
                ),
                of(balance).pipe(
                  filter(balance => balance > _toNumber(param.mine_price)),
                  mergeMap(() =>
                    merge(
                      of(param).pipe(
                        delay(1000),
                        map(() =>
                          dialogActions.addDialog({
                            [DialogNames.MINT_DIALOG_PROGRESS]: {
                              dialogName: DialogNames.MINT_DIALOG_PROGRESS
                            }
                          })
                        )
                      ),
                      of(param).pipe(
                        mergeMap(({ param, mine_price, imageId, projectId, projectName }) =>
                          from(CryptoUtils.mintTo(param)).pipe(
                            tap(() => {
                              MixPanelUtils.track<'MINE_IMAGE'>('Mine Image', {
                                project_id: projectId,
                                project_name: projectName,
                                project_type: 'artist',
                                network: cryptoState.network,
                                chain_id: cryptoState.chainId,
                                price: _toNumber(mine_price) * values.WEI_TO_ETH
                              })
                            }),
                            mergeMap(() => [
                              eventEmiterActions.emit({
                                [AppEvents.MINT_SUCCESS]: {
                                  event: AppEvents.MINT_SUCCESS,
                                  projectId
                                }
                              }),
                              appActions.metamask.setMintLoading(false),
                              dialogActions.openDialog({
                                [DialogNames.MINT_DIALOG_SUCCESS]: {
                                  dialogName: 'mint_dialog_success',
                                  imageId
                                }
                              })
                            ]),
                            catchError(err =>
                              of(err).pipe(
                                tap(err => {
                                  SentryUtils.captureMessage(
                                    `Error On Mint Image`,
                                    { errorCode: err.code, message: err.message },
                                    Severity.Error
                                  )
                                }),
                                mergeMap((err: { message: string; code: number }) => [
                                  appActions.metamask.setMintLoading(false),
                                  dialogActions.openDialog({
                                    [DialogNames.MINT_DIALOG_ARTIST_CURATED]: {
                                      dialogName: DialogNames.MINT_DIALOG_ARTIST_CURATED,
                                      projectId,
                                      errorMessage: err.message,
                                      isUserCanceled: err.code === 4001
                                    }
                                  })
                                ])
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        ),
        startWith(apiActions.mineProject.prepareMintImage({ projectId }))
      )
    )
  )

const submitEmailNotifyEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.emailNotification.submitNotify)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const openedMineProject = apiSelectors.openedMineProject(state)
      return { email: action.payload, project: openedMineProject?.id ?? 0 }
    }),
    mergeMap(({ email, project }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mineProject.createNotifyResponse)),
        take(1),
        tap(() => {
          MixPanelUtils.track<'SEND_EMAIL'>('Send Email', {
            email: email ?? '',
            project
          })
        }),
        mergeMap(() => [appActions.emailNotification.setEmailNotifySubmitState('submitted')]),
        startWith(
          apiActions.mineProject.createNotify({
            email,
            project
          })
        )
      )
    )
  )

const submitMailChimpEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.emailNotification.submitMailChimp)),
    map(action => {
      return { email: action.payload, audience_id: values.AUDIENCE_ID }
    }),
    mergeMap(({ email = '', audience_id = '' }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mineProject.createMailChimpResponse)),
        take(1),
        tap(() => {
          MixPanelUtils.track<'SEND_EMAIL_MAILCHIMP'>('Send Email Mailchimp', {
            email,
            audience_id
          })
        }),
        mergeMap(() => [appActions.emailNotification.setEmailMailchimpSubmitState('submitted')]),
        startWith(
          apiActions.mineProject.createMailChimp({
            email,
            audience_id
          })
        )
      )
    )
  )

const listenOnCreateEmailErrorEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    map(action => ({
      error: action.payload,
      errorCode: errorUtils.getCode(action.payload) ?? 0
    })),
    mergeMap(payload =>
      merge(
        of(payload).pipe(
          filter(
            ({ error }) =>
              error.type === getType(apiActions.mineProject.createMailChimp) ||
              error.type === getType(apiActions.mineProject.createNotify)
          ),
          map(({ errorCode, error }) => {
            const message =
              errorCode === 429
                ? 'Please wait for a minute to send the email again.'
                : errorUtils.flattenMessage(error)

            return { message }
          }),
          mergeMap(({ message }) => [
            dialogActions.addDialog({
              [DialogNames.ERROR]: {
                content: message,
                dialogName: DialogNames.ERROR,
                title: 'Unable To Send The Email'
              }
            })
          ])
        )
      )
    )
  )

const setEmailMailchimpSubmitStateEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.emailNotification.setEmailMailchimpSubmitState)),
    filter(({ payload }) => payload === 'submitted'),
    delay(values.SUBMIT_FEEDBACK_DELAY),
    map(() => appActions.emailNotification.setEmailMailchimpSubmitState(undefined))
  )

const setEmailNotifySubmitStateEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.emailNotification.setEmailNotifySubmitState)),
    filter(({ payload }) => payload === 'submitted'),
    delay(values.SUBMIT_FEEDBACK_DELAY),
    map(() => appActions.emailNotification.setEmailNotifySubmitState(undefined))
  )

export const epics = combineEpics(
  submitMailChimpEpic,
  submitEmailNotifyEpic,
  listenOnCreateEmailErrorEpic,
  setEmailMailchimpSubmitStateEpic,
  setEmailNotifySubmitStateEpic,
  mintCollectorCuratedEpic,
  mintArtistCuratedEpic,
  mintLoadingEpic,
  connectToMetaMaskEpic,
  initEpic,
  disconnectFromMetamaskEpic
)

export default reducer
