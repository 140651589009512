import { ListMineProjectReq, ListMineProjectReqType } from 'models/ApiModels'
import { constructChainParam } from 'utils/TextUtils'
import _isNil from 'lodash/isNil'
// Primary Home Route
export const MINE_PROJECTS = '/projects'
export const COLLECTIONS = '/collections'

export const SUB_ROUTE_PARAM = 'subRoute'
export const ID_PARAM = 'id'

export const MINE_PROJECT_SUB_ROUTE_LIST = ['summary', 'mine', 'collected'] as const
export type MineProjectSubRouteType = typeof MINE_PROJECT_SUB_ROUTE_LIST[number]

export type MineProjectResultParamType = {
  id?: number
  subRoute?: MineProjectSubRouteType
}

export type CollectionsParamType = {
  id?: number
}

export const route = {
  ROOT: {
    paths: [`/`],
    getUrl: () => route.ROOT.paths[0]
  },
  ABOUT: {
    paths: [`/about`],
    getUrl: () => route.ABOUT.paths[0]
  },
  CONTACT: {
    paths: [`/contact`],
    getUrl: () => route.CONTACT.paths[0]
  },
  MINE_PROJECTS: {
    paths: [`${MINE_PROJECTS}`, `:${ID_PARAM}`, `:${SUB_ROUTE_PARAM}`],
    getUrl: (param?: MineProjectResultParamType, searchParam?: ListMineProjectReq) => {
      const id = param?.id
      const subRoute = param?.subRoute
      const urlParams = new URLSearchParams()
      Object.keys(searchParam ?? {}).forEach(key => {
        const value = searchParam?.[key as ListMineProjectReqType]
        !_isNil(value) && urlParams.append(key, `${value}`)
      })

      return constructChainParam([MINE_PROJECTS, id, subRoute], `?${urlParams.toString()}`)
    }
  },
  COLLECTIONS: {
    paths: [`${COLLECTIONS}`, `:${ID_PARAM}`],
    getUrl: (param?: CollectionsParamType) => {
      const id = param?.id
      return constructChainParam([COLLECTIONS, id])
    }
  },
  UI_SHOWCASE: {
    paths: [`/uishowcase`],
    getUrl: () => route.UI_SHOWCASE.paths[0]
  }
}
