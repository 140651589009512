import React from 'react'

export const BreakpointContext = React.createContext({
  breakpoint: 'xs',
  isSmallscreenAbove: false,
  isMediumscreenAbove: false,
  isLargescreenAbove: false,
  isSmallscreenBelow: false,
  isMediumscreenBelow: false,
  isLargescreenBelow: false
})

export const WindowDimensionContext = React.createContext({
  height: 0,
  width: 0
})
