import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'
import styles from './Icon.module.scss'

const ArrowUpwardIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon
      className={clsx(styles.IconStroke, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M12.01 4.51001V19.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.02301 10.514L12 4.49799L17.977 10.514"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default ArrowUpwardIcon
