import { useEffect, useMemo } from 'react'
import { useBreakpoint, useWidth, useWindowDimensionDetector } from 'utils/Hooks'
import {
  BREAKPOINTS_SMALLSCREEN_UP,
  BREAKPOINTS_MEDIUMSCREEN_UP,
  BREAKPOINTS_LARGESCREEN_UP
} from 'appConstants'
import Platform from 'utils/Platform'
import ReactGA from 'react-ga4'
import { Location } from 'history'
import stylesGlobal from 'stylesGlobal.module.scss'
import MixPanelUtils from 'utils/MixPanelUtils'

/* Set User Device Information */
export const useUserDeviceDetector = () => {
  const { breakpoint } = useBreakpoint()

  const { isStandalone } = Platform

  useEffect(() => {
    if (isStandalone) document.body.classList.add(stylesGlobal.UserSelectNone)
  }, [isStandalone])

  useEffect(() => {
    if (breakpoint) {
      const classNameResponsive = `Body${breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1)}`

      document.body.classList.add(classNameResponsive)

      return () => {
        document.body.classList.remove(classNameResponsive)
      }
    }
  }, [breakpoint])
}
/* useUserDeviceDetector END */

/* useWindowDimensionInit */
export const useWindowDimensionInit = () => {
  const windowDimension = useWindowDimensionDetector()

  return windowDimension
}
/* useWindowDimensionInit END */

type UsePageViewTracker = {
  location: Location | undefined | null
}

export const usePageViewTracker = (param: UsePageViewTracker) => {
  const { location } = param

  const pathname = location?.pathname ?? ''
  const search = location?.search ?? ''
  const pathnameWithSearch = `${pathname}/${search}`

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_ID || '')
  }, [])

  useEffect(() => {
    ReactGA.send('pageview')
    MixPanelUtils.track<'PAGE_VIEW'>('Page View', {
      route: pathnameWithSearch
    })
  }, [pathnameWithSearch])
}

export const useBreakpointInit = () => {
  const breakpoint = useWidth() || ''

  const breakpointData = useMemo(() => {
    const isSmallscreenAbove = BREAKPOINTS_SMALLSCREEN_UP.includes(breakpoint)
    const isMediumscreenAbove = BREAKPOINTS_MEDIUMSCREEN_UP.includes(breakpoint)
    const isLargescreenAbove = BREAKPOINTS_LARGESCREEN_UP.includes(breakpoint)

    const isSmallscreenBelow = !isSmallscreenAbove
    const isMediumscreenBelow = !isMediumscreenAbove
    const isLargescreenBelow = !isLargescreenAbove

    return {
      breakpoint,
      isSmallscreenAbove,
      isMediumscreenAbove,
      isLargescreenAbove,
      isSmallscreenBelow,
      isMediumscreenBelow,
      isLargescreenBelow
    }
  }, [breakpoint])

  return breakpointData
}
