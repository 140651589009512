import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import { withLatestFrom, map, filter } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ListMineProjectReq } from 'models/ApiModels'
import { apiActions } from 'duck/ApiDuck'
import { isActionOf, getType, createAction, ActionType } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { constCreatorMaker } from 'utils/TextUtils'
import { values } from 'appConstants'

// Constants
const NAMESPACE = '@@page/ProjectsPage'
const creator = constCreatorMaker(NAMESPACE)

export const DEFAULT_SORT: ListMineProjectReq['sort'] = 'date'

// Actions
export const actions = {
  updateListParam: createAction(creator('UPDATE_LIST_PARAM'))<
    Partial<ProjectsPageState['projectList']['listParams']>
  >()
}

// Selectors
const selectHomePage = (state: RootState) => state.container.projectsPage

const selectProjectListParams = createSelector(
  selectHomePage,
  homeScreen => homeScreen.projectList.listParams
)

export const selectors = {
  projectListParams: selectProjectListParams
}

export type ProjectsPageState = {
  projectList: {
    loaded: boolean
    listParams: Omit<ListMineProjectReq, 'offset'>
  }
}

export const INITIAL: ProjectsPageState = {
  projectList: {
    loaded: false,
    listParams: { ...values.BASE_PROJECT_LIST_PARAM, sort: DEFAULT_SORT }
  }
}

const reducer = produce((state: ProjectsPageState, { type, payload }) => {
  switch (type) {
    case getType(actions.updateListParam): {
      const param = payload as ActionType<typeof actions.updateListParam>['payload']

      state.projectList.listParams = {
        ...state.projectList.listParams,
        ...param
      }
      return
    }
    default:
  }
}, INITIAL)

// Epics
const updateListParamEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.updateListParam)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.projectListParams(state)
      return { listParams }
    }),
    map(({ listParams }) => apiActions.mineProject.listMineProjects({ req: listParams }))
  )

export const epics = combineEpics(updateListParamEpic)
export default reducer
