import Web3 from 'web3'
import { CryptoStateType, MintParam } from './Models'
import { Severity } from '@sentry/react'
import SentryUtils from 'utils/SentryUtils'
import Platform from 'utils/Platform'
import { ArtMineConfig, MineProject } from 'models/ApiModels'

const INITIAL_STATE: CryptoStateType = {
  accounts: undefined,
  web3: undefined,
  contract: undefined
}

export const cryptoState: CryptoStateType = {
  ...INITIAL_STATE
}

const CryptoUtils = {
  errorMessage: () => {
    if (
      (Platform.isDesktop &&
        (Platform.isChrome ||
          Platform.isChromium ||
          Platform.isFirefox ||
          Platform.isEdgeChromium ||
          Platform.isOpera)) ||
      Platform.isMobile
    )
      return 'Download the MetaMask extension or app to get started'

    return 'The MetaMask extension is only available on Chrome and Firefox'
  },
  hasMetamask: () => {
    return Boolean(window.ethereum)
  },
  init: async (
    param: Pick<
      ArtMineConfig,
      | 'contract_abi'
      | 'contract_address'
      | 'chain_id'
      | 'polygon_chain_id'
      | 'polygon_contract_address'
      | 'polygon_contract_abi'
    >
  ): Promise<CryptoStateType> => {
    const {
      contract_abi,
      contract_address,
      chain_id,
      polygon_chain_id,
      polygon_contract_address,
      polygon_contract_abi
    } = param
    if (window.ethereum) {
      try {
        cryptoState.accounts = (await window.ethereum?.request?.({
          method: 'eth_requestAccounts'
        })) as CryptoStateType['accounts']

        const chainIdHex = (await window.ethereum?.request?.({
          method: 'eth_chainId'
        })) as CryptoStateType['chainId']

        cryptoState.chainId = parseInt(`${chainIdHex ?? ''}`, 16)

        const contractData:
          | {
              network: MineProject['network']
              abi: ArtMineConfig['contract_abi']
              contractAddress: ArtMineConfig['contract_address']
            }
          | undefined =
          chain_id === cryptoState.chainId
            ? {
                network: 'ETHEREUM',
                abi: contract_abi,
                contractAddress: contract_address
              }
            : polygon_chain_id === cryptoState.chainId
            ? {
                network: 'POLYGON',
                abi: polygon_contract_abi,
                contractAddress: polygon_contract_address
              }
            : undefined

        if (contractData) {
          cryptoState.web3 = new Web3(window.ethereum)

          cryptoState.contract = new cryptoState.web3.eth.Contract(
            contractData.abi,
            contractData.contractAddress
          )

          cryptoState.network = contractData.network
        } else {
          throw new Error(
            "Your current chain network is not supported, make sure you're using Ethereum or Polygon Mainnet network."
          )
        }

        window.ethereum?.on?.('chainChanged', () => {
          window.location.reload()
        })

        window.ethereum?.on?.('accountsChanged', () => {
          window.location.reload()
        })

        return cryptoState
      } catch (error: any) {
        SentryUtils.captureMessage(
          `Error On Initialize Metamask Connection`,
          { errorCode: error.code, message: error.message },
          Severity.Error
        )
        if (error.code === 4001) {
          throw new Error('Connection Canceled')
        } else if (error.code === -32002) {
          throw new Error('Please accept the pending connection request.')
        } else {
          throw new Error(error.message)
        }
      }
    } else {
      throw new Error(
        'No blockchain provider installed in your browser. You can try to install Metamask browser extension first to continue.'
      )
    }
  },
  disconnect: async () => {
    cryptoState.chainId = INITIAL_STATE.chainId
    cryptoState.accounts = INITIAL_STATE.accounts
    cryptoState.web3 = INITIAL_STATE.web3
    cryptoState.contract = INITIAL_STATE.contract
  },
  getUserBalance: async (): Promise<number> => {
    const account = cryptoState?.accounts?.[0]
    return account
      ? await cryptoState.web3.eth.getBalance(cryptoState?.accounts?.[0])
      : new Promise<number>(() => 0)
  },
  mintTo: async (param: MintParam) => {
    const { code, ipfs_uri, price, expire_at, signature } = param
    const userAddress = cryptoState.accounts?.[0]
    return await cryptoState.contract?.methods
      .mintTo(code, ipfs_uri, expire_at, signature)
      .send({ from: userAddress, value: price.toString() })
  }
}

export default CryptoUtils

/*
approve: ƒ ()
approve(address,uint256): ƒ ()
balanceOf: ƒ ()
balanceOf(address): ƒ ()
getApproved: ƒ ()
getApproved(uint256): ƒ ()
getEthSignedMessageHash: ƒ ()
getEthSignedMessageHash(bytes32): ƒ ()
getMessageHash: ƒ ()
getMessageHash(uint256,uint256,string): ƒ ()
isApprovedForAll: ƒ ()
isApprovedForAll(address,address): ƒ ()
name: ƒ ()
name(): ƒ ()
owner: ƒ ()
owner(): ƒ ()
ownerOf: ƒ ()
ownerOf(uint256): ƒ ()
price: ƒ ()
price(): ƒ ()
purchaseToken: ƒ ()
purchaseToken(uint256,uint256,string,bytes): ƒ ()
recoverSigner: ƒ ()
recoverSigner(bytes32,bytes): ƒ ()
renounceOwnership: ƒ ()
renounceOwnership(): ƒ ()
safeTransferFrom: ƒ ()
safeTransferFrom(address,address,uint256): ƒ ()
safeTransferFrom(address,address,uint256,bytes): ƒ ()
setApprovalForAll: ƒ ()
setApprovalForAll(address,bool): ƒ ()
splitSignature: ƒ ()
splitSignature(bytes): ƒ ()
supportsInterface: ƒ ()
supportsInterface(bytes4): ƒ ()
symbol: ƒ ()
symbol(): ƒ ()
tokenURI: ƒ ()
tokenURI(uint256): ƒ ()
transferFrom: ƒ ()
transferFrom(address,address,uint256): ƒ ()
transferOwnership: ƒ ()
transferOwnership(address): ƒ ()
verify: ƒ ()
verify(address,bytes,bytes32): ƒ ()

*/
