import { RootState } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import { createSelector } from 'reselect'
import { DialogNames } from './Models'

// Selectors
const selectDialog = (state: RootState) => state.dialog

const selectActiveDialog = createSelector(selectDialog, dialog => {
  const { show } = dialog
  return show[0]
})

const selectActiveDialogOverlay = createSelector(selectDialog, dialog => {
  const { showOverlay, show } = dialog
  return showOverlay || show[0]
})

const selectDialogData = createSelector(selectDialog, dialog => {
  return dialog.data
})

const selectHasActiveDialog = createSelector(selectActiveDialog, activeDialog =>
  Boolean(activeDialog)
)

const selectHasMultipleDialog = createSelector(selectDialog, dialog => {
  const { show } = dialog

  return (show?.length ?? 0) > 1
})

const dialogDatum = {
  [DialogNames.INFORMATION]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.INFORMATION]
  ),
  [DialogNames.ERROR]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.ERROR]
  ),
  [DialogNames.CONNECTION_STATUS]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.CONNECTION_STATUS]
  ),
  [DialogNames.MINT_DIALOG_ARTIST_CURATED]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.MINT_DIALOG_ARTIST_CURATED]
  ),
  [DialogNames.MINT_DIALOG_COLLECTOR_CURATED]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.MINT_DIALOG_COLLECTOR_CURATED]
  ),
  [DialogNames.CONNECT_TO_METAMASK]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.CONNECT_TO_METAMASK]
  ),
  [DialogNames.MINT_DIALOG_SUCCESS]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.MINT_DIALOG_SUCCESS]
  ),
  [DialogNames.CONNECT_TO_WRONG_NETWORK]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.CONNECT_TO_WRONG_NETWORK]
  ),
  [DialogNames.PROJECT_CONFIRMATION]: createSelector(
    selectDialogData,
    dialogData => dialogData[DialogNames.PROJECT_CONFIRMATION]
  ),
  [DialogNames.MINT_DIALOG_COLLECTOR_CURATED]: createSelector(
    selectDialogData,
    apiSelectors.mineImageData,
    apiSelectors.mineProjectsData,
    (dialogData, mineImageData, mineProjectsData) => {
      const mineImage =
        mineImageData[dialogData[DialogNames.MINT_DIALOG_COLLECTOR_CURATED]?.mineImageId ?? 0] ??
        undefined
      const mineProject = mineProjectsData[mineImage?.project ?? 0]

      return {
        ...dialogData[DialogNames.MINT_DIALOG_COLLECTOR_CURATED],
        mineImage: mineImage
          ? {
              ...mineImage,
              network: mineProject?.network,
              project_name: mineProject?.title,
              project_by: mineProject?.user_full_name
            }
          : undefined
      }
    }
  ),
  [DialogNames.MINT_DIALOG_ARTIST_CURATED]: createSelector(
    selectDialogData,
    apiSelectors.mineProjectsData,
    apiSelectors.prepareMineData,
    (dialogData, mineProjectsData, prepareMineData) => {
      const mineProject =
        mineProjectsData[dialogData[DialogNames.MINT_DIALOG_ARTIST_CURATED]?.projectId ?? 0] ??
        undefined
      const prepareMineDatum =
        prepareMineData[dialogData[DialogNames.MINT_DIALOG_ARTIST_CURATED]?.projectId ?? 0] ??
        undefined

      return {
        ...dialogData[DialogNames.MINT_DIALOG_ARTIST_CURATED],
        projectData: mineProject,
        prepareMintData: prepareMineDatum
      }
    }
  )
}

export const dialogSelectors = {
  dialog: selectDialog,
  dialogData: selectDialogData,
  activeDialogOverlay: selectActiveDialogOverlay,
  activeDialog: selectActiveDialog,
  hasActiveDialog: selectHasActiveDialog,
  hasMultipleDialog: selectHasMultipleDialog,
  dialogDatum
}
