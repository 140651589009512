import React from 'react'
import clsx from 'clsx'
import Button, { ButtonProps } from 'components/Button'

import DotIcon from 'components/Icon/DotIcon'
import Platform from 'utils/Platform'
import CryptoUtils from 'utils/CryptoUtils'

import stylesGlobal from 'stylesGlobal.module.scss'

type ButtonConnectMetamaskProps = Pick<
  ButtonProps,
  'className' | 'disabled' | 'fullWidth' | 'onClick'
> & {
  loading?: boolean
  userAddressShort?: string
  hideUserAddress?: boolean
}

const ButtonConnectMetamask: React.FC<ButtonConnectMetamaskProps> = props => {
  const { className, disabled, fullWidth, hideUserAddress, loading, onClick, userAddressShort } =
    props

  if (userAddressShort) {
    return (
      <Button
        disabled={disabled}
        className={className}
        variant="outlined"
        color={hideUserAddress ? 'error' : undefined}
        onClick={onClick}
        fullWidth={fullWidth}
      >
        {hideUserAddress ? (
          'Disconnect Wallet'
        ) : (
          <span className={clsx(stylesGlobal.DisplayFlex, stylesGlobal.AlignItemsCenter)}>
            <DotIcon color="green" />

            <span>{userAddressShort}</span>
          </span>
        )}
      </Button>
    )
  }

  if (Platform.isIOS || Platform.isAndroid) {
    const hasMetamask = CryptoUtils.hasMetamask()

    return (
      <Button
        className={className}
        disabled={loading || disabled}
        variant="outlined"
        onClick={hasMetamask ? onClick : undefined}
        href={!hasMetamask ? `https://metamask.app.link/dapp/${window.location.host}/` : undefined}
        fullWidth={fullWidth}
      >
        {loading ? 'Connecting...' : 'Connect to Metamask'}
      </Button>
    )
  }

  return (
    <Button
      className={className}
      disabled={loading || disabled}
      variant="outlined"
      onClick={onClick}
      fullWidth={fullWidth}
    >
      {loading ? 'Connecting...' : 'Connect to Metamask'}
    </Button>
  )
}

export default ButtonConnectMetamask
