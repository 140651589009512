import { Store, applyMiddleware, createStore } from 'redux'
import { RootActionType } from 'duck'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createBrowserHistory } from 'history'
import { createEpicMiddleware } from 'redux-observable'
import { epics, RootState, createRootReducer } from 'duck'
import _get from 'lodash/get'

export const history = createBrowserHistory()
const composeEnhancers = composeWithDevTools({})

export function configureStore() {
  const epicMiddleWare = createEpicMiddleware<RootActionType, RootActionType, RootState>()
  const middlewares = [epicMiddleWare]

  const rootReducer = createRootReducer()

  const store = createStore(
    rootReducer,
    undefined,
    composeEnhancers(applyMiddleware(...middlewares))
  ) as Store<RootState>

  epicMiddleWare.run(epics)

  if (process.env.NODE_ENV !== 'production') {
    if (_get(module, 'hot')) {
      _get(module, 'hot').accept('./duck', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return { store }
}
