import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import useInterval from 'use-interval'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import CardCollection from 'components/Card/CardCollection'
import { useBreakpoint } from 'utils/Hooks'
import { RootState, RootActionType } from 'duck'
import { dialogSelectors, DialogNames, dialogActions } from 'duck/DialogDuck'
import Typography from 'components/Typography'
import { appActions, appSelectors } from 'duck/AppDuck'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import Tooltip from 'components/Tooltip'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './DialogMintMini.module.scss'
import { ErrorPanel } from './Components'

const RETRIEVE_MINE_IMAGE_INTERVAL = 4000
const CLASSES_DIALOG = {
  paper: styles.DialogPaper
}

const mapStateToProps = (state: RootState) => ({
  dialogDatum: dialogSelectors.dialogDatum[DialogNames.MINT_DIALOG_COLLECTOR_CURATED](state),
  activeDialog: dialogSelectors.activeDialog(state),
  mintLoading: appSelectors.mintLoading(state),
  retrieveMineImageLoading: apiSelectors.loading['mineProject.retrieveMineImage'](state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      mint: appActions.metamask.mintCollectorCurated,
      retrieveMineImage: apiActions.mineProject.retrieveMineImage
    },
    dispatch
  )

type DialogMintMiniProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const DialogMintMini: React.FC<DialogMintMiniProps> = props => {
  const {
    closeDialog,
    mint,
    retrieveMineImage,
    retrieveMineImageLoading,
    mintLoading,
    dialogDatum,
    activeDialog
  } = props

  const open = activeDialog === DialogNames.MINT_DIALOG_COLLECTOR_CURATED
  const mineImage = dialogDatum.mineImage
  const isUserCanceled = dialogDatum.isUserCanceled
  const errorMessageRaw = dialogDatum.errorMessage
  const [errorMessage, setErrorMessage] = useState(errorMessageRaw)
  const { isSmallscreenAbove } = useBreakpoint()

  useEffect(() => {
    setErrorMessage(errorMessageRaw)
  }, [errorMessageRaw])

  const shouldPooling = open && mineImage && !mineImage.signature

  useInterval(
    () => {
      retrieveMineImage(mineImage?.id ?? 0)
    },
    shouldPooling ? RETRIEVE_MINE_IMAGE_INTERVAL : false,
    true
  )

  const handleCloseDialog = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleCloseDialog}
      onBackdropClick={handleCloseDialog}
      classes={CLASSES_DIALOG}
      fullWidth
    >
      <div
        className={clsx(
          stylesGlobal.MarginTop4,
          stylesGlobal.MarginXAuto,
          stylesGlobal.PaddingBottom50,
          styles.DialogContentWrapper
        )}
      >
        {mineImage ? (
          <>
            <CardCollection
              showPrice
              network={mineImage.network}
              showProjectName
              mineImage={mineImage}
              classes={{
                previewWrapperOuter: clsx(stylesGlobal.MarginXAuto, styles.MaxWidth170),
                title: stylesGlobal.TextColorPrimary
              }}
            />

            <div
              className={clsx(
                stylesGlobal.DisplayFlexColumn,
                stylesGlobal.AlignItemsCenter,
                stylesGlobal.MarginTop16
              )}
            >
              <Button
                className={styles.MaxWidth170}
                variant="contained"
                color="secondary"
                disabled={Boolean(mintLoading || retrieveMineImageLoading || !mineImage.signature)}
                onClick={() => {
                  setErrorMessage('')
                  mint(mineImage)
                }}
                fullWidth
              >
                {!mineImage.signature
                  ? 'Preparing Image...'
                  : mintLoading
                  ? 'Mint In Progress...'
                  : 'Mint to Wallet'}
              </Button>
              <ErrorPanel errorMessage={errorMessage} isUserCanceled={isUserCanceled} />

              {!mineImage.signature ? (
                <Tooltip
                  className={clsx(
                    stylesGlobal.HeightFull,
                    stylesGlobal.PointerEventsAuto,
                    stylesGlobal.MarginTop16
                  )}
                  title={'The Image is not available for minting yet, please wait for a minute.'}
                  placement={isSmallscreenAbove ? 'right-end' : 'bottom'}
                >
                  <HelpOutlineIcon className={clsx(stylesGlobal.TextColorLight)} />
                </Tooltip>
              ) : null}
            </div>
          </>
        ) : (
          <Typography variant="body1" component="div" align="center">
            Data Not Available
          </Typography>
        )}
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogMintMini)
