import React, { useMemo, useEffect } from 'react'
import clsx from 'clsx'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import UIShowcase from 'containers/UIShowcase/Loadable'
import ProjectPage from 'containers/ProjectPage/Loadable'
import CollectionsPage from 'containers/CollectionsPage/Loadable'
import CollectionPage from 'containers/CollectionPage/Loadable'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import HomePage from 'containers/HomePage/Loadable'
import ProjectsPage from 'containers/ProjectsPage/Loadable'
// import Header from 'containers/AppPage/Header'
import Navbar from './Navbar'
import { route } from 'routes'
import { useBreakpointInit, useUserDeviceDetector } from './Hooks'
import { BreakpointContext } from 'context'
import artmineTheme from 'artmineTheme'
import { defaultPageTitle, defaultDescription, values } from 'appConstants'
import Platform from 'utils/Platform'
import { RootState, RootActionType } from 'duck'
import { appActions } from 'duck/AppDuck'
import ErrorDialog from './Dialogs/ErrorDialog'
import DialogMetamaskConnection from './Dialogs/DialogMetamaskConnection'
import DialogConnectToWrongNetwork from './Dialogs/DialogConnectToWrongNetwork'
import DialogMintCollectorCurated from './Dialogs/DialogMint/DialogMintCollectorCurated'
import DialogMintArtistCurated from './Dialogs/DialogMint/DialogMintArtistCurated'
import DialogConnectToMetamask from './Dialogs/DialogConnectToMetamask'
import AboutPage from 'containers/AboutPage/Loadable'
import ContactPage from 'containers/ContactPage/Loadable'
import DialogMintSuccess from './Dialogs/DialogMint/DialogMintSuccess'
import DialogMintProgress from './Dialogs/DialogMint/DialogMintProgress'
import SEOHelmet from './components/SEOHelmet'
import InformationDialog from './Dialogs/InformationDialog'
import LocationBasedAddon from './LocationBasedAddon'
import { apiSelectors } from 'duck/ApiDuck'

import stylesGlobal from 'stylesGlobal.module.scss'

type HelmetLinkType = {
  type: string
  rel: string
  href: string
}

const mapStateToProps = (state: RootState) => ({
  currentUserAddress: apiSelectors.currentUserAddress(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      init: appActions.metamask.init
    },
    dispatch
  )

type AppProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const App: React.FC<AppProps> = props => {
  const { currentUserAddress, init } = props

  useUserDeviceDetector()

  useEffect(() => {
    //
    init()

    // Remove first load placeholder component
    const firstloadStyles = document.getElementById('firstloadStyles')
    const firstloadComponent = document.getElementById('firstloadPlaceholder')

    window.setTimeout(() => {
      if (firstloadStyles) firstloadStyles?.parentNode?.removeChild(firstloadStyles)
      if (firstloadComponent) firstloadComponent?.parentNode?.removeChild(firstloadComponent)
    }, 100)
  }, [init])

  const { isDarkMode } = Platform
  const helmetLinkProps = useMemo(() => {
    if (!isDarkMode) return undefined

    const helmetLinkProps: Array<HelmetLinkType> = [
      {
        type: 'link',
        rel: 'shortcut icon',
        href: isDarkMode
          ? '/assets/images/favicon/favicon-white.png'
          : '/assets/images/favicon/favicon.png'
      },
      {
        type: 'link',
        rel: 'manifest',
        href: isDarkMode ? '/manifest-dark.json' : '/manifest.json'
      }
    ]

    return helmetLinkProps
  }, [isDarkMode])

  return (
    <div
      className={clsx(
        stylesGlobal.PositionRelative,
        stylesGlobal.DisplayFlexColumn,
        stylesGlobal.WidthFull,
        stylesGlobal.HeightFull,
        stylesGlobal.OverflowHidden
      )}
    >
      <SEOHelmet
        link={helmetLinkProps}
        description={defaultDescription}
        title={defaultPageTitle}
        image={values.OG_IMAGE_URL}
      />
      <LocationBasedAddon />
      <InformationDialog />
      <ErrorDialog />
      <DialogMetamaskConnection />
      <DialogConnectToMetamask />
      <DialogConnectToWrongNetwork />

      {currentUserAddress ? (
        <>
          <DialogMintCollectorCurated />
          <DialogMintArtistCurated />
          <DialogMintSuccess />
          <DialogMintProgress />
        </>
      ) : null}

      <Navbar />

      <Routes>
        <Route path={route.ROOT.paths[0]} element={<HomePage />} />
        <Route path={route.ABOUT.paths[0]} element={<AboutPage />} />
        <Route path={route.CONTACT.paths[0]} element={<ContactPage />} />
        <Route path={route.MINE_PROJECTS.paths[0]}>
          <Route index element={<ProjectsPage />} />
          <Route path={'featured'} element={<ProjectsPage />} />
          <Route path={'studio'} element={<ProjectsPage />} />
          <Route path={route.MINE_PROJECTS.paths[1]} element={<ProjectPage />}>
            <Route path={route.MINE_PROJECTS.paths[2]} element={<ProjectPage />} />
          </Route>
        </Route>
        <Route path={route.COLLECTIONS.paths[0]}>
          <Route index element={<CollectionsPage />} />
          <Route path={route.COLLECTIONS.paths[1]} element={<CollectionPage />} />
        </Route>
        <Route path={route.UI_SHOWCASE.paths[0]} element={<UIShowcase />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}
const AppWithConnect = connect(mapStateToProps, mapDispatchToProps)(App)

const AppWithContext = () => {
  const breakpointData = useBreakpointInit()

  return (
    <BreakpointContext.Provider value={breakpointData}>
      <AppWithConnect />
    </BreakpointContext.Provider>
  )
}

type AppWithErrorBoundaryProps = {}

const AppWithErrorBoundary: React.FC<AppWithErrorBoundaryProps> = props => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={artmineTheme}>
        <CssBaseline />
        <BrowserRouter>
          <AppWithContext />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default AppWithErrorBoundary
