import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

type DotIconProps = IconProps & {
  color: 'green' | 'yellow' | 'red'
}

const COLOR_MAP: Record<DotIconProps['color'], string> = {
  green: '#27AE60',
  yellow: '#F2C94C',
  red: '#FF5C5C'
}

const DotIcon: React.FC<DotIconProps> = ({ className, alt, color }) => {
  return (
    <SvgIcon
      style={{
        width: '8px',
        height: '8px',
        marginRight: '8px'
      }}
      className={clsx(className)}
      width="8"
      titleAccess={alt}
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={COLOR_MAP[color]} />
    </SvgIcon>
  )
}

export default DotIcon
