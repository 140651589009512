import React, { MouseEvent } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Dialog from 'components/Dialog'
import { useBreakpoint } from 'utils/Hooks'
import { RootState, RootActionType } from 'duck'
import { dialogActions, dialogSelectors, DialogNames } from 'duck/DialogDuck'
import Typography from 'components/Typography'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './Dialogs.module.scss'

const CLASSES_DIALOG = {
  paper: styles.DialogPaper
}

const mapStateToProps = (state: RootState) => ({
  dialogDatum: dialogSelectors.dialogDatum[DialogNames.ERROR](state),
  activeDialog: dialogSelectors.activeDialog(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog
    },
    dispatch
  )

type ErrorDialogProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ErrorDialogComponent: React.FC<ErrorDialogProps> = props => {
  const { closeDialog, activeDialog, dialogDatum } = props

  const open = activeDialog === DialogNames.ERROR

  const { isSmallscreenAbove } = useBreakpoint()

  const handleCloseDialog = (event: MouseEvent<any>) => {
    closeDialog()
    dialogDatum?.onClose?.()
  }

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleCloseDialog}
      onBackdropClick={handleCloseDialog}
      classes={isSmallscreenAbove ? CLASSES_DIALOG : undefined}
      fullScreen={!isSmallscreenAbove}
      fullWidth
    >
      <div
        className={clsx(
          stylesGlobal.MarginTop4,
          stylesGlobal.Padding16,
          stylesGlobal.MarginBottom36
        )}
      >
        <div className={clsx(stylesGlobal.TextCenter)}>
          {dialogDatum?.title ? (
            <Typography
              variant="h5"
              component="h2"
              fontWeight="bold"
              className={stylesGlobal.MarginBottom16}
            >
              {dialogDatum?.title}
            </Typography>
          ) : null}

          {dialogDatum?.content ? (
            <Typography variant="subtitle1">{dialogDatum?.content}</Typography>
          ) : null}
        </div>
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialogComponent)
