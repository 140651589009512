import React, { useCallback } from 'react'
import clsx from 'clsx'
import Button from 'components/Button'
import Dialog from 'components/Dialog'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './DialogMintMini.module.scss'
import { RootState, RootActionType } from 'duck'
import { dialogSelectors, DialogNames, dialogActions } from 'duck/DialogDuck'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Typography from 'components/Typography'
import { appActions } from 'duck/AppDuck'
import { route } from 'routes'
import SubmitEmailNotification from 'containers/AppPage/components/SubmitEmailNotification'

const CLASSES_DIALOG = {
  paper: styles.DialogPaper
}

const mapStateToProps = (state: RootState) => ({
  dialogDatum: dialogSelectors.dialogDatum[DialogNames.MINT_DIALOG_SUCCESS](state),
  activeDialog: dialogSelectors.activeDialog(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      setPushTo: appActions.setPushTo
    },
    dispatch
  )

type DialogMintSuccessProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const DialogMintSuccess: React.FC<DialogMintSuccessProps> = props => {
  const { closeDialog, setPushTo, dialogDatum, activeDialog } = props
  const open = activeDialog === DialogNames.MINT_DIALOG_SUCCESS

  const imageId = dialogDatum?.imageId

  const handleCloseDialog = useCallback(() => {
    closeDialog()
  }, [closeDialog])

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleCloseDialog}
      onBackdropClick={handleCloseDialog}
      classes={CLASSES_DIALOG}
      fullWidth
    >
      <div className={styles.MintSuccessContainer}>
        <div className={stylesGlobal.TextCenter}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            className={stylesGlobal.MarginBottom16}
          >
            Now Minting
          </Typography>

          <div className={clsx(stylesGlobal.MarginTop16, stylesGlobal.TextCenter)}>
            <Button
              className={clsx(stylesGlobal.MarginTop16, stylesGlobal.MarginBottom16)}
              variant="outlined"
              color="inherit"
              onClick={() => {
                closeDialog()
                if (imageId) {
                  setPushTo(route.COLLECTIONS.getUrl({ id: imageId ?? 0 }))
                } else {
                  setPushTo(route.COLLECTIONS.getUrl())
                }
              }}
            >
              Check Minting Status
            </Button>
          </div>

          <Typography variant="body1" className={stylesGlobal.MarginBottom16}>
            Your purchase is complete. Please provide your email address to receive updates on this
            project. Your information will only be shared with the project creator.
          </Typography>

          <SubmitEmailNotification mode="mint-dialog" spacing={1} />
        </div>
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogMintSuccess)
