import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import _compact from 'lodash/compact'
import { withLatestFrom, mergeMap, filter, map } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { isActionOf, getType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { constCreatorMaker } from 'utils/TextUtils'

// Constants
const NAMESPACE = '@@page/HomePage'
const creator = constCreatorMaker(NAMESPACE)

// Actions
export const actions = {
  initHomePage: createAction(creator('INIT_HOME_PAGE'))()
}

// Selectors
const selectHomePage = (state: RootState) => state.container.homePage

export const selectors = {
  initiated: createSelector(selectHomePage, homeScreen => homeScreen.initiated)
}

export type HomePageState = {
  initiated: boolean
}

const initial: HomePageState = {
  initiated: false
}

const reducer = produce((state: HomePageState, { type }) => {
  switch (type) {
    case getType(actions.initHomePage): {
      state.initiated = true
      return
    }
    default:
  }
}, initial)

// Epics
const initHomePageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.initHomePage)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      mineProjectFeaturedList: apiSelectors.mineProjectFeaturedList(state),
      mineProjectTopList: apiSelectors.mineProjectTopList(state),
      mineProjectStudioList: apiSelectors.mineProjectStudioList(state),
      bannerImages: apiSelectors.bannerImages(state)
    })),
    mergeMap(
      ({ mineProjectFeaturedList, mineProjectTopList, bannerImages, mineProjectStudioList }) =>
        _compact([
          !bannerImages?.length && apiActions.mineProject.listBannerImages(),
          !mineProjectStudioList.length && apiActions.mineProject.listStudioMineProjects(),
          !mineProjectFeaturedList.length && apiActions.mineProject.listFeaturedMineProjects(),
          !mineProjectTopList.length && apiActions.mineProject.listTopMineProjects()
        ])
    )
  )

export const epics = combineEpics(initHomePageEpic)
export default reducer
