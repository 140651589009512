import { RootState } from 'duck'
import { createSelector } from 'reselect'
import { apiActions } from './actions'
import { getType } from 'typesafe-actions'
import { MineImage, MINING_STATUS } from 'models/ApiModels'

const mineProjects = (state: RootState) => state.api.mineProjects

const selectMineProjectListPage = createSelector(
  mineProjects,
  mineProjects => mineProjects.mineProject.lists.page
)
const selectMineProjectFeaturedListId = createSelector(
  mineProjects,
  mineProjects => mineProjects.mineProject.lists.featured
)
const selectMineProjectStudioListId = createSelector(
  mineProjects,
  mineProjects => mineProjects.mineProject.lists.studio
)
const selectMineProjectTopListId = createSelector(
  mineProjects,
  mineProjects => mineProjects.mineProject.lists.topSell
)

const selectMineProjectHomeListId = createSelector(
  mineProjects,
  mineProjects => mineProjects.mineProject.lists.home
)

const selectMineConfig = createSelector(mineProjects, mineProjects => mineProjects.mineConfig)
const selectMineProjectListData = createSelector(
  mineProjects,
  mineProjects => mineProjects.mineProject.data
)

const selectMineProjectList = createSelector(
  selectMineProjectListPage,
  selectMineProjectListData,
  (_: RootState, page: number) => page,
  (listPage, data, page) => listPage[page]?.map(id => data[id])
)

const selectMineProjectListTotalCount = createSelector(
  mineProjects,
  mineProject => mineProject.mineProject.lastListReq?.count
)

const selectMineProjectFeaturedList = createSelector(
  selectMineProjectFeaturedListId,
  selectMineProjectListData,
  (list, data) => list?.map(id => data[id])
)
const selectMineProjectStudioList = createSelector(
  selectMineProjectStudioListId,
  selectMineProjectListData,
  (list, data) => list.map(id => data[id])
)
const selectMineProjectHomeList = createSelector(
  selectMineProjectHomeListId,
  selectMineProjectListData,
  (list, data) => list.map(id => data[id])
)
const selectMineProjectTopList = createSelector(
  selectMineProjectTopListId,
  selectMineProjectListData,
  (list, data) => list.map(id => data[id])
)

const selectOpenedtMineProjectId = createSelector(mineProjects, data => data.mineProject.openedId)
const mineProjectsData = createSelector(mineProjects, data => data.mineProject.data)

const selectOpenedMineProject = createSelector(
  selectOpenedtMineProjectId,
  mineProjectsData,
  (id, data) => (id ? data[id] : undefined)
)

const selectCollectionImages = createSelector(
  mineProjects,
  mineProjects => mineProjects.collectionImages
)

const selectInputs = createSelector(mineProjects, mineProjects => mineProjects.inputs)

const selectMineImageData = createSelector(
  mineProjects,
  mineProjects => mineProjects.mineImage.data
)

const selectCurrentMineProjectSampleListId = createSelector(
  mineProjects,
  selectOpenedMineProject,
  (mineProjects, openedMineProject) =>
    mineProjects.mineImage.sampleLists[openedMineProject?.id ?? 0] ?? []
)

const selectCurrentMineProjectSampleList = createSelector(
  selectCurrentMineProjectSampleListId,
  selectMineImageData,
  (ids, data) => ids.map(id => data[id])
)

const selectCurrentMineProjectCollectedListId = createSelector(
  mineProjects,
  selectOpenedMineProject,
  (mineProjects, openedMineProject) =>
    mineProjects.mineImage.collectedList[openedMineProject?.id ?? 0]?.list ?? []
)

const selectCurrentMineProjectCollectedList = createSelector(
  selectCurrentMineProjectCollectedListId,
  selectMineImageData,
  (ids, data) => ids.map(id => data[id])
)

const selectUserCollectedListId = createSelector(
  mineProjects,
  selectOpenedMineProject,
  mineProjects => mineProjects.mineImage.userList.list ?? []
)

const selectUserCollectedList = createSelector(
  selectUserCollectedListId,
  selectMineImageData,
  selectMineProjectListData,
  (ids, data, mineProjects) =>
    ids.map<MineImage>(id => {
      const mineImage = data[id]
      const projectData = mineProjects[mineImage?.project ?? 0]
      return {
        ...mineImage,
        project_name: projectData?.title,
        project_by: projectData?.user_full_name
      }
    })
)

const selectActiveUserCollectedList = createSelector(selectUserCollectedList, list =>
  list.filter(value => MINING_STATUS.includes(value.mine_status)).map(value => value.id)
)

const errors = (state: RootState) => {
  return state?.api?.shared?.errors ?? {}
}

const selectCurrentUserAddress = createSelector(
  mineProjects,
  mineProjects => mineProjects.accounts?.[0]
)

const loadings = (state: RootState) => {
  return state?.api?.shared?.loadings ?? {}
}

const loading = {
  'mineProject.createMineImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.createMineImage)]
  ),
  'mineProject.createNotify': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.createNotify)]
  ),
  'mineProject.createMailChimp': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.createMailChimp)]
  ),
  'mineProject.createFeedback': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.createFeedback)]
  ),
  'mineProject.listMineProjectUserCollectedOutputs': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.listMineProjectUserCollectedOutputs)]
  ),
  'mineProject.listMineProjectCollectedOutputs': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.listMineProjectCollectedOutputs)]
  ),
  'mineProject.listMineProjectSampleOutputs': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.listMineProjectSampleOutputs)]
  ),
  'mineProject.retrieveProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.retrieveProject)]
  ),
  'mineProject.retrieveMineImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.retrieveMineImage)]
  ),
  'mineProject.retrieveInput': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.retrieveInput)]
  ),
  'mineProject.listMineProjects': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.listMineProjects)]
  ),
  'mineProject.listMineProjectsHome': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.listMineProjectsHome)]
  ),
  'mineProject.prepareMintImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.prepareMintImage)]
  ),
  'mineProject.retrieveConfig': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mineProject.retrieveConfig)]
  )
}

const bannerImages = (state: RootState) => {
  return state.api.mineProjects.bannerImages
}

const prepareMineData = createSelector(mineProjects, mineProjects => mineProjects.prepareMineData)

export const apiSelectors = {
  loading,
  mineProjectsData,
  inputs: selectInputs,
  mineProjectFeaturedList: selectMineProjectFeaturedList,
  mineProjectTopList: selectMineProjectTopList,
  mineProjectStudioList: selectMineProjectStudioList,
  mineProjectHomeList: selectMineProjectHomeList,
  mineProjectListData: selectMineProjectListData,
  mineImageData: selectMineImageData,
  userCollectedList: selectUserCollectedList,
  activeUserCollectedList: selectActiveUserCollectedList,
  currentUserAddress: selectCurrentUserAddress,
  currentMineProjectSampleList: selectCurrentMineProjectSampleList,
  currentMineProjectCollectedList: selectCurrentMineProjectCollectedList,
  collectionImages: selectCollectionImages,
  mineProjectListTotalCount: selectMineProjectListTotalCount,
  openedMineProject: selectOpenedMineProject,
  mineConfig: selectMineConfig,
  prepareMineData,
  errors,
  loadings,
  bannerImages,
  mineProjectList: selectMineProjectList
}
