import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import { withLatestFrom, filter, map, mergeMap, startWith, take } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { constCreatorMaker } from 'utils/TextUtils'

// Constants
const NAMESPACE = '@@page/CollectionPage'
const creator = constCreatorMaker(NAMESPACE)

export const actions = {
  setOpenedImage: createAction(creator('SET_OPENED_IMAGE'))<number | undefined>()
}

const selectCollectionPage = (state: RootState) => state.container.collectionPage

export const selectors = {
  currentMineImage: createSelector(
    selectCollectionPage,
    apiSelectors.mineImageData,
    apiSelectors.mineProjectListData,
    (collectionPage, imageData, mineProjects) => {
      const id = collectionPage.openedMineImageId
      const data = id ? imageData[id] : undefined

      const projectData = mineProjects[data?.project ?? 0]

      return data
        ? {
            ...data,
            project_name: projectData?.title,
            project_by: projectData?.user_full_name,
            projectData
          }
        : undefined
    }
  )
}

export type CollectionPageState = {
  openedMineImageId?: number
}

const INITIAL: CollectionPageState = {
  openedMineImageId: undefined
}

const reducer = produce((state: CollectionPageState, { type, payload }) => {
  switch (type) {
    case getType(actions.setOpenedImage): {
      const id = payload as ActionType<typeof actions.setOpenedImage>['payload']
      state.openedMineImageId = id
      return
    }
    default:
  }
}, INITIAL)

// Epics

const setOpenedImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.setOpenedImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      mineProjectsData: apiSelectors.mineProjectsData(state),
      mineImages: apiSelectors.mineImageData(state),
      id: action.payload
    })),
    mergeMap(({ id, mineProjectsData }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mineProject.retrieveMineImageResponse)),
        take(1),
        filter(({ payload }) =>
          Boolean(payload.project && !mineProjectsData[payload.project ?? 0])
        ),
        map(({ payload }) => apiActions.mineProject.retrieveProject(payload.project ?? 0)),
        startWith(apiActions.mineProject.retrieveMineImage(id ?? 0))
      )
    )
  )

export const epics = combineEpics(setOpenedImageEpic)
export default reducer
