import { ActionType, createAction } from 'typesafe-actions'
import { DialogState } from './reducers'

// Actions
export const dialogActions = {
  addDialog: createAction('@@page/App/dialog/ADD_DIALOG')<DialogState['data']>(), // Add Dialog Queue
  addDialogOverlay: createAction('@@page/App/dialog/ADD_DIALOG_OVERLAY')<DialogState['data']>(),
  openDialog: createAction('@@page/App/dialog/OPEN_DIALOG')<DialogState['data']>(), // Open fresh dialog, all queue will be deleted
  closeDialog: createAction('@@page/App/dialog/CLOSE_DIALOG')(),
  closeAllDialog: createAction('@@page/App/dialog/CLOSE_ALL_DIALOG')()
}

export type DialogActions = ActionType<typeof dialogActions>
