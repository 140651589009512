/*
 * Image component on steroid
 *  - Handle empty with grey image
 *  - Display File object
 */

import React, { memo, useState, useEffect, SyntheticEvent, Ref, MouseEventHandler } from 'react'
import clsx from 'clsx'

import { ImageType } from 'models/ApiModels'
import styles from './Img.module.scss'

export type ImgProps = {
  //Accept 3 type of src ordered by priority
  image?: ImageType
  file?: File
  src?: string

  borderless?: boolean
  onClick?: MouseEventHandler
  onContextMenu?: MouseEventHandler
  className?: string
  alt?: string
  style?: object
  title?: string
  fullHeight?: boolean
  fullSize?: boolean
  fullWidth?: boolean
  onLoad?: (event: SyntheticEvent<HTMLImageElement, Event>) => void
  onError?: (event: SyntheticEvent<HTMLImageElement, Event>) => void
  component?: 'span'
  backgroundImage?: boolean
  loading?: 'lazy' | 'eager'
  classes?: {
    background?: string
    backgroundContainer?: string
    empty?: string
    image?: string
  }
  crossOrigin?: '' | 'anonymous' | 'use-credentials' | undefined
  showBgColor?: boolean
}

const ImgComponent: React.FC<
  ImgProps & {
    setUseBackgroundImage?: Function
    setRef?: any
  }
> = props => {
  const {
    alt = '',
    className,
    classes,
    crossOrigin,
    fullWidth,
    fullHeight,
    onContextMenu,
    onError,
    onLoad,
    setRef,
    title,
    style,
    setUseBackgroundImage,
    showBgColor,
    src
  } = props

  return (
    <img
      onContextMenu={onContextMenu}
      ref={setRef}
      crossOrigin={crossOrigin}
      alt={alt}
      title={title}
      style={style}
      className={clsx(
        fullWidth && styles.FullWidth,
        fullHeight && styles.FullHeight,
        showBgColor && styles.BackgroundDefault,
        className,
        classes?.image
      )}
      src={src}
      onLoad={onLoad}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        onError?.(e)
        if (setUseBackgroundImage) setUseBackgroundImage(true)
      }}
    />
  )
}

const ImgWrapper: React.FC<
  ImgProps & {
    setRef?: any
  }
> = props => {
  const {
    borderless,
    backgroundImage = false,
    alt,
    className,
    classes,
    component: Component = 'div',
    crossOrigin,
    file,
    fullWidth,
    fullHeight,
    fullSize,
    image,
    onClick,
    onContextMenu,
    onError,
    onLoad,
    showBgColor,
    style,
    setRef,
    title
  } = props

  const [srcFile, setSrcFile] = useState<string>('')
  /* 
      Some error happen because of CORS issue, which can be solved by displaying image  using
      css 'background-image'. So when error happen, set the display mode using div instead of img. 
    */
  const [, setUseBackgroundImage] = useState<boolean>(backgroundImage)
  const srcFromImage = fullSize ? image?.file : image?.thumbnail ?? image?.file
  const src = srcFromImage || props.src || srcFile || ''

  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onload = event => {
        setSrcFile(event?.target?.result?.toString() ?? '')
      }
      reader.readAsDataURL(file)
    } else {
      setSrcFile('')
    }
  }, [file])

  if (!src)
    return (
      <Component
        className={clsx(
          styles.DisplayBlock,
          fullWidth && styles.FullWidth,
          fullHeight && styles.FullHeight,
          styles.EmptyImage,
          !borderless && styles.Bordered,
          showBgColor && styles.BackgroundDefault,
          className,
          classes?.empty
        )}
        style={style}
      />
    )

  return (
    <ImgComponent
      crossOrigin={crossOrigin}
      alt={alt}
      className={className}
      classes={classes}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onError={onError}
      onLoad={onLoad}
      setRef={setRef}
      title={title}
      showBgColor={showBgColor}
      style={style}
      src={src}
      setUseBackgroundImage={setUseBackgroundImage}
    />
  )
}

const ImgContainer = React.forwardRef((props: ImgProps, ref: Ref<HTMLImageElement>) => {
  return <ImgWrapper {...props} setRef={ref} crossOrigin="anonymous" />
})

export const ImgPure = React.forwardRef((props: ImgProps, ref: Ref<HTMLImageElement>) => {
  return <ImgWrapper {...props} setRef={ref} crossOrigin={props.crossOrigin || undefined} />
})

export const ImgMemo = memo(ImgContainer)

export default ImgContainer
